import { CRS, LatLng } from "leaflet";
import {
  Instance,
  SnapshotIn,
  SnapshotOrInstance,
  getParentOfType,
  getRoot,
  types,
} from "mobx-state-tree";
import { LengthUnits, SpeedUnits, VolumeFlowRateUnits } from "../convert";
import {
  precipIntervalDurations,
  precipIntervalIdx,
  withPrecipIntervalDurations,
} from "../shared/precipIntervals";
import {
  streamPeriodDurations,
  streamPeriodIdx,
} from "../shared/streamPeriods";

import { BookmarkStore } from "./Bookmarks";
import { IRoot } from "./Root";
import config from "../config";
import { toSeconds } from "../shared/duration";

const {
  defaultDisplay: { interval: defaultInterval },
} = config;

const {
  array,
  compose,
  enumeration,
  frozen,
  identifier,
  literal,
  map,
  maybe,
  model,
  number,
  optional,
  reference,
  string,
  union,
} = types;

interface HasPosition {
  position: LatLng;
}

const SensorBase = model({
  type: string,
  id: identifier,
  timezone: string,
  latitude: number,
  longitude: number,
  name: string,
  source: string,
  site: maybe(string),
  lastReport: maybe(string),
})
  .volatile((self) => ({
    selected: false,
  }))
  .actions((self) => ({
    select() {
      self.selected = true;
    },
    unselect() {
      self.selected = false;
    },
  }))
  .views((self) => ({
    get position(): LatLng {
      return new LatLng(self.latitude, self.longitude);
    },
  }))
  .views((self) => ({
    distance(other: HasPosition) {
      return CRS.Earth.distance(self.position, other.position);
    },
  }))
  .views((self) => ({
    get nearest() {
      const sensorStore = getParentOfType(self, Sensors);
      const sensors = sensorStore.sensorsOfType(self.type);

      let minDistance = Infinity;
      let closestSensor: ISensor | undefined;
      for (const sensor of sensors) {
        if (sensor.id !== self.id) {
          const distance = self.distance(sensor);
          // If less than 1m apart, consider them the same sensor
          if (distance > 1 && distance < minDistance) {
            minDistance = distance;
            closestSensor = sensor;
          }
        }
      }
      return closestSensor;
    },
  }));

export interface SensorBase_ extends SnapshotIn<typeof SensorBase> {}

const toIntensity = (depth: number, interval: Duration): number =>
  depth * (toSeconds({ hours: 1 }) / toSeconds(interval));

export const PrecipSensor = compose(
  SensorBase,
  model({
    type: literal("precip"),
    depthUnit: frozen<LengthUnits>(),
    intensityUnit: frozen<SpeedUnits>(),
    depths: array(maybe(number)),
  })
    .views((self) => ({
      depthFor(interval: Duration) {
        const idx = precipIntervalIdx(interval);
        return self.depths[idx];
      },
    }))
    .views((self) => ({
      get intensities() {
        return withPrecipIntervalDurations(self.depths).map(
          ({ interval, depth }) =>
            depth === undefined ? undefined : toIntensity(depth, interval)
        );
      },
      intensityFor(interval: Duration) {
        const depth = self.depthFor(interval);
        return depth === undefined ? undefined : toIntensity(depth, interval);
      },
    }))
).named("PrecipSensor");

export const StreamValues = model({
  height: maybe(number),
  discharge: maybe(number),
}).actions((self) => ({
  mergeWith(other: IStreamValues) {
    if (other.height !== undefined) {
      self.height = other.height;
    }
    if (other.discharge !== undefined) {
      self.discharge = other.discharge;
    }
  },
}));
export interface IStreamValues extends Instance<typeof StreamValues> {}
export interface IStreamValues_ extends SnapshotIn<typeof StreamValues> {}

export const StreamSensor = compose(
  SensorBase,
  model({
    type: literal("stream"),
    latest: StreamValues,
    values: array(StreamValues),
    heightUnit: maybe(frozen<LengthUnits>()),
    dischargeUnit: maybe(frozen<VolumeFlowRateUnits>()),
  })
    .views((self) => ({
      get hasDischarge() {
        return self.dischargeUnit !== undefined;
      },
      heightFor(period: "last" | Duration) {
        if (period === "last") {
          return self.latest.height;
        } else {
          const idx = streamPeriodIdx(period);
          return self.values[idx].height;
        }
      },
      dischargeFor(period: "last" | Duration) {
        if (period === "last") {
          return self.latest.discharge;
        } else {
          const idx = streamPeriodIdx(period);
          return self.values[idx].discharge;
        }
      },
    }))
    .actions((self) => ({
      mergeWith(other: IStreamSensor) {
        self.latest.mergeWith(other.latest);
        self.values.forEach((s, i) => s.mergeWith(other.values[i]));
        self.heightUnit = self.heightUnit || other.heightUnit;
        self.dischargeUnit = self.dischargeUnit || other.dischargeUnit;
      },
    }))
).named("StreamSensor");

export const Sensor = union(PrecipSensor, StreamSensor);

export type ISensor = Instance<typeof Sensor>;
export interface IPrecipSensor extends Instance<typeof PrecipSensor> {}
export interface IStreamSensor extends Instance<typeof StreamSensor> {}

export interface PrecipSensor_ extends SnapshotIn<typeof PrecipSensor> {}
export interface StreamSensor_ extends SnapshotIn<typeof StreamSensor> {}
export type Sensor_ = PrecipSensor_ | StreamSensor_;

export const isPrecipSensor = (
  sensor: ISensor | undefined
): sensor is IPrecipSensor => sensor?.type === "precip";
export const isStreamSensor = (
  sensor: ISensor | undefined
): sensor is IStreamSensor => sensor?.type === "stream";

export const SensorDisplayPrecip = model({
  type: literal("precip"),
  interval: frozen<Duration>(),
  measure: enumeration("precip", ["depth", "intensity"]),
});
export interface ISensorDisplayPrecip
  extends Instance<typeof SensorDisplayPrecip> {}

export const SensorDisplayStream = model({
  type: literal("stream"),
  period: union(literal("last"), frozen<Duration>()),
  measure: enumeration("stream", ["height", "discharge"]),
});
export interface ISensorDisplayStream
  extends Instance<typeof SensorDisplayStream> {}

const SensorDisplay = union(SensorDisplayPrecip, SensorDisplayStream);
export type SensorDisplay_ = SnapshotOrInstance<typeof SensorDisplay>;

const SelectedPane = enumeration("selected", ["summary", "tabular", "chart"]);

const SelectedSensorBase = model({
  pane: SelectedPane,
});

export const SelectedPrecipSensor = compose(
  SelectedSensorBase,
  model({
    type: literal("precip"),
    sensor: reference(PrecipSensor),
    display: SensorDisplayPrecip,
  })
).named("SelectedPrecipSensor");
export type ISelectedPrecipSensor = Instance<typeof SelectedPrecipSensor>;

const SelectedStreamSensor = compose(
  SelectedSensorBase,
  model({
    type: literal("stream"),
    sensor: reference(StreamSensor),
    display: SensorDisplayStream,
  })
).named("SelectedStreamSensor");
export type ISelectedStreamSensor = Instance<typeof SelectedStreamSensor>;

export const SelectedSensor = union(SelectedPrecipSensor, SelectedStreamSensor);
export type ISelectedSensor = Instance<typeof SelectedSensor>;

export const Sensors = model({
  display: maybe(SensorDisplay),
  selected: maybe(SelectedSensor),
  sensors: map(Sensor),
  loading: true,
  bookmarks: optional(BookmarkStore, {}),
})
  .named("SensorStore")
  .views((self) => ({
    get sensorList(): ISensor[] {
      return Array.from(self.sensors.values());
    },
    sensorsOfType(type: string): ISensor[] {
      return this.sensorList.filter((s) => s.type === type);
    },
  }))
  .views((self) => ({
    get precipSensors(): IPrecipSensor[] {
      return self.sensorList.filter(isPrecipSensor);
    },
    get streamSensors(): IStreamSensor[] {
      const sensors = self.sensorList.filter(isStreamSensor);

      if (
        SensorDisplayStream.is(self.display) &&
        self.display.measure === "discharge"
      ) {
        return sensors.filter((s) => s.hasDischarge);
      }

      return sensors;
    },
    get bookmarkedSensors() {
      return self.sensorList.filter((sensor) =>
        self.bookmarks.ids.has(sensor.id)
      );
    },
  }))
  .actions((self) => ({
    setSensor(
      sensor: ISensor,
      pane: Instance<typeof SelectedPane> = "summary"
    ) {
      if (self.display) {
        // If setting to different sensor (or undefined)
        if (self.selected && self.selected.sensor.id !== sensor?.id) {
          self.selected.sensor.unselect();
        }

        const root = getRoot<IRoot>(self);
        root.layers.clearSelectedFeature();
        root.layers.lightning?.clear();

        sensor.select();

        if (sensor.type === "precip") {
          if (SensorDisplayStream.is(self.display)) {
            // i.e. we're switching from stream to precip
            const { period, measure } = self.display;

            const matchingInterval =
              period !== "last" && precipIntervalDurations.includes(period);

            self.display = {
              type: "precip",
              interval: matchingInterval ? period : defaultInterval,
              measure: measure === "height" ? "depth" : "intensity",
            };
          }

          self.selected = SelectedPrecipSensor.create({
            type: "precip",
            sensor: sensor.id,
            display: { ...self.display },
            pane,
          });
        } else {
          if (SensorDisplayPrecip.is(self.display)) {
            // i.e. we're switching from precip to stream
            const { interval, measure } = self.display;

            const matchingPeriod = streamPeriodDurations.includes(interval);

            self.display = {
              type: "stream",
              period: matchingPeriod ? interval : "last",
              measure: measure === "depth" ? "height" : "discharge",
            };
          }

          self.selected = SelectedStreamSensor.create({
            type: "stream",
            sensor: sensor.id,
            display: { ...self.display },
            pane,
          });
        }
      } else {
        console.log("self.display is undefined");
      }
    },

    clearSensor() {
      if (self.selected) {
        self.selected.sensor.unselect();
      }
      self.selected = undefined;
    },
  }))

  .actions((self) => ({
    putSensor(sensor: ISensor) {
      self.loading = false;
      const sensor_ = self.sensors.get(sensor.id);
      if (isStreamSensor(sensor) && isStreamSensor(sensor_)) {
        sensor_.mergeWith(sensor);
      } else {
        self.sensors.put(sensor);
      }
    },
    clearDisplay() {
      self.display = undefined
      self.selected = undefined
    },
    displayPrecipWithInterval(interval: Duration) {
      if (SensorDisplayPrecip.is(self.display)) {
        self.display.interval = interval;
      } else if (self.display !== undefined) {
        const measure =
          self.display.measure === "height" ? "depth" : "intensity";
        self.display = { type: "precip", interval, measure };
      } else {
        self.display = { type: "precip", interval, measure: "depth" };
      }

      if (SelectedPrecipSensor.is(self.selected)) {
        self.selected.display.interval = interval;
      } else if (SelectedStreamSensor.is(self.selected)) {
        const { name } = self.selected.sensor;
        const sensor = self.precipSensors.find((s) => s.name.startsWith(name));
        if (sensor) {
          self.selected.sensor.unselect();
          sensor.select();
          self.selected = SelectedPrecipSensor.create({
            type: "precip",
            sensor: sensor.id,
            display: { ...self.display },
            pane: self.selected?.pane,
          });
        } else {
          self.clearSensor();
        }
      }
    },
    displayStreamWithPeriod(period: "last" | Duration) {
      if (SensorDisplayStream.is(self.display)) {
        self.display.period = period;
      } else if (self.display !== undefined) {
        const measure =
          self.display.measure === "depth" ? "height" : "discharge";
        self.display = { type: "stream", period, measure };
      } else {
        self.display = { type: "stream", period, measure: "height" };
      }

      if (SelectedStreamSensor.is(self.selected)) {
        self.selected.display.period = period;
      } else if (SelectedPrecipSensor.is(self.selected)) {
        const { name } = self.selected.sensor;
        const sensor = self.streamSensors.find((s) => s.name.startsWith(name));
        if (sensor) {
          self.selected.sensor.unselect();
          sensor.select();
          self.selected = SelectedStreamSensor.create({
            type: "stream",
            sensor: sensor.id,
            display: { ...self.display },
            pane: self.selected?.pane,
          });
        } else {
          self.clearSensor();
        }
      }
    },
    displayPrecipWithMeasure(measure: ISensorDisplayPrecip["measure"]) {
      if (SensorDisplayPrecip.is(self.display)) {
        self.display.measure = measure;
      } else {
        self.display = { type: "precip", interval: defaultInterval, measure };
      }
      if (SelectedPrecipSensor.is(self.selected)) {
        self.selected.display.measure = measure;
      } else {
        self.clearSensor();
      }
    },
    displayStreamWithMeasure(measure: ISensorDisplayStream["measure"]) {
      if (SensorDisplayStream.is(self.display)) {
        self.display.measure = measure;
      } else {
        self.display = { type: "stream", period: "last", measure };
      }

      if (SelectedStreamSensor.is(self.selected)) {
        self.selected.display.measure = measure;
        if (measure === "discharge" && !self.selected.sensor.hasDischarge) {
          self.clearSensor();
        }
      } else {
        self.clearSensor();
      }
    },
  }));
