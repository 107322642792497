import { Instance, cast, getEnv, types } from "mobx-state-tree";

import { CbrfcAPI } from "./CbrfcAPI";
import { JefdaqAPI } from "./JefdaqAPI";
import { Layers } from "./Layers";
import { Menus } from "./Menus";
import { Sensors } from "./Sensors";
import { VaisalaAPI } from "./VaisalaAPI";
import { autorun } from "mobx";
import { precipApiToSensors } from "../jefdaqApiToSensorModel/precip";
import { ratedApiToSensors } from "../jefdaqApiToSensorModel/rated";
import { stageApiToSensors } from "../jefdaqApiToSensorModel/stage";
import { useStore } from "mobx-store-provider";

const { model, number } = types;

const Map = model("Map", {
  zoom: number,
}).actions((self) => ({
  setZoom(zoom: number) {
    self.zoom = zoom;
  },
}));

const Root = model({
  jefdaqApi: JefdaqAPI,
  vaisalaApi: VaisalaAPI,
  cbrfcApi: CbrfcAPI,
  sensors: Sensors,
  layers: Layers,
  menus: Menus,
  map: Map,
})
  .volatile((self) => ({
    isMobile: getEnv(self).mobile || false,
  }))
  .actions((self) => ({
    updateWidth(width: number) {
      self.isMobile = width < 1024;
    },
  }))
  .actions((self) => ({
    afterCreate() {
      const { putSensor } = self.sensors;

      // Automatically update the sensor model when the API is refreshed
      // Maybe this should use onPatch since we're using MST?
      autorun(() => {
        const { precip } = self.jefdaqApi;
        if (precip.response !== undefined) {
          precipApiToSensors(precip.response).forEach((s) =>
            putSensor(cast(s))
          );
        }
      });
      autorun(() => {
        const { stage } = self.jefdaqApi;
        if (stage.response !== undefined) {
          stageApiToSensors(stage.response).forEach((s) => putSensor(cast(s)));
        }
      });
      autorun(() => {
        const { rated } = self.jefdaqApi;
        if (rated.response !== undefined) {
          ratedApiToSensors(rated.response).forEach((s) => putSensor(cast(s)));
        }
      });

      // Be smart about closing things on mobile
      autorun(() => {
        const { selected } = self.sensors;
        if (self.isMobile && selected !== undefined) {
          self.menus.closePanel();
        }
      });
      autorun(() => {
        const { panel } = self.menus;
        if (self.isMobile && panel !== undefined) {
          self.sensors.clearSensor();
        }
      });
    },
  }));

export default Root;
export interface IRoot extends Instance<typeof Root> {}

export const useRootStore = () => useStore(Root);
