import { getTimezoneOffset } from "date-fns-tz";

// https://github.com/marnusw/date-fns-tz/issues/194#issuecomment-1197170838

export function utcToZonedTime(date: Date | number, timeZone: string) {
  const offset = getTimezoneOffset(timeZone, date);

  return new Date(date.valueOf() + offset);
}

export function zonedTimeToUtc(date: Date | number, timeZone: string) {
  const offset = getTimezoneOffset(timeZone, date);

  return new Date(date.valueOf() - offset);
}
