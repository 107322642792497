import { Column, Columns, Subtitle, Tag, Tags, bulma } from "trunx";
import { decimalPlaces, useLocaleContext } from "../../contexts/LocaleContext";
import { formatISODuration, toSeconds } from "../../shared/duration";

import { ISelectedPrecipSensor } from "../../models/Sensors";
import convert from "../../convert";
import { observer } from "mobx-react-lite";
import { splitEvery } from "ramda";
import { withPrecipIntervalDurations } from "../../shared/precipIntervals";

const PrecipDataTable = ({ selected }: { selected: ISelectedPrecipSensor }) => {
  const { sensor, display } = selected;

  const {
    dict: { intervals: dict },
  } = useLocaleContext();

  const data = withPrecipIntervalDurations(
    display.measure === "depth" ? sensor.depths : sensor.intensities
  );

  const {
    dict: {
      panel: {
        sensors: {
          info: { precip: precipTitle, intensity: intensityTitle },
        },
      },
    },
    units: {
      precip: { depth: toDepthUnit, intensity: toIntensityUnit },
    },
  } = useLocaleContext();

  const title = display.measure === "depth" ? precipTitle : intensityTitle;
  const fromUnit =
    display.measure === "depth" ? sensor.depthUnit : sensor.intensityUnit;
  const toUnit = display.measure === "depth" ? toDepthUnit : toIntensityUnit;

  interface DataTagProps {
    interval: Duration;
    depth: number | undefined;
  }
  const DataTag = observer(({ interval, depth }: DataTagProps) => {
    const value =
      depth !== undefined
        ? convert(depth)
            .from(fromUnit)
            .to(toUnit)
            .toFixed(decimalPlaces(toUnit))
        : "-";
    const key = formatISODuration(interval);
    const label = dict.durationLabel[key];
    const isDisplaying = toSeconds(interval) === toSeconds(display.interval);
    return (
      <Tags className="is-precip is-justify-content-center" key={key} hasAddons>
        <Tag key="label" className={isDisplaying ? bulma("is-info") : ""}>
          {label}
        </Tag>
        <Tag
          key="value"
          className={!!depth && depth > 0 ? bulma("is-primary") : ""}
        >
          {value}
        </Tag>
      </Tags>
    );
  });

  interface DataColumnProps {
    idx: number;
    data: DataTagProps[];
  }
  const DataColumn = ({ data, idx }: DataColumnProps) => (
    <Column key={idx.toString()}>
      {data.map((d, idx_) => (
        <DataTag key={idx_} {...d} />
      ))}
    </Column>
  );

  return (
    <div id="sensor-data-table">
      <Subtitle>
        {title}&nbsp;
        <small className={bulma("is-size-6")}>({toUnit})</small>
      </Subtitle>
      <Columns>
        {splitEvery(4, data).map((c, idx) => (
          <DataColumn data={c} key={idx} idx={idx} />
        ))}
      </Columns>
    </div>
  );
};

export default observer(PrecipDataTable);
