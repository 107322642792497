import convert, { LengthUnits, VolumeFlowRateUnits } from "../convert";
import { useEffect, useState } from "react";

import { ISensor } from "../models/Sensors";
import conagua from "./useTabularData/conagua";
import { formatInTimeZone } from "date-fns-tz";
import jefdaq from "./useTabularData/jefdaq";

export type TabularDataOneColumn = Map<Date, number>;
export type TabularDataTwoColumns = Map<Date, [number, number]>;

export type TabularData =
  | { type: "OneColumn"; unit: LengthUnits; rows: TabularDataOneColumn }
  | {
      type: "TwoColumns";
      units: [LengthUnits, VolumeFlowRateUnits];
      rows: TabularDataTwoColumns;
    };

type ApiResponse = "loading" | TabularData;

const useTabularData = (sensor: ISensor, startDate: Date, endDate: Date) => {
  const [data, setData] = useState<ApiResponse>();

  useEffect(() => {
    if (sensor.id.startsWith("jefdaq")) {
      setData("loading");
      const getJefdaq = async () => {
        const data = await jefdaq(sensor, startDate, endDate);
        setData(data);
      };
      getJefdaq();
    } else if (sensor.id.startsWith("conagua")) {
      setData("loading");
      const getConagua = async () => {
        const data = await conagua(sensor, startDate, endDate);
        setData(data);
      };
      getConagua();
    } else {
      throw new Error(`no handler for ${sensor.id}`);
    }
  }, [sensor, startDate, endDate]);

  return data;
};

export default useTabularData;

export const forCsvDownloader = (
  data: TabularData,
  tz: string,
  toUnit: LengthUnits,
  ratedUnit: VolumeFlowRateUnits
) => {
  const formatDate = (date: Date) => formatInTimeZone(date, tz, "yyyy-MM-dd");
  const formatTime = (date: Date) => formatInTimeZone(date, tz, "HH:mm:ss");

  if (data.type === "OneColumn") {
    return Array.from(data.rows.entries()).map(([date, value]) => ({
      date: formatDate(date),
      time: formatTime(date),
      value: convert(value).from(data.unit).to(toUnit).toString(),
    }));
  } else {
    return Array.from(data.rows.entries()).map(([date, [value, rated]]) => ({
      date: formatDate(date),
      time: formatTime(date),
      value: convert(value).from(data.units[0]).to(toUnit).toString(),
      rated: convert(rated).from(data.units[1]).to(ratedUnit).toString(),
    }));
  }
};
