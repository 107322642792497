import { Feature, GeoJsonObject, GeometryObject } from "geojson";
import {
  IAnyType,
  IArrayType,
  IModelType,
  ISimpleType,
  Instance,
  _NotCustomized,
  cast,
  flow,
  getRoot,
  types,
} from "mobx-state-tree";
import {
  IQueryFeaturesOptions,
  queryFeatures,
} from "@esri/arcgis-rest-feature-service";
import { IRiver, River } from "./CbrfcAPI";
import { IStrike, Strike } from "./VaisalaAPI";
import { LatLng, GeoJSON as LeafletGeoJSON } from "leaflet";

import { IRoot } from "./Root";
import { toGeoJSON } from "../shared/staticLayer";

const {
  array,
  boolean,
  frozen,
  identifier,
  integer,
  maybe,
  model,
  number,
  refinement,
  safeReference,
  string,
  union,
  enumeration,
} = types;

export enum BaseMap {
  Streets = "Streets",
  Imagery = "Imagery",
  ImageryLabels = "ImageryLabels",
  Topographic = "Topographic",
  Terrain = "Terrain",
  TerrainLabels = "TerrainLabels",
  ShadedRelief = "ShadedRelief",
}

const Source = union(string, frozen<IQueryFeaturesOptions>());
export const isUrl = (x: Instance<typeof Source>): x is string =>
  typeof x === "string";

export const StaticLayer = model({
  name: identifier,
  source: Source,
  geoJson: maybe(frozen<GeoJsonObject>()),
  color: maybe(string),
  visible: false,
}).actions((self) => ({
  afterCreate: flow(function* afterCreate() {
    const geoJson = yield isUrl(self.source)
      ? toGeoJSON(self.source)
      : queryFeatures(self.source);

    if (geoJson) {
      // will throw if invalid,
      // but don't need result if doesn't throw
      new LeafletGeoJSON(geoJson, {});
      self.geoJson = geoJson;
    }
  }),
  toggle() {
    self.visible = !self.visible;
  },
}));

export interface IStaticLayer extends Instance<typeof StaticLayer> {}

const DynamicLayerLayerMetadata = model({
  id: integer,
  name: string,
});

export const DynamicLayerMetadata = array(DynamicLayerLayerMetadata);

export const DynamicLayer = types
  .model({
    title: identifier,
    url: string,
    opacity: refinement(number, (n) => n >= 0 && n <= 1),
    defaultLayers: array(integer),
    visibleLayers: array(integer),
    metadata: maybe(DynamicLayerMetadata),
  })
  .views((self) => ({
    get anyVisible() {
      return self.visibleLayers.length > 0;
    },
    isVisible(id: number) {
      return self.visibleLayers.includes(id);
    },
  }))
  .actions((self) => ({
    setMetadata(metadata: typeof self.metadata) {
      self.metadata = metadata;
    },
    toggleDefaultLayers() {
      if (self.anyVisible) {
        self.visibleLayers.clear();
      } else {
        self.visibleLayers.replace(self.defaultLayers);
      }
    },
    toggleLayer(id: number) {
      if (self.isVisible(id)) {
        self.visibleLayers.remove(id);
      } else {
        self.visibleLayers.push(id);
      }
    },
  }));

export interface IDynamicLayer extends Instance<typeof DynamicLayer> {}

const CameraLayerCamera = model({
  url: identifier,
  name: string,
  location: model({ lat: number, lng: number }),
}).views((self) => ({
  get position() {
    return new LatLng(self.location.lat, self.location.lng);
  },
}));

export interface ICameraLayerCamera
  extends Instance<typeof CameraLayerCamera> {}

export const CameraLayer = types
  .model({
    name: string,
    visible: false,
    cameras: array(CameraLayerCamera),
  })
  .actions((self) => ({
    toggle() {
      self.visible = !self.visible;
    },
  }));

export interface ICameraLayer extends Instance<typeof CameraLayer> {}

export const LayerFolder = <IT extends IAnyType>(type: IT): ILayerFolder<IT> =>
  types
    .model({
      title: identifier,
      layers: array(type),
    })
    .views((self) => ({
      get anyVisible() {
        return self.layers.filter(({ visible }) => visible).length > 0;
      },
    }))
    .actions((self) => ({
      toggle() {
        if (self.anyVisible) {
          self.layers.forEach((l) => (l.visible = false));
        } else {
          self.layers.forEach((l) => (l.visible = true));
        }
      },
    }));

export type ILayerFolder<IT extends IAnyType> = IModelType<
  {
    title: ISimpleType<string>;
    layers: IArrayType<IT>;
  },
  {
    readonly anyVisible: boolean;
  } & {
    toggle(): void;
  },
  _NotCustomized,
  _NotCustomized
>;

export const isFolder = <IT extends IAnyType>(
  x: Instance<IT | ILayerFolder<IT>>
): x is Instance<ILayerFolder<IT>> =>
  (x as Instance<ILayerFolder<IT>>).layers !== undefined;

export const StaticLayerFolder = LayerFolder(StaticLayer);
const StaticLayerOrFolder = union(StaticLayer, StaticLayerFolder);
export interface IStaticLayerFolder
  extends Instance<typeof StaticLayerFolder> {}
export type IStaticLayerOrFolder = Instance<typeof StaticLayerOrFolder>;

export const isStaticLayer = (x: IStaticLayerOrFolder): x is IStaticLayer =>
  !isFolder(x);

export const isStaticLayerFolder = (
  x: IOpenableLayer
): x is IStaticLayerFolder => (x as IStaticLayerFolder).layers !== undefined;

export const isDynamicLayer = (x: IOpenableLayer): x is IDynamicLayer =>
  (x as IDynamicLayer).url !== undefined;
const CameraLayerFolder = LayerFolder(CameraLayer);
const CameraLayerOrFolder = union(CameraLayer, CameraLayerFolder);
export interface ICameraLayerFolder
  extends Instance<typeof CameraLayerFolder> {}
export type ICameraLayerOrFolder = Instance<typeof CameraLayerOrFolder>;

export const isCameraLayer = (x: ICameraLayerOrFolder): x is ICameraLayer =>
  !isFolder(x);

const OpenableLayer = union(StaticLayerFolder, DynamicLayer, CameraLayerFolder);
type IOpenableLayer = Instance<typeof OpenableLayer>;

export const Lightning = types
  .model({
    selected: safeReference(Strike),
    showLegend: boolean,
  })
  .actions((self) => ({
    set(strike: IStrike) {
      self.selected = strike;
      self.showLegend = true;

      const root = getRoot<IRoot>(self);
      root.sensors.clearSensor();
      root.layers.clearSelectedFeature();
    },
    clear() {
      self.selected = undefined;
      self.showLegend = false;
    },
  }));
export interface ILightning extends Instance<typeof Lightning> {}

export const Cbrfc = types
  .model({
    selected: safeReference(River),
  })
  .actions((self) => ({
    set(river: IRiver) {
      self.selected = river;

      const root = getRoot<IRoot>(self);
      root.sensors.clearSensor();
      root.layers.clearSelectedFeature();
    },
    clear() {
      self.selected = undefined;
    },
  }));

export interface ICbrfc extends Instance<typeof Cbrfc> {}

export const Layers = types
  .model({
    baseMap: enumeration<BaseMap>("BaseMap", Object.values(BaseMap)),
    static: array(StaticLayerOrFolder),
    dynamic: array(DynamicLayer),
    misc: array(CameraLayerOrFolder),
    openLayerPanel: safeReference(OpenableLayer),
    selectedFeature: maybe(frozen<Feature<GeometryObject>>()),
    selectedCamera: safeReference(CameraLayerCamera),
    lightning: maybe(Lightning),
    cbrfc: maybe(Cbrfc),
  })
  .views((self) => ({
    findDynamicLayer(url_: string) {
      return self.dynamic.find(({ url }) => url === url_);
    },
    get flattenStatic() {
      const layers: IStaticLayer[] = [];
      self.static.forEach((folderOrLayer) => {
        if (isStaticLayer(folderOrLayer)) {
          layers.push(folderOrLayer);
        } else if (isFolder(folderOrLayer)) {
          folderOrLayer.layers.forEach((l) => layers.push(l));
        }
      });
      return layers;
    },
    get flattenMisc() {
      const layers: ICameraLayer[] = [];
      self.misc.forEach((folderOrLayer) => {
        if (isCameraLayer(folderOrLayer)) {
          layers.push(folderOrLayer);
        } else if (isFolder(folderOrLayer)) {
          folderOrLayer.layers.forEach((l) => layers.push(l));
        }
      });
      return layers;
    },
  }))
  .views((self) => ({
    get any() {
      return (
        self.flattenStatic.length > 0 ||
        self.dynamic.length > 0 ||
        self.flattenMisc.length > 0
      );
    },
  }))
  .actions((self) => ({
    setBaseMap(baseMap: BaseMap) {
      self.baseMap = baseMap;
    },
    setOpenLayerPanel(openableLayer: IOpenableLayer) {
      self.openLayerPanel = openableLayer;
    },
    clearOpenLayerPanel() {
      self.openLayerPanel = undefined;
    },
    setSelectedFeature(feature: Feature<GeometryObject>) {
      const root = getRoot<IRoot>(self);
      root.sensors.clearSensor();
      self.lightning?.clear();

      self.selectedFeature = feature;
    },
    clearSelectedFeature() {
      self.selectedFeature = undefined;
    },
    toggleLightning() {
      const { sensors } = getRoot<IRoot>(self);

      if (self.lightning === undefined) {
        self.lightning = cast({
          showLegend: !sensors.selected && !self.selectedFeature,
          selected: undefined,
        });
      } else {
        self.lightning = undefined;
      }
    },

    showCbrfc() {
      self.cbrfc = cast({
        selected: undefined,
      });
    },
    toggleCbrfc() {
      if (self.cbrfc === undefined) {
        self.cbrfc = cast({
          selected: undefined,
        });
      } else {
        self.cbrfc = undefined;
      }
    },
    setCamera(camera: ICameraLayerCamera) {
      self.selectedCamera = camera;
      const root = getRoot<IRoot>(self);
      root.menus.closePanel();
      root.sensors.clearSensor();
      root.layers.clearSelectedFeature();
    },
    clearCamera() {
      self.selectedCamera = undefined;
    },
  }));
export interface ILayerStore extends Instance<typeof Layers> {}
