import { DynamicMapLayer } from "../../config";

const layer: DynamicMapLayer= {
  title: "FEMA NFHL",
  url: "https://hazards.fema.gov/gis/nfhl/rest/services/public/NFHL/MapServer",
  opacity: 0.7,
  defaultLayers: [
    1,
    2,
    //3,
    //4,
    //5,
    //6,
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    15,
    16,
    17,
    18,
    19,
    20,
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    30,
    31,
    32,
  ],
};
export default layer;
