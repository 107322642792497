import { Icon, Section, Table, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISensor } from "../models/Sensors";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../contexts/LocaleContext";
import { useRootStore } from "../models/Root";

const BookmarksPanel = () => {
  const {
    dict: {
      panel: { bookmarks: dict },
    },
  } = useLocaleContext();

  const {
    menus: { closePanel },
    sensors: { setSensor, bookmarkedSensors },
  } = useRootStore();

  const Row = ({ sensor }: { sensor: ISensor }) => {
    const onClick = () => {
      setSensor(sensor);
    };

    const { id: idx, name, site } = sensor;

    const anchor = (text: string, className: string) => (
      <td className={className}>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
        <a onClick={onClick}>{text}</a>
      </td>
    );

    return (
      <tr key={idx}>
        {anchor(name, bulma("has-text-left"))}
        {anchor(site || "", bulma("has-text-right"))}
      </tr>
    );
  };

  const table =
    bookmarkedSensors.length > 0 ? (
      <Table isFullwidth>
        <tbody>
          <tr>
            <th>{dict.name}</th>
            <th colSpan={2}>ID</th>
          </tr>

          {bookmarkedSensors.map((s) => (
            <Row sensor={s}></Row>
          ))}
        </tbody>
      </Table>
    ) : (
      <Section className={bulma("has-text-centered")}>
        <i className="has-text-centered">No bookmarks, click</i>
        <Icon>
          <FontAwesomeIcon icon={["far", "star"]} />
        </Icon>
        <i> to add one.</i>
      </Section>
    );

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
    >
      <LeafletPanelBody>
        <LeafletPanelSection>{table}</LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(BookmarksPanel);
