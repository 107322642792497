import { CameraLayerCamera } from "../../config";
import { LatLng } from "leaflet";

export const camerasPima: CameraLayerCamera[] = [
  {
    name: "Canada Del Oro Wash at Rancho Solano",
   url: 
      "https://jefulleralert.com/Webcam/moultrie/CDOatRS/latest.jpg",
    location: new LatLng(32.5642, -110.8478),
  },
  {
    name: "Canada Del Oro Wash at Edwin Road",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/cdoedwin/snap_c1.jpg",
    location: new LatLng(32.511585, -110.873448),
  },
  {
    name: "Canada Del Oro Wash at Golder Road",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/cdogolder/snap_c1.jpg",
    location: new LatLng(32.478382, -110.899393),
  },
  {
    name: "Canada Del Oro Wash at Overton Road",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/cdooverton/snap_c1.jpg",
    location: new LatLng(32.374063, -111.009013),
  },
  {
    name: "Finger Rock",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/fingerrock/snap_c1.jpg",
    location: new LatLng(32.333754, -110.906522),
  },
  {
    name: "Santa Cruz River at Quail Crossing",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/santaCruzSahuarita/latest.jpg",
    location: new LatLng(31.8959410, -110.9760330),
  },
  {
    name: "Agua Caliente",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/aguacaliente/snap_c1.jpg",
    location: new LatLng(32.2970390, -110.7167110),
  },
  {
    name: "Big Wash",
    url: 
      "https://jefulleralert.com/Webcam/Latest/Pima-FTP/bigwash/latest.jpg",
    location: new LatLng(32.412583, -110.942167),
  }
];

export const camerasAZGF: CameraLayerCamera[] = [
  {
    name: "Arivaca Lake",
    url:
      "https://azgfd.jefulleralert.com/img_61855ffa577bfb1f593eb891.jpg",
    location: new LatLng(31.530218, -111.252561),
  },
  {
    name: "Ash Creek",
    url:
      "https://azgfd.jefulleralert.com/Webcam/AZGF/ashCreek/snap_c1.jpg",
    location: new LatLng(32.7616750, -109.8726480),
  },
  {
    name: "Black Canyon Lake",
    url:
      "https://azgfd.jefulleralert.com/Webcam/AZGF/blackCanyon/snap_c1.jpg",
    location: new LatLng(34.3316442, -110.6980644),
  },
  {
    name: "Cluff Ranch",
    url:
      "https://jefulleralert.com/Webcam/moultrie/CluffRanch/latest.jpg",
    location: new LatLng(32.832529, -109.834719),
  },
  {
    name: "Big Lake",
    url:
      "https://jefulleralert.com/Webcam/cuddeback/Dam/latest.jpg",
    location: new LatLng(33.889144, -109.414511),
  },
  {
    name: "Luna Lake",
    url:
      "https://azgfd.jefulleralert.com/Webcam/AZGF/lunaLake/snap_c1.jpg",
    location: new LatLng(33.8289030, -109.0878300),
  },
  {
    name: "Lynx Lake",
    url:
      "https://jefulleralert.com/Webcam/moultrie/LynxLake/latest.jpg",
    location: new LatLng(34.522598, -112.385701),
  },
  {
    name: "Roper Lake",
    url:
      "https://azgfd.jefulleralert.com/img_6183ba43577bfb1f59c52fd5.jpg",
    location: new LatLng(32.756211, -109.704842),
  },
  {
    name: "Pena Blanca",
    url:
      "https://azgfd.jefulleralert.com/img_61878f03577bfb1f599bb601.jpg",
    location: new LatLng(31.409246, -111.085049),
  },
  {
    name: "Lee Valley Reservoir 1",
    url:
      "https://jefulleralert.com/Webcam/moultrie/LeeValley1/latest.jpg",
    location: new LatLng(33.943655, -109.498279),
  },
  {
    name: "Fool Hollow Lake",
    url:
      "https://jefulleralert.com/Webcam/moultrie/FoolHollow/latest.jpg",
    location: new LatLng(34.2732838, -110.0743356),
  },
  {
    name: "Lee Valley Reservoir 2",
    url:
      "https://jefulleralert.com/Webcam/moultrie/LeeValley/latest.jpg",
    location: new LatLng(33.9419444, -109.4988889),
  },
  {
    name: "Parker Canyon Lake",
    url:
      "https://azgfd.jefulleralert.com/img_632c9eb8f7008b8cf30e4bdf.jpg",
    location: new LatLng(31.4269444, -110.4569444),
  },
  {
   name: "Willow Springs Lake",
    url:
      "https://jefulleralert.com/Webcam/moultrie/WillowSprings/latest.jpg",
    location: new LatLng(34.312991, -110.878339),
  },
  {
    name: "South Cove",
     url:
       "https://jefulleralert.com/Webcam/cuddeback/SouthCove/latest.jpg",
     location: new LatLng(33.873633, -109.428025),
   },
   {
    name: "Fool Hollow Seepage",
     url:
       "https://azgfd.jefulleralert.com/img_646279e5595bb6c7840b3a34.jpg",
     location: new LatLng(34.277971, -110.074164),
   },
];

export const camerasPinal: CameraLayerCamera[] = [
  {
    name: "Weekes Wash",
    url:
      "https://pinal.jefulleralert.com/img_635312e72fe0a4b3afdc5361.jpg",
    location: new LatLng(33.4455566, -111.5077744),
  },
  {
    name: "McClellan Wash at the CAP",
    url:
      "https://pinal.jefulleralert.com/img_633e0451beb5e3ec8b55da69.jpg",
    location: new LatLng(32.6691666, -111.3491669),
  },
  {
    name: "San Pedro River at SR77",
    url:
      "https://jefulleralert.com/Webcam/Latest/Pinal/207891_SanPedro/SanPedro.jpg",
    location: new LatLng(32.742082, -110.648081),
  },
  {
    name: "Santa Cruz River at Honeycutt Road",
    url:
      "https://jefulleralert.com/Webcam/moultrie/Honeycutt/latest.jpg",
    location: new LatLng(33.0580559, -111.9786148),
  },
  {
    name: "Vekol at Ralston",
    url:
      "https://jefulleralert.com/Webcam/Latest/Pinal/VekolatRalston/snap_c1.jpg",
    location: new LatLng(32.994098, -112.116951),
  },
  {
    name: "Queen Creek at Sharon",
    url:
      "https://jefulleralert.com/Webcam/Latest/Pinal/207897_QC-at-ECV/QC-at-ECV.jpg",
    location: new LatLng(33.2946480, -111.3035160),
  },
  {
    name: "Tangerine Basins",
    url:
      "https://jefulleralert.com/Webcam/Latest/Pinal/TangerineBasins/snap_c1.jpg",
    location: new LatLng(32.9805556, -111.7670000),
  },
  {
    name: "Siphon Draw",
    url:
      "https://pinal.jefulleralert.com/img_633e00faa0a83ecb63266431.jpg",
    location: new LatLng(33.385594, -111.499135),
  },
  {
    name: "Santa Cruz River at Trico Road",
    url:
      "https://pinal.jefulleralert.com/img_619489f9577bfb1f59bd2821.jpg",
    location: new LatLng(32.4716100, -111.3019540),
  },
  {
    name: "Queen Creek at El Camino Viejo",
    url:
      "https://jefulleralert.com/reolink/qc-ecv/latest.jpeg",
    location: new LatLng(33.2877778, -111.3291667),
  },
  {
    name: "Pinal Peak",
    url:
      "https://pinal.jefulleralert.com/img_647609d76834f0996115f59a.jpg",
    location: new LatLng(33.2805700, -110.8210480),
  },
  {
    name: "Greene Wash and Sunland Gin Rd",
    url:
      "https://jefulleralert.com/reolink/gw-sgr/latest.jpeg",
    location: new LatLng(32.610440, -111.671962),
  },
    {
    name: "Magma Channel at CAP",
    url:
      "https://pinal.jefulleralert.com/img_64c843062502e4dd6e4d7da2.jpg",
    location: new LatLng(33.103333, -111.429722),
  },
  {
    name: "QC at Ironwood",
    url:
      "https://pinal.jefulleralert.com/img_64768128dc14a49690c94268.jpg",
    location: new LatLng(33.231208, -111.563189),
  },
];

export const camerasSantaCruz: CameraLayerCamera[] = [
  {
    name: "Nogales Wash",
    url:
      "https://jefulleralert.com/Webcam/moultrie/NogalesWash/latest.jpg",
    location: new LatLng(31.3558333, -110.9288889),
  },
  {
    name: "Patagonia Lake",
    url:
      "https://santacruz.jefulleralert.com/img_6108392250fa460014be1394.jpg",
    location: new LatLng(31.488883, -110.867836),
  },
  {
    name: "Sonoita Creek",
    url:
      "https://jefulleralert.com/Webcam/moultrie/SonoitaCrk/latest.jpg",
    location: new LatLng(31.542268, -110.751084),
  },
];

export const camerasGilaTelegraph: CameraLayerCamera[] = [
  {
    name: "Bloody Tanks",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839042276374/latest.jpg",
    location: new LatLng(33.3708458, -110.9211048),
  },
  {
    name: "Ice House",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839046687972/latest.jpg",
    location: new LatLng(33.390841, -110.8802573),
  },
  {
    name: "Six Shooter",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839046573792/latest.jpg",
    location: new LatLng(33.3110170, -110.7918527)
  },
  {
    name: "Russell Gulch",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839046683070/latest.jpg",
    location: new LatLng(33.3597619, -110.8157310),
  }
];
export const camerasNavajo: CameraLayerCamera[] = [
 {
    name: "Millet Swale Stage Sensor",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839046682783/latest.jpg",
    location: new LatLng(34.4337850, -110.0406140),
  },
  {
    name: "Millet Swale Breach",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839046573206/latest.jpg",
    location: new LatLng(34.431835, -110.041837),
  },
  {
    name: "Lone Pine Dam",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839043108238/latest.jpg",
    location: new LatLng(34.3511, -110.087),
  },
  {
    name: "Schoens Dam",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839042403010/latest.jpg",
    location: new LatLng(34.4147, -110.102),
  },
  {
    name: "Mexican Lake",
    url:
      "https://jefulleralert.com/Webcam/ridgetec/864839042382743/latest.jpg",
    location: new LatLng(34.3738, -109.983),
  }
];


export const camerasCoconinoMuseum: CameraLayerCamera[] = [
  {
    "name": "Upper Oldham",
    "url": "https://coconino.jefulleralert.com/cameras/UpperOldham.jpg",
    "location": new LatLng(35.258788, -111.620745)
  },
  {
    "name": "Museum South",
    "url": "https://coconino.jefulleralert.com/cameras/MuseumFireSouth.jpg",
    "location": new LatLng(35.253049, -111.624881)
  },
  {
    "name": "Museum West",
    "url": "https://coconino.jefulleralert.com/cameras/MuseumFireWest2.jpg",
    "location": new LatLng(35.249953, -111.632390)
  },
  {
    "name" : "Morning Glory",
    "url": "https://coconino.jefulleralert.com/cameras/Ogden.jpg",
    "location": new LatLng(35.237727, -111.633929)
  },
  {
    "name": "Above Paradise",
    "url": "https://coconino.jefulleralert.com/cameras/Above Paradise.jpg",
    "location": new LatLng(35.228128, -111.627085)
  }
];


export const pipelineFire: CameraLayerCamera[] = [
  {
    "name": "Upper Schultz",
    "url": "https://jefulleralert.com/Webcam/ridgetec/866834040180031/latest.jpg",
    "location": new LatLng(35.27271, -111.64751)
  },
  {
    "name": "Schultz Detention- Middle Basin",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046682882/latest.jpg",
    "location": new LatLng(35.240436, -111.658619)
  },
  {
    "name": "Middle Schultz",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839042379020/latest.jpg",
    "location": new LatLng(35.259186, -111.661813)
  },
  {
    "name": "Chimney",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046682684/latest.jpg",
    "location": new LatLng(35.26173, -111.6632)
  },
  {
    "name": "Peaceful Way",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046568966/latest.jpg",
    "location": new LatLng(35.32194, -111.5864)
  },
  {
    "name": "Copeland",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046560393/latest.jpg",
    "location": new LatLng(35.31722, -111.5914)
  },
  {
    "name": "Upper Campbell",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839047323684/latest.jpg",
    "location": new LatLng(35.30788, -111.5839)
  },
  {
    "name": "Government Tank",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046686834/latest.jpg",
    "location": new LatLng(35.289925, -111.62304)
  },
  {
    "name": "Paintbrush-Siesta",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839047430067/latest.jpg",
    "location": new LatLng(35.2925, -111.5853)
  },
  {
    "name": "Thames-Brandis",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046681439/latest.jpg",
    "location": new LatLng(35.3317, -111.5783)
  },
  {
    "name": "Lennox-Wupatki",
    "url": "https://jefulleralert.com/Webcam/ridgetec/864839046691032/latest.jpg",
    "location": new LatLng(35.35028, -111.5697)
  }
];

export const camerasTusayan: CameraLayerCamera[] = [
  {
    "name": "Tusayan Sanitation Plant",
    "url": "https://coconino.jefulleralert.com/cameras/Tusayan%20Sanitation%20Plant.jpg",
    "location": new LatLng(35.974622, -112.137748)
  },
  {
    "name": "Long Jim Canyon",
    "url": "https://tusayan.jefulleralertdev.com/cameras/Tusayan%20Long%20Jim%20Canyon%20at%20Hwy%2064.jpg",
    "location": new LatLng(35.984932, -112.123132)
  }
];
