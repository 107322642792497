import React, { ReactNode } from "react";

import { Table } from "trunx";

export interface PanelInfoTableProps {
  rows: [string, ReactNode | string][];
}

const PanelInfoTable = ({ rows }: PanelInfoTableProps) => {
  return (
    <Table isFullwidth>
      <tbody>
        {rows.map(([left, right]) => (
          <tr key={left}>
            <td>{left}</td>
            <td>{right}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default PanelInfoTable;
