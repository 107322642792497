import { kml } from "@tmcw/togeojson";

export const toGeoJSON = async (url: string) => {
  const response = await fetch(url);
  const contentType = response.headers.get("content-type");
  if (contentType?.includes("application/geo+json")) {
    const json = await response.json();
    return json;
  } else if (contentType?.includes("kml")) {
    const text = await response.text();
    const xml = new window.DOMParser().parseFromString(text, "text/xml");
    return kml(xml);
  } else {
    console.error("not KML or GeoJSON", url, contentType);
  }
};
