import { Icon, IconText, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IStaticLayerFolder } from "../models/Layers";
import { observer } from "mobx-react-lite";

interface StaticLayerFolderPanelProps {
  model: IStaticLayerFolder;
  back: () => void;
}
const StaticLayerFolderPanel = ({
  model: { title, layers },
  back,
}: StaticLayerFolderPanelProps) => {
  const LayerMenu = observer(
    ({ layers }: { layers: IStaticLayerFolder["layers"] }) => {
      return (
        <aside className={bulma("menu")}>
          <ul className={bulma("menu-list")}>
            {layers.map(({ name, toggle, visible }) => {
              return (
                <li key={name} onClick={toggle}>
                  <IconText>
                    <Icon className={bulma("is-left")}>
                      <FontAwesomeIcon
                        icon={visible ? faCheckSquare : faSquare}
                      />
                    </Icon>
                    <span>{name}</span>
                  </IconText>
                </li>
              );
            })}
          </ul>
        </aside>
      );
    }
  );

  return (
    <LeafletPanel heading={<LeafletPanelHeading title={title} close={back} />}>
      <LeafletPanelBody>
        <LeafletPanelSection>
          <LayerMenu layers={layers}></LayerMenu>
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(StaticLayerFolderPanel);
