import { PrecipApiResponse, PrecipApiSensor } from "../jefdaqApi/precip";

import { PrecipSensor, PrecipSensor_ } from "../models/Sensors";
import { isLengthUnit } from "../convert";
import notEmpty from "../shared/notEmpty";
import { precipIntervalSeconds } from "../shared/precipIntervals";

import { getSnapshot } from "mobx-state-tree";

export const precipApiToSensors = (
  precipApiResponse: PrecipApiResponse
): PrecipSensor_[] => {
  const apiIntervals = precipApiResponse.meta.precipitationIntervalSeconds;
  if (JSON.stringify(apiIntervals) !== JSON.stringify(precipIntervalSeconds)) {
    throw new Error("Precipitation intervals do not match");
  }

  const apiSensorToPrecipSensor = (
    apiSensor: PrecipApiSensor
  ): PrecipSensor_ | undefined => {
    const type = "precip";
    const id = "jefdaq_" + apiSensor.idx.toString();
    const depths = apiSensor.precipitationDepthsInches.map((d) =>
      d === apiSensor.null ? undefined : d
    );
    const lastReport = apiSensor.lastReport || undefined;


    const depthUnit = apiSensor.unitShort.toLowerCase().trim();
    if (!isLengthUnit(depthUnit)) {
      console.error("No compatible unit for precip sensor " + id);
      return undefined;
    }
    const intensityUnit = "in/h";

    return getSnapshot(PrecipSensor.create({
      ...apiSensor,
      ...precipApiResponse.meta,
      ...{ type, id, lastReport, depths, depthUnit, intensityUnit },
    }));
  };

  return precipApiResponse.data.map(apiSensorToPrecipSensor).filter(notEmpty);
};
