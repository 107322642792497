import { Sensor, SensorMeta, fetchSensorAPI, rootApiUrl } from "../jefdaqApi";

export interface StageApiSensor extends Sensor {
  idx: number;
  site: string;
  lastReport: string | null;
  latitude: number;
  longitude: number;
  name: string;
  source: string;
  null: number;
  stage: number | null;
  stages: (number | null)[];
  unitLong: string;
  unitShort: string;
}

export interface StageApiMeta extends SensorMeta {
  periodsSeconds: number[];
  timezone: string;
}

export interface StageApiResponse {
  meta: StageApiMeta;
  data: StageApiSensor[];
}

const url = rootApiUrl + "/json/stage/"
const check = /[feet|in]/i;

export const fetchStageAPI = () => fetchSensorAPI(url, check);
