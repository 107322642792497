import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";
import { lightningIcon } from "../shared/lightning";
import { IStrike } from "../models/VaisalaAPI";
import { useEffect, useState } from "react";
import { intervalToDuration } from "date-fns";
import { SVGOverlay } from "react-leaflet";
import { ILightning } from "../models/Layers";

interface StrikeProps {
  strike: IStrike;
  selected: boolean;
  set: () => void;
}

const Strike = observer(({ strike }: StrikeProps) => {
  const {
    layers: { lightning },
  } = useRootStore();

  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 60000);
    return () => clearInterval(interval);
  }, []);

  const selected = lightning?.selected?.id === strike.id;
  const click = () => lightning?.set(strike);

  const bounds = strike.position.toBounds(1000); // 1000 gives okay size at default zoom
  return (
    <SVGOverlay bounds={bounds} interactive={true} eventHandlers={{ click }}>
      {lightningIcon(intervalToDuration({ start: strike.local, end: date }))}

      {selected && (
        <rect
          x="0"
          y="0"
          width="100%"
          height="100%"
          stroke="grey"
          strokeWidth={4}
          rx={5}
          fillOpacity={0}
        />
      )}
    </SVGOverlay>
  );
});

interface LightningLayerProps {
  lightning: ILightning;
}
const LightningLayer = ({ lightning }: LightningLayerProps) => {
  const {
    vaisalaApi: { strikes },
  } = useRootStore();

  return (
    <>
      {strikes.map((strike) => (
        <Strike
          key={strike.id}
          strike={strike}
          selected={lightning.selected?.id === strike.id}
          set={() => lightning.set(strike)}
        />
      ))}
    </>
  );
};

export default observer(LightningLayer);
