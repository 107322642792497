import BookmarksPanel from "./panels/BookmarksPanel";
import CameraInfoPanel from "./info/CameraInfo";
import FeatureInfo from "./info/FeatureInfo";
import FindTable from "./FindTable";
import { IPanel } from "./models/Menus";
import { ISelectedSensor } from "./models/Sensors";
import LayersPanel from "./panels/LayersPanel";
import LeafletMobileCard from "./shared/LeafletMobileCard";
import LeafletSidebar from "./shared/LeafletSidebar";
import LightningInfo from "./info/LightningInfo";
import MeasurePanel from "./panels/MeasurePanel";
import NavMenu from "./NavMenu";
import RadarPanel from "./panels/RadarPanel";
import SensorInfo from "./info/SensorInfo";
import SensorsPanel from "./panels/SensorsPanel";
import TabularDataPane from "./panes/TabularData";
import { ZoomControl } from "react-leaflet";
import { observer } from "mobx-react-lite";
import { useRootStore } from "./models/Root";

const MobileSensorInfo = ({ sensor }: { sensor: ISelectedSensor }) => {
  switch (sensor.pane) {
    case "summary":
      return <SensorInfo selected={sensor} />;
    case "tabular":
      return <TabularDataPane sensor={sensor.sensor} />;
    default:
      return <> </>;
  }
};

const MobileCard = observer(() => {
  const getPanel = (panel: IPanel) => {
    switch (panel) {
      case "layers":
        return LayersPanel;
      case "bookmarks":
        return BookmarksPanel;
      case "measure":
        return MeasurePanel;
      case "sensors":
        return SensorsPanel;
      case "radar":
        return RadarPanel;
      case "find":
        return FindTable;
    }
  };

  const {
    menus: { panel },
    sensors: { selected: selectedSensor },
    layers: { selectedCamera, selectedFeature, lightning },
  } = useRootStore();

  if (selectedSensor) {
    return (
      <LeafletMobileCard>
        <MobileSensorInfo sensor={selectedSensor} />
      </LeafletMobileCard>
    );
  } else if (selectedFeature) {
    return (
      <LeafletMobileCard>
        <FeatureInfo feature={selectedFeature} />
      </LeafletMobileCard>
    );
  } else if (
    lightning &&
    (lightning.showLegend || lightning.selected !== undefined)
  ) {
    return (
      <LeafletMobileCard>
        <LightningInfo lightning={lightning} legend={false} />
      </LeafletMobileCard>
    );
  } else if (selectedCamera !== undefined) {
    return (
      <LeafletMobileCard>
        <CameraInfoPanel camera={selectedCamera} />
      </LeafletMobileCard>
    );
  } else if (panel && panel !== "find") {
    const Panel = getPanel(panel);
    return (
      <LeafletMobileCard>
        <Panel />
      </LeafletMobileCard>
    );
  } else if (panel === "find") {
    return (
      <LeafletMobileCard>
        <FindTable />
      </LeafletMobileCard>
    );
  } else {
    return <> </>;
  }
});

const Nav = observer(() => {
  const {
    menus: { nav },
  } = useRootStore();

  if (!nav) {
    return <></>;
  }

  return (
    <LeafletSidebar side="left" size="narrow">
      <div className="leaflet-control narrow">
        <NavMenu></NavMenu>
      </div>
    </LeafletSidebar>
  );
});

const Mobile = () => {
  return (
    <>
      <ZoomControl position="topright" />
      <Nav />
      <MobileCard />
    </>
  );
};

export default Mobile;
