import { zipWith } from "ramda";
import { isoToSeconds, parseISODuration, toSeconds } from "./duration";

export const precipIntervals = [
  "PT15M",
  "PT30M",
  "PT1H",
  "PT3H",
  "PT6H",
  "PT12H",
  "P1D",
  "P7D",
  "P14D",
  "P30D",
];
export const precipIntervalDurations = precipIntervals.map(parseISODuration);
export const precipIntervalSeconds = precipIntervals.map(isoToSeconds);
export const precipIntervalIdx = (interval: Duration): number =>
  precipIntervalSeconds.indexOf(toSeconds(interval));

export const withPrecipIntervalDurations = zipWith(
  (interval, depth: number | undefined) => ({ interval, depth }),
  precipIntervalDurations
);
