import { Duration } from "date-fns";

export const zero = {
  years: 0,
  months: 0,
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const formatISODuration = (duration: Duration): string => {
  if (typeof duration !== "object")
    throw new Error("Duration must be an object");

  const {
    years = 0,
    months = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
  } = duration;

  let iso = "P";
  if (years > 0) iso += `${years}Y`;
  if (months > 0) iso += `${months}M`;
  if (days > 0) iso += `${days}D`;
  if (hours + minutes + seconds > 0) {
    iso += "T";
    if (hours > 0) iso += `${hours}H`;
    if (minutes > 0) iso += `${minutes}M`;
    if (seconds > 0) iso += `${seconds}S`;
  }

  return iso;
};

// parts of a date-fns duration
const partsSeconds: Duration = {
  years: 365 * 24 * 60 * 60,
  months: 30 * 24 * 60 * 60,
  weeks: 7 * 24 * 60 * 60,
  days: 24 * 60 * 60,
  hours: 60 * 60,
  minutes: 60,
  seconds: 1,
};

// Heavily inspired by
// https://github.com/pke/date-fns-duration/blob/ae58a89140d385ed8e64dd74dd2024125b7003bc/index.js#L3
const ISO_PERIOD =
  /^(-|\+)?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;

export const parseISODuration = (isoDuration: string) => {
  isoDuration = isoDuration.trim();
  const matches = ISO_PERIOD.exec(isoDuration);
  if (!matches || isoDuration.length < 3) {
    throw new TypeError(
      `invalid duration: "${isoDuration}". Must be an ISO 8601 duration. See https://en.wikipedia.org/wiki/ISO_8601#Durations`
    );
  }
  const duration: Duration = {};

  Object.keys(partsSeconds).forEach((part, index) => {
    //@ts-ignore
    duration[part] = parseInt(matches[index + 2]) || undefined;
  });

  return duration;
};

export const toSeconds = (duration: Duration): number => {
  let secs = 0;
  Object.entries(partsSeconds).forEach(([part, partSecs]) => {
    //@ts-ignore
    const partDuration = duration[part];
    if (partDuration && partSecs) {
      secs += partDuration * partSecs;
    }
  });

  return secs;
};

export const isoToSeconds = (isoDuration: string): number =>
  toSeconds(parseISODuration(isoDuration));

export const isEqual = (a: Duration, b: Duration): boolean =>
  toSeconds(a) === toSeconds(b);

export const isLessOrEqual = (a: Duration, b: Duration): boolean =>
  toSeconds(a) <= toSeconds(b);

export const isGreater = (a: Duration, b: Duration): boolean =>
  toSeconds(a) > toSeconds(b);
