import { LatLng } from "leaflet";
import { Instance, flow, types } from "mobx-state-tree";


const { map, model, identifier, number, refinement, string } = types;

const float = refinement(number, (n) => !isNaN(n));

export const River = model({
    id: identifier,
    lat: float,
    lng: float,
    name: string,
    state: string,
    ifs: number
}).named("River")
    .views((self) => ({
        get position() {
            return new LatLng(self.lat, self.lng);
        }
    }))


export interface IRiver extends Instance<typeof River> { }

export const CbrfcAPI = model({
    rivers: map(River),
})
    .volatile((self) => ({
        fetching: false,
    }))
    .actions((self) => ({
        fetch: flow(function* f() {
            if (!self.fetching) {
                self.fetching = true;
                while (self.fetching) {
                    const url = 'https://eonvjzjqkgdwkoy.m.pipedream.net/'
                    const response = yield fetch(url);
                    if (response.ok) {
                        const json = yield response.json()
                        json.riverid.forEach((id: string, i: number) => {
                            const lat = json.riverlat[i]
                            const lng = json.riverlng[i]
                            const name = json.rivername[i]
                            const state = json.riverstat[i]
                            const ifs = json.riverifs[i]
                            const river = { id, lat, lng, name, state, ifs }
                            self.rivers.put(river)
                        })
                    }
                    yield new Promise((resolve) => setTimeout(resolve, 60000));
                }
            }
        })
    }))

