import { IStreamValues_, StreamSensor_ } from "../models/Sensors";
import { RatedApiResponse, RatedApiSensor } from "../jefdaqApi/rated";

import { VolumeFlowRateUnits } from "convert-units";
import notEmpty from "../shared/notEmpty";
import { streamPeriodSeconds } from "../shared/streamPeriods";

// Handles 'rated' API, but filtered to just discharge, hence 'stage'
export const ratedApiToSensors = (
  ratedApiResponse: RatedApiResponse
): StreamSensor_[] => {
  const { periodsSeconds, timezone } = ratedApiResponse.meta;

  // rated encodes 'latest' as 0 second period
  const expectedPeriodsSeconds = [0].concat(streamPeriodSeconds);
  if (
    JSON.stringify(periodsSeconds) !== JSON.stringify(expectedPeriodsSeconds)
  ) {
    throw new Error("Stage periods do not match");
  }
  const type = "Stage-Discharge";
  const dischargeIdx = ratingTypeIdx(ratedApiResponse, type);
  if (dischargeIdx === undefined) {
    return [];
  }

  interface RatedUnit {
    unit: VolumeFlowRateUnits;
    index: number; // in the array of units in API response
    idx: number; // normRatingUnits in JEFDAQ
  }
  const coerceApiUnit = (
    apiUnit: string,
    unit: VolumeFlowRateUnits,
    discharges: number[][][]
  ): RatedUnit | undefined => {
    const index = ratingUnitIndex(ratedApiResponse, type, apiUnit);
    if (index === undefined) {
      return undefined;
    }
    const idx = ratingUnitIdx(ratedApiResponse, type, index);
    if (idx !== undefined && discharges[index].length > 0) {
      return { unit, index, idx };
    }
    return undefined;
  };

  const apiSensorToStreamSensor = (
    apiSensor: RatedApiSensor
  ): StreamSensor_ | undefined => {
    const type = "stream"; // since rated is always stage while doing discharge
    const id = "jefdaq_" + apiSensor.idx.toString();
    const { name, source, site, lastReport, latitude, longitude } = apiSensor;
    const discharges = apiSensor.rateds[dischargeIdx];

    const cfs = coerceApiUnit("cfs", "ft3/s", discharges);
    const gpm = coerceApiUnit("gpm", "gal/min", discharges);
    const ratedUnit = cfs || gpm;

    if (ratedUnit === undefined) {
      console.error("No compatible unit for discharge sensor " + id);
      return undefined;
    }

    const dischargesForUnit = discharges[ratedUnit.index];

    // can assume this because expectedPeriodsSeconds already checked
    const [head, ...tail] = dischargesForUnit;
    if (tail.length !== streamPeriodSeconds.length) {
      // https://github.com/JEFuller/jefdaq/issues/305
      //console.error(
      //  "Can't match values with periods for discharge sensor" + idx
      //);
      return undefined;
    }

    const ratedToStageValue = (data: (number | null)[]): IStreamValues_ => {
      // https://github.com/JEFuller/jefdaq/blob/c930c0896485f1624537606c955b9b04bf5d6dc3/Core/sensorData.py#L470-L472
      // https://github.com/JEFuller/jefdaq/blob/c930c0896485f1624537606c955b9b04bf5d6dc3/Utilities/ratedValues.py#L430
      // https://github.com/JEFuller/jefdaq/blob/c930c0896485f1624537606c955b9b04bf5d6dc3/Utilities/ratedValues.py#L341-L342
      const dischargeOrNull = data[2];

      const actuallyNull = dischargeOrNull === null;
      const isNullValue = dischargeOrNull === apiSensor.null;
      const maybeDischarge =
        actuallyNull || isNullValue ? undefined : (dischargeOrNull as number);
      return { height: undefined, discharge: maybeDischarge };
    };

    const latest = ratedToStageValue(head);
    const stages = tail.map(ratedToStageValue);

    const sensor: StreamSensor_ = {
      type,
      id,
      timezone,
      latitude,
      longitude,
      name,
      source,
      site,
      lastReport,
      latest,
      values: stages,
      dischargeUnit: ratedUnit.unit,
    };

    return sensor;
  };

  return ratedApiResponse.data.map(apiSensorToStreamSensor).filter(notEmpty);
};

const ratingTypeIdx = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string
): number | undefined => {
  if (ratedApiResponse) {
    const {
      meta: { ratingTypes },
    } = ratedApiResponse;
    const idx = ratingTypes.indexOf(type);
    return idx >= 0 ? idx : undefined;
  } else {
    return undefined;
  }
};

const ratingUnitIndex = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string,
  unit: string
): number | undefined => {
  const typeIdx = ratingTypeIdx(ratedApiResponse, type);
  if (typeIdx !== undefined) {
    if (ratedApiResponse) {
      const {
        meta: { ratingUnits },
      } = ratedApiResponse;
      const idx = ratingUnits[typeIdx].indexOf(unit);
      return idx >= 0 ? idx : undefined;
    } else {
      return undefined;
    }
  }
};

export const ratingUnitIdx = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string,
  ratingUnitIndex: number
): number | undefined => {
  const typeIdx = ratingTypeIdx(ratedApiResponse, type);
  if (typeIdx !== undefined) {
    if (ratedApiResponse) {
      const {
        meta: { ratingUnitIdxs },
      } = ratedApiResponse;
      const idx = ratingUnitIdxs[typeIdx][ratingUnitIndex];
      return idx >= 0 ? idx : undefined;
    } else {
      return undefined;
    }
  }
};
