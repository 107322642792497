import { Button, Table } from "trunx";
import { PrecipM, PrecipNone } from "../../layers/PrecipMarker";
import {
  decimalPlaces,
  formatUnit,
  useLocaleContext,
} from "../../contexts/LocaleContext";

import { ISensorDisplayPrecip } from "../../models/Sensors";
import PrecipMarker from "../../layers/PrecipMarker";
import classNames from "classnames";
import config from "../../config";
import convert from "../../convert";
import { observer } from "mobx-react-lite";
import { scaleThreshold } from "d3-scale";
import { useRootStore } from "../../models/Root";

const PrecipLegend = ({ display }: { display: ISensorDisplayPrecip }) => {
  const currentMeasure = display.measure;
  const {
    dict: {
      panel: {
        sensors: { legend: dict },
      },
    },
    units: {
      precip: { depth: depthUnit, intensity: intensityUnit },
    },
  } = useLocaleContext();

  const precipConfig = config.markers.precip;

  const { breakValues } = precipConfig.tag;
  const colors = precipConfig.tag.backgroundColors;
  const colorScaleThreshold = scaleThreshold(breakValues, colors);

  const legendRows = colors.map((color) => {
    const [min, max] = colorScaleThreshold.invertExtent(color);
    if (max) {
      const min_ = min || 0;
      return {
        isLast: false,
        threshold: max,
        value: min_ + (max - min_) / 2.0,
      };
    } else if (min) {
      return { isLast: true, threshold: min, value: min };
    } else {
      throw new Error("invertExtent shouldn't do this");
    }
  });

  const {
    sensors: { displayPrecipWithMeasure },
  } = useRootStore();

  const unit = currentMeasure === "depth" ? depthUnit : intensityUnit;

  const MeasureButton = observer(
    ({ measure }: { measure: ISensorDisplayPrecip["measure"] }) => {
      const isSelected = currentMeasure === measure;
      return (
        <Button
          onClick={() => displayPrecipWithMeasure(measure)}
          className={classNames({
            "is-selected": isSelected,
            "is-primary": isSelected,
          })}
        >
          <span>{dict.precipMeasure[measure]}</span>
        </Button>
      );
    }
  );

  const LegendRow = ({
    isLast,
    threshold,
    value,
  }: {
    isLast: boolean;
    threshold: number;
    value: number;
  }) => {
    return (
      <tr key={value}>
        <td key="marker" style={{ backgroundColor: "darkgrey" }}>
          <PrecipMarker
            measure={currentMeasure}
            depth={value}
            intensity={value}
            depthUnit={"in"}
            intensityUnit={"in/h"}
          ></PrecipMarker>
        </td>
        <td key="threshold">
          {isLast ? "≥" : "<"}{" "}
          {convert(threshold)
            .from("in")
            .to(depthUnit)
            .toFixed(decimalPlaces(depthUnit))}{" "}
          {formatUnit(unit)}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="buttons has-addons" style={{ justifyContent: "center" }}>
        <MeasureButton measure={"depth"}></MeasureButton>
        <MeasureButton measure={"intensity"}></MeasureButton>
      </div>

      <Table isFullwidth isNarrow style={{ marginTop: 0, textAlign: "center" }}>
        <tbody>
          <tr key="missing">
            <td style={{ backgroundColor: "darkgrey" }}>
              <PrecipM />
            </td>
            <td>{dict.missing}</td>
          </tr>
          <tr key="zero">
            <td style={{ backgroundColor: "darkgrey" }}>
              <PrecipNone />
            </td>
            <td>0 {formatUnit(unit)}</td>
          </tr>
          {legendRows.map((rowProps) => (
            <LegendRow key={rowProps.value} {...rowProps}></LegendRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default observer(PrecipLegend);
