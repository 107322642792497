import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { isLessOrEqual } from "../shared/duration";


const color = (age: Duration) => {
  switch (true) {
    case isLessOrEqual(age, { minutes: 15 }):
      return "black";
    case isLessOrEqual(age, { minutes: 30 }):
      return "red";
    case isLessOrEqual(age, { minutes: 45 }):
      return "gold";
    default:
      return "green";
  }
};

export const lightningIcon = (age: Duration) =>
  React.createElement(FontAwesomeIcon, {
    icon: "bolt",
    color: color(age),
    opacity: 0.75
  });
