import { Feature, GeometryObject, Point } from "geojson";
import { LatLng, Layer, circleMarker } from "leaflet";

import { GeoJSON } from "react-leaflet";
import { Instance } from "mobx-state-tree";
import { StaticLayer as StaticLayerModel } from "../models/Layers";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";
import { useEffect, useState } from "react";

interface StaticLayerProps {
  model: Instance<typeof StaticLayerModel>;
}

const StaticLayer = ({ model }: StaticLayerProps) => {
  const { visible, color, geoJson } = model;
  const {
    layers: { setSelectedFeature },
  } = useRootStore();

  const onEachFeature = (feature: Feature<GeometryObject>, layer: Layer) => {
    layer.on({
      click: () => {
        setSelectedFeature(feature);
      },
    });
  };

  const pointToLayer = (_: Feature<Point, {}>, latlng: LatLng) => {
    return circleMarker(latlng, {});
  };

  const [visible_, setVisible] = useState(visible);

  useEffect(() => {
    setVisible(visible);
  }, [visible]);

  if (geoJson && visible_) {
    return (
      <GeoJSON
        data={geoJson}
        style={color ? { color } : undefined}
        onEachFeature={onEachFeature}
        pointToLayer={pointToLayer}
      ></GeoJSON>
    );
  } else {
    return null;
  }
};

export default observer(StaticLayer);
