import { VolumeFlowRateUnits } from "../convert";
import { rootApiUrl } from "../jefdaqApi";
import { ISelectedSensor, SelectedPrecipSensor } from "../models/Sensors";
import { RatedApiResponse, ratingUnitIdx, ratingUnitIndex } from "./rated";

const coerceDischargeToApiUnit = (unit: VolumeFlowRateUnits) => {
  switch (unit) {
    case "ft3/s":
      return "cfs";
    case "gal/min":
      return "gpm";
    default:
      return undefined;
  }
};

export const chartUrl = (
  ratedApiResponse: RatedApiResponse | undefined,
  selected: ISelectedSensor
) => {
  const site = selected.sensor.site;
  if (site === undefined) {
    return undefined;
  }
  if (SelectedPrecipSensor.is(selected)) {
    const url = rootApiUrl + "/plots/pgi_bar/";
    const params = new URLSearchParams({ sensorPair: site });
    return url + "?" + params.toString();
  } else {
    const { measure } = selected.display;
    // HEIGHT
    if (measure
      === "height") {
      const url = rootApiUrl + "/plots/sgi_line/";
      const params = new URLSearchParams({ sensorPair: site });
      return url + "?" + params.toString();
    }

    // DISCHARGE
    const { dischargeUnit } = selected.sensor;
    if (dischargeUnit === undefined) {
      return undefined;
    }
    const apiUnit = coerceDischargeToApiUnit(dischargeUnit);
    if (apiUnit === undefined) {
      return undefined;
    }

    const type = "Stage-Discharge";
    const index = ratingUnitIndex(ratedApiResponse, type, apiUnit);
    if (index === undefined) {
      return undefined;
    }
    const idx = ratingUnitIdx(ratedApiResponse, type, index);
    if (idx !== undefined) {
      const url = rootApiUrl + "/plots/dgi_line/";
      const params = new URLSearchParams({
        sensorPair: site,
        rateUnitsIDX: idx.toString(),
      });
      return url + "?" + params.toString();
    }

    return undefined;
  }
};
