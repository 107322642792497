import {
  faChartLine,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import { CircleMarker, Popup } from "react-leaflet";
import { Button, Icon, Level, LevelItem, Progress, bulma } from "trunx";
import { useLocaleContext } from "../contexts/LocaleContext";
import { IRiver } from "../models/CbrfcAPI";
import { ICbrfc } from "../models/Layers";
import { useRootStore } from "../models/Root";
import DivIconMarker from "./DivIconMarker";
import { useState } from "react";

interface RiverProps {
  river: IRiver;
  selected: boolean;
  set: () => void;
}

const River = observer(({ river, selected, set }: RiverProps) => {
  const {
    dict: {
      panel: { cbrfc: dict },
    },
  } = useLocaleContext();

  const {
    map: { zoom },
  } = useRootStore();

  const color = selected ? "red" : "rgb(34, 34, 34)";
  const weight = selected ? 2 : 1;

  const ifsColors = [
    "rgb(0, 127, 255)",
    "#00ff00",
    "#ffff00",
    "#ff8800",
    "#ff0000",
    "#8800ff",
  ];
  const fillColor = ifsColors[river.ifs - 1] ?? ifsColors[0];

  const ChartPopup = () => {
    const link = `https://www.cbrfc.noaa.gov/dbdata/station/flowgraph/flowgraph_hc.html?id=${river.id}&ptype=0&linear_flow=0`;
    const now = new Date().getTime();
    const chartUrl = `https://www.cbrfc.noaa.gov/dbdata/station/flowgraph/flowgraph_hc.py?id=${river.id}&ptype=1&linear_flow=0&zero_flow=1&show_stats=1&legend=0&show_title=0&width=600&ts=${now}`;

    const [loaded, setLoaded] = useState(false);

    const Loader = () => (
      <div>
        <div style={{ padding: "0 10px", color: "grey", fontSize: "1.2em" }}>
          <b>
            {river.id}&nbsp;{river.name}
          </b>
        </div>
        <Progress />
      </div>
    );

    return (
      <Popup position={river.position} minWidth={600} className="cbrfc-popup">
        {!loaded && <Loader />}
        <img
          src={chartUrl}
          alt="A chart"
          onClick={set}
          onLoad={() => setLoaded(true)}
        />
        <Level
          left={
            <LevelItem className={bulma("is-hidden-touch")}>
              <Button className={bulma(["is-ghost"])} onClick={set}>
                <span>{dict.details}</span>
                <Icon className={bulma("is-small")}>
                  <FontAwesomeIcon icon={faChartLine}></FontAwesomeIcon>
                </Icon>
              </Button>
            </LevelItem>
          }
          right={
            <LevelItem>
              <a href={link} rel="noopener noreferrer" target="_blank">
                {dict.open}&nbsp;cbrfc.noaa.gov
                <Icon className={"is-right"}>
                  <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
                </Icon>
              </a>
            </LevelItem>
          }
        />
      </Popup>
    );
  };

  return (
    <>
      <CircleMarker
        pathOptions={{ color, weight, fillColor }}
        center={river.position}
        radius={6}
        fillOpacity={1}
      >
        <ChartPopup />
      </CircleMarker>
      {zoom > 8 && (
        <DivIconMarker
          marker={{ position: river.position }}
          container={{ tagName: "div", className: "cbrfc-name" }}
        >
          <>
            <span>{river.id.slice(0, 3)}</span>
            <ChartPopup />
          </>
        </DivIconMarker>
      )}
    </>
  );
});

interface CbrfcLayerProps {
  cbrfc: ICbrfc;
}
const CbrfcLayer = ({ cbrfc }: CbrfcLayerProps) => {
  const {
    cbrfcApi: { rivers },
  } = useRootStore();

  return (
    <>
      {Array.from(rivers.values()).map((river) => (
        <River
          key={river.id}
          river={river}
          selected={cbrfc.selected?.id === river.id}
          set={() => cbrfc.set(river)}
        />
      ))}
    </>
  );
};

export default observer(CbrfcLayer);
