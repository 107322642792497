import { LengthUnits, SpeedUnits, Units } from "../convert";
import React, { ReactNode, createContext, useContext, useState } from "react";
import { enUS as dateEn, es as dateEs } from "date-fns/esm/locale";

import { Locale as DateLocale } from "date-fns";
import { Dictionary } from "../locale/dictionary";
import { VolumeFlowRateUnits } from "convert-units";
import config from "../config";
import en from "../locale/en";
import es from "../locale/es";

export type Lang = "en" | "es";

export interface UnitValue {
  value: number;
  unit: Units;
}

interface Locale {
  lang: Lang;
  setLang: (lang: Lang) => void;
  dict: Dictionary;
  dateLocale: DateLocale;
  unitSystem: "metric" | "imperial";
  units: {
    precip: {
      depth: LengthUnits;
      intensity: SpeedUnits;
    };
    stream: {
      height: LengthUnits;
      discharge: VolumeFlowRateUnits;
    };
  };
}

export const LocaleContext = createContext<Locale | undefined>(undefined);
export const useLocaleContext = () => {
  const localeContext = useContext(LocaleContext);
  if (!localeContext)
    throw new Error(
      "No LocaleContext.Provider found when calling useLocaleContext."
    );
  return localeContext;
};

export const LocaleContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [lang, setLang] = useState<Lang>(config.defaultLocale);
  const dict = lang === "en" ? en : es;
  const dateLocale = lang === "en" ? dateEn : dateEs;
  const unitSystem = lang === "en" ? "imperial" : "metric";
  const units: Locale["units"] = {
    precip: {
      depth: lang === "en" ? "in" : "mm",
      intensity: lang === "en" ? "in/h" : "mm/h",
    },
    stream: {
      height: lang === "en" ? "ft" : "m",
      discharge: lang === "en" ? "ft3/s" : "m3/s",
    },
  };

  return (
    <LocaleContext.Provider
      value={{ lang, setLang, dict, dateLocale, unitSystem, units }}
    >
      {children}
    </LocaleContext.Provider>
  );
};

export const decimalPlaces = (u: Units) => {
  switch (u) {
    case "in":
      return 2;
    case "ft":
      return 2;
    case "m":
      return 2;
    case "mm":
      return 0;
    case "in/h":
      return 2;
    case "mm/h":
      return 0;
    case "ft3/s":
      return 2;
    case "m3/s":
      return 2;
    default:
      throw new Error(`Unsupported unit: ${u}`);
  }
};

export const formatUnit = (u: Units) => {
  switch (u) {
    case "ft3/s":
      return "cfs";
    case "m3/s":
      return "m³/s";
    default:
      return u;
  }
};

export const unitFor = (
  units: Locale["units"],
  measure: "depth" | "intensity" | "height" | "discharge"
): Units => {
  switch (measure) {
    case "depth":
      return units.precip.depth;
    case "intensity":
      return units.precip.intensity;
    case "height":
      return units.stream.height;
    case "discharge":
      return units.stream.discharge;
  }
};
