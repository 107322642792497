import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "trunx";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { useLocaleContext } from "../../contexts/LocaleContext";

interface SensorIdsManifest {
  [id: string]: string;
}

interface AdditionalInformationLinkProps {
  sensorID: string;
}
const AdditionalInformationLink = ({
  sensorID,
}: AdditionalInformationLinkProps) => {
  const {
    dict: {
      panel: {
        sensors: {
          info: { additionalInformation },
        },
      },
    },
  } = useLocaleContext();

  const [link, setLink] = useState<string>();

  useEffect(() => {
    fetch("/sensorIDs.json")
      .then((res) => res.json())
      .then((manifest: SensorIdsManifest) => {
        setLink(manifest[sensorID]);
      });
  }, [sensorID]);

  if (link) {
    return (
      <div>
        <a href={`/${link}`} rel="noopener noreferrer" target="_blank">
          {additionalInformation}
          <Icon className={"is-right"}>
            <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
          </Icon>
        </a>
      </div>
    );
  } else return <></>;
};

export default AdditionalInformationLink;
