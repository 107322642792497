import RadarContext, { toApiTimeFormat } from "../contexts/RadarContext";
import React, { useContext } from "react";

import { TileLayer } from "react-leaflet";
export interface RadarLayerProps {
  opacity: number;
}

const RadarLayer = ({ opacity }: RadarLayerProps) => {
  const radar = useContext(RadarContext);
  if (radar?.apiResponse) {
    const { frame } = radar;
    const { baseURL, nullTile, times } = radar.apiResponse;
    const layers = times.map((time, i) => (
      <TileLayer
        zIndex={100}
        key={time.toISOString()}
        errorTileUrl={nullTile}
        url={baseURL + "/" + toApiTimeFormat(time) + "/{z}/{x}/{y}.png"}
        opacity={frame === i ? opacity : 0}
      />
    ));
    return <>{layers}</>;
  } else {
    return <></>;
  }
};

export default RadarLayer;
