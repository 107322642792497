import { isLengthUnit, isVolumeFlowRateUnit } from "../../convert";
import {
  normalizeUnit,
  TabularData as JefdaqTabularData,
  TabularDataRated as JefdaqTabularDataRated,
  tabularDataUrl,
} from "../../jefdaqApi";

import { ISensor } from "../../models/Sensors";
import {
  TabularData,
  TabularDataOneColumn,
  TabularDataTwoColumns,
} from "../useTabularData";

const get = async (
  sensor: ISensor,
  startDate: Date,
  endDate: Date
): Promise<TabularData> => {
  if (sensor.site === undefined) {
    return Promise.reject(new Error("JEFDAQ sensor needs site"));
  }

  const headers = { "Content-Type": "application/json" };
  const range = { startDate, endDate };

  const primaryUrl = tabularDataUrl(sensor.site, range).toString();
  const primaryFetch: Promise<JefdaqTabularData> = fetch(primaryUrl, {
    headers,
  }).then((res) => res.json());

  const ratedUrl = tabularDataUrl(sensor.site, range, true).toString();
  const ratedFetch: Promise<JefdaqTabularDataRated | undefined> = fetch(
    ratedUrl,
    { headers }
  )
    .then((res) => (res.ok ? res.json() : undefined))
    .catch(() => undefined);

  const [primary, rated] = await Promise.all([primaryFetch, ratedFetch]);

  const [primaryType_, primaryUnit_] = primary.meta.units;
  const primaryType = primaryType_.trim().toLowerCase();

  const primaryUnit = primaryUnit_.trim().toLowerCase();
  if (sensor.type === 'stream') {
    if (!primaryType.includes('stage')) {
      return Promise.reject(new Error("Sensor type mismatch"));
    }
  } else if (!primaryType.includes(sensor.type)) {
    return Promise.reject(new Error("Sensor type mismatch"));
  }

  if (!isLengthUnit(primaryUnit)) {
    return Promise.reject(new Error(`Unhandled primary unit ${primaryUnit}`));
  }

  if (rated !== undefined) {
    const [primaryType_, primaryUnit_, ratedType_, ratedUnit_] =
      rated.meta.units;
    const ratedType = ratedType_.trim().toLowerCase();

    if (primaryType !== primaryType_.trim().toLowerCase()) {
      return Promise.reject(new Error("Primary type mismatch"));
    }
    if (primaryUnit !== primaryUnit_.trim().toLowerCase()) {
      return Promise.reject(new Error("Primary unit mismatch"));
    }
    if (ratedType !== "stage") {
      return Promise.reject(new Error("Can only handle stage rated data"));
    }

    const ratedUnit = normalizeUnit(ratedUnit_);
    if (ratedUnit === undefined || !isVolumeFlowRateUnit(ratedUnit)) {
      return Promise.reject(new Error(`Unhandled rated unit ${ratedUnit}`));
    }

    const rows: TabularDataTwoColumns = new Map();
    primary.data.forEach(({ utc, val }) => {
      const date = new Date(utc);
      const rated_ = rated.data.find((d) => d.utc === utc);
      if (rated_ !== undefined) {
        rows.set(date, [val, rated_.val]);
      } else {
        console.warn(`No rated data for ${sensor.name} at ${date}`);
      }
    });

    const data: TabularData = {
      type: "TwoColumns",
      units: [primaryUnit, ratedUnit],
      rows,
    };

    return Promise.resolve(data);
  } else {
    const rows: TabularDataOneColumn = new Map();
    primary.data.forEach(({ utc, val }) => {
      const date = new Date(utc);
      rows.set(date, val);
    });

    const data: TabularData = {
      type: "OneColumn",
      unit: primaryUnit,
      rows,
    };

    return Promise.resolve(data);
  }
};

export default get;
