import configureMeasurements, {
  LengthSystems,
  LengthUnits as LengthUnits_,
  Measure,
  SpeedSystems,
  SpeedUnits as SpeedUnits_,
  VolumeFlowRateSystems,
  VolumeFlowRateUnits as VolumeFlowRateUnits_,
  length,
  speed,
  volumeFlowRate,
} from "convert-units";

export type LengthUnits = LengthUnits_;
export type VolumeFlowRateUnits = VolumeFlowRateUnits_;
export type SpeedUnits = SpeedUnits_ | "in/h" | "mm/h";

const extendedSpeed: Measure<SpeedSystems, SpeedUnits> = {
  systems: {
    metric: {
      ...speed.systems.metric,
      "mm/h": {
        name: {
          singular: "Millimeter per hour",
          plural: "Millimeters per hour",
        },
        to_anchor: 0.000001,
      },
    },
    imperial: {
      ...speed.systems.imperial,
      "in/h": {
        name: {
          singular: "Inch per hour",
          plural: "Inches per hour",
        },
        to_anchor: 0.00001578,
      },
    },
  },
  anchors: { ...speed.anchors },
};

export type Measures = "length" | "volumeFlowRate" | "speed";
export type Systems = LengthSystems | VolumeFlowRateSystems | SpeedSystems;
export type Units = LengthUnits | VolumeFlowRateUnits | SpeedUnits;

const convert = configureMeasurements<Measures, Systems, Units>({
  length,
  volumeFlowRate,
  speed: extendedSpeed,
});

export default convert;

export const isUnit = (str: string): str is Units =>
  !!convert()
    .possibilities()
    .find((s) => str === s);

export const isLengthUnit = (str: string): str is LengthUnits =>
  !!convert()
    .possibilities("length")
    .find((s) => str === s);

export const isVolumeFlowRateUnit = (str: string): str is VolumeFlowRateUnits =>
  !!convert()
    .possibilities("volumeFlowRate")
    .find((s) => str === s);
