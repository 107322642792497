import { PrecipApiResponse, fetchPrecipAPI } from "../jefdaqApi/precip";
import { RatedApiResponse, fetchRatedAPI } from "../jefdaqApi/rated";
import { Sensor, SensorApiResponse, SensorMeta } from "../jefdaqApi";
import { StageApiResponse, fetchStageAPI } from "../jefdaqApi/stage";
import { flow, types } from "mobx-state-tree";

import { fetchFeatureAPI } from "../jefdaqApi/features";

const { maybe, frozen, model } = types;

const wait = () => new Promise((resolve) => setTimeout(resolve, 5000));

type FetchFactory = () => Promise<SensorApiResponse<SensorMeta, Sensor>>

const ApiRoute = <R>(createFetch: FetchFactory) =>
  model({
    loading: false,
    response: maybe(frozen<R>()),
  })
    .volatile((self) => ({
      fetching: false,
    }))
    .actions((self) => ({
      fetch: flow(function* f() {
        if (!self.fetching) {
          self.fetching = true;
          while (self.fetching) {
            self.loading = true;
            try {
              self.response = yield createFetch();
            } catch (e) {
              console.error('Bad JEFDAQ API response', e);
            }
            self.loading = false;
            yield wait();
          }
        }
      }),
    }));

export const JefdaqAPI = model({
  features: model({ api: false }),
  precip: ApiRoute<PrecipApiResponse>(fetchPrecipAPI),
  stage: ApiRoute<StageApiResponse>(fetchStageAPI),
  rated: ApiRoute<RatedApiResponse>(fetchRatedAPI),
}).actions((self) => ({
  fetch: flow(function* f() {
    try {
      self.features = yield fetchFeatureAPI;
    } catch (e) {
      // JEFDAQ instance too old to support features API
    }

    yield Promise.all([
      self.precip.fetch(),
      self.stage.fetch(),
      self.rated.fetch().catch(() => {
        console.error("Couldn't fetch rated API");
      }),
    ]);
  }),
}));
