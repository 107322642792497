import { ISelectedSensor } from "../../models/Sensors";
import { Embed as ChartEmbed } from "../../panes/Chart";

interface MinichartProps {
  selected: ISelectedSensor;
  url: string | undefined;
  api: boolean;
}

const MiniChart = ({ api, selected, url }: MinichartProps) => {
  const { sensor } = selected;
  if (!sensor.site) {
    return <></>;
  }
  if (api) {
    return <ChartEmbed selected={selected}></ChartEmbed>;
  } else {
    if (url !== undefined) {
      return (
        <iframe
          style={{ display: "block" }}
          title="plot"
          src={url.toString()}
        ></iframe>
      );
    }
    return <></>;
  }
};

export default MiniChart;
