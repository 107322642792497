import BookmarksPanel from "./panels/BookmarksPanel";
import CameraInfoPanel from "./info/CameraInfo";
import CbrfcInfo from "./info/CbrfcInfo";
import Chart from "./panes/Chart";
import FeatureInfo from "./info/FeatureInfo";
import FindTable from "./FindTable";
import { IPanel } from "./models/Menus";
import { ISelectedSensor } from "./models/Sensors";
import LayersPanel from "./panels/LayersPanel";
import LeafletSidebar from "./shared/LeafletSidebar";
import LightningInfo from "./info/LightningInfo";
import MeasurePanel from "./panels/MeasurePanel";
import NavMenu from "./NavMenu";
import RadarPanel from "./panels/RadarPanel";
import SensorInfo from "./info/SensorInfo";
import SensorsPanel from "./panels/SensorsPanel";
import TabularDataPane from "./panes/TabularData";
import { ZoomControl } from "react-leaflet";
import { observer } from "mobx-react-lite";
import { useRootStore } from "./models/Root";

const LeftSidebar = observer(() => {
  const getPanel = (panel: IPanel) => {
    switch (panel) {
      case "layers":
        return LayersPanel;
      case "bookmarks":
        return BookmarksPanel;
      case "measure":
        return MeasurePanel;
      case "sensors":
        return SensorsPanel;
      case "radar":
        return RadarPanel;
      case "find":
        return FindTable;
    }
  };

  const {
    menus: { nav, panel },
  } = useRootStore();

  if (panel) {
    const Panel = getPanel(panel);
    return (
      <LeafletSidebar side="left" size="normal">
        <div className="leaflet-control">
          <Panel />
        </div>
      </LeafletSidebar>
    );
  } else if (nav) {
    return (
      <LeafletSidebar side="left" size="narrow">
        <div className="leaflet-control narrow">
          <NavMenu></NavMenu>
        </div>
      </LeafletSidebar>
    );
  } else {
    return <> </>;
  }
});

const RightSidebarSensorInfo = ({ sensor }: { sensor: ISelectedSensor }) => {
  switch (sensor.pane) {
    case "summary":
      return <SensorInfo selected={sensor} />;
    case "tabular":
      return <TabularDataPane sensor={sensor.sensor} />;
    case "chart":
      return <Chart selected={sensor} />;
  }
};

const RightSidebar = observer(() => {
  const {
    menus: { closePanel, closeNav },
    sensors: { selected: selectedSensor },
    layers: { selectedCamera, selectedFeature, lightning, cbrfc },
  } = useRootStore();

  if (selectedSensor) {
    const size = selectedSensor.pane === "summary" ? "normal" : "full";

    if (size === "full") {
      closePanel();
      closeNav();
    }

    return (
      <LeafletSidebar side="right" size={size}>
        <RightSidebarSensorInfo sensor={selectedSensor} />
      </LeafletSidebar>
    );
  } else if (selectedFeature) {
    return (
      <LeafletSidebar side="right" size="normal">
        <FeatureInfo feature={selectedFeature} />
      </LeafletSidebar>
    );
  } else if (
    lightning &&
    (lightning.showLegend || lightning.selected !== undefined)
  ) {
    return (
      <LeafletSidebar side="right" size="normal">
        <LightningInfo lightning={lightning} legend={true}/>
      </LeafletSidebar>
    );
  } else if (cbrfc && cbrfc.selected !== undefined) {
    return (
      <LeafletSidebar side="right" size="full">
        <CbrfcInfo cbrfc={cbrfc} />
      </LeafletSidebar>
    );
  } else if (selectedCamera !== undefined) {
    return (
      <LeafletSidebar side="right" size="full">
        <CameraInfoPanel camera={selectedCamera} />
      </LeafletSidebar>
    );
  } else {
    return <></>;
  }
});

const Desktop = () => {
  return (
    <>
      <ZoomControl position="bottomright" />
      <LeftSidebar />
      <RightSidebar />
    </>
  );
};

export default Desktop;
