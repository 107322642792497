import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { SensorDisplayPrecip, SensorDisplayStream } from "../models/Sensors";

import PrecipLegend from "./SensorsPanel/PrecipLegend";
import SelectSensorFilter from "./SensorsPanel/SelectSensorFilter";
import StreamLegend from "./SensorsPanel/StreamLegend";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../contexts/LocaleContext";
import { useRootStore } from "../models/Root";

const SensorsPanel = () => {
  const {
    dict: {
      panel: { sensors: dict },
    },
  } = useLocaleContext();

  const {
    sensors: { display },
    menus: { closePanel },
  } = useRootStore();

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
    >
      <LeafletPanelBody minHeight='500px'>
        <LeafletPanelSection>
          <SelectSensorFilter />
          {SensorDisplayPrecip.is(display) && (
            <PrecipLegend display={display} />
          )}
          {SensorDisplayStream.is(display) && (
            <StreamLegend display={display} />
          )}
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(SensorsPanel);
