import { DynamicMapLayerOptions, dynamicMapLayer } from "esri-leaflet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useCreateStore, useProvider } from "mobx-store-provider";
import { useEffect, useState } from "react";

import BaseMapDropdown from "./BaseMapDropdown";
import { CbrfcAPI } from "./models/CbrfcAPI";
import Desktop from "./Desktop";
import Disclaimer from "./Disclaimer";
import { JefdaqAPI } from "./models/JefdaqAPI";
import { Layers } from "./models/Layers";
import { Map as LeafletMap } from "leaflet";
import { LocaleContextProvider } from "./contexts/LocaleContext";
import Map from "./Map";
import { MapContainer } from "react-leaflet";
import { Menus } from "./models/Menus";
import Mobile from "./Mobile";
import Navbar from "./Navbar";
import { RadarContextProvider } from "./contexts/RadarContext";
import Root from "./models/Root";
import { Sensors } from "./models/Sensors";
import { VaisalaAPI } from "./models/VaisalaAPI";
import { conaguaToPrecipSensor } from "./conaguaToSensorModel";
import config from "./config";
import { observer } from "mobx-react-lite";
import { reaction } from "mobx";
import { useConagua } from "./useConagua";
import { useWindowSize } from "react-use";

const App = () => {
  const jefdaqApiStore = useCreateStore(JefdaqAPI, {
    features: { api: false }, // assume false, will be set to true if API is available
    precip: { loading: false, response: undefined },
    stage: { loading: false, response: undefined },
    rated: { loading: false, response: undefined },
  });

  const sensorStore = useCreateStore(Sensors, {
    display: config.defaultDisplay,
  });

  const { putSensor } = sensorStore;

  const menuStore = useCreateStore(Menus);

  const conaguaResponse = useConagua();

  useEffect(() => {
    if (conaguaResponse) {
      const sensors = conaguaToPrecipSensor(conaguaResponse);
      //@ts-ignore
      sensors.forEach(putSensor);
    }
  }, [putSensor, conaguaResponse]);

  const layerStore = useCreateStore(Layers, {
    baseMap: config.basemap,
    static: config.layers.static,
    dynamic: config.layers.dynamic,
    misc: config.layers.misc,
  });

  const vaisalaApiStore = useCreateStore(VaisalaAPI);
  const cbrfcApiStore = useCreateStore(CbrfcAPI);

  const [mapCreateHandled, setMapCreateHandled] = useState(false);

  const [map, setMap] = useState<LeafletMap | undefined>(undefined);
  const mapCreated = (map_: LeafletMap) => {
    if (map_ === null || mapCreateHandled) {
      return;
    }

    setMap(map_);

    map_.attributionControl.addAttribution(
      '<a href="https://www.jefuller.com">JEFDAQ</a>'
    );

    layerStore.dynamic.forEach(({ url, visibleLayers, setMetadata }) => {
      const options: DynamicMapLayerOptions = { url, layers: [] };
      const mapLayer = dynamicMapLayer(options).addTo(map_);
      mapLayer.metadata((_, { layers }) => setMetadata(layers));
      reaction(
        () => [visibleLayers, visibleLayers.length], // need length to trigger update
        (visibleLayers) => {
          mapLayer.setLayers(visibleLayers);
        }
      );
    });

    setMapCreateHandled(true);
  };

  const { height, width } = useWindowSize();
  useEffect(() => {
    const mapContainer = document.querySelector(".leaflet-container");
    if (mapContainer && map) {
      const navbarHeight = 60;
      mapContainer.setAttribute("style", `height: ${height - navbarHeight}px`);
      map.invalidateSize();
    }
  }, [height, map]);

  const StoreProvider = useProvider(Root);
  const rootStore = useCreateStore(Root, {
    jefdaqApi: jefdaqApiStore,
    vaisalaApi: vaisalaApiStore,
    cbrfcApi: cbrfcApiStore,
    sensors: sensorStore,
    layers: layerStore,
    menus: menuStore,
    map: { zoom: config.zoom },
  });

  useEffect(() => {
    jefdaqApiStore.fetch();
  }, [jefdaqApiStore]);

  useEffect(() => {
    config.layers.lightning && vaisalaApiStore.fetch();
  }, [vaisalaApiStore]);

  useEffect(() => {
    config.layers.cbrfc && cbrfcApiStore.fetch();
  }, [cbrfcApiStore]);
  layerStore.showCbrfc();

  rootStore.updateWidth(width);
  const { isMobile } = rootStore;

  if (!isMobile && config.defaultMenuPanel) {
    menuStore.openPanel(config.defaultMenuPanel);
  }

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StoreProvider value={rootStore}>
        <LocaleContextProvider>
          <RadarContextProvider>
            {config.disclaimer && (
              <Disclaimer outLink={config.disclaimer.outLink}>
                {config.disclaimer.body}
              </Disclaimer>
            )}
            <Navbar/>
            <MapContainer
              center={config.center}
              zoom={config.zoom}
              zoomControl={false}
              ref={mapCreated}
              className="sensors-loading"
            >
              <Map></Map>
              <BaseMapDropdown />
              {isMobile && <Mobile />}
              {!isMobile && <Desktop />}
            </MapContainer>
          </RadarContextProvider>
        </LocaleContextProvider>
      </StoreProvider>
    </QueryClientProvider>
  );
};

export default observer(App);
