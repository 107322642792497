import { ISensor, isPrecipSensor } from "./models/Sensors";
import { Icon, Table } from "trunx";

import BookmarkStar from "./shared/BookmarkStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import useFindSensors from "./hooks/useFindSensor";
import { useLocaleContext } from "./contexts/LocaleContext";
import { useRootStore } from "./models/Root";

const FindTable = observer(() => {
  const {
    dict: {
      panel: { find: dict },
    },
  } = useLocaleContext();

  const {
    sensors: { setSensor },
    menus: { find, setFind },
  } = useRootStore();

  const results = useFindSensors(find);

  const resultRow = (result: Fuzzysort.KeyResult<ISensor>) => {
    const sensor = result.obj;
    const { name, site } = sensor;

    const onClick = () => {
      setFind("");
      setSensor(sensor);
    };
    return (
      <tr key={sensor.id}>
        <td>
          <BookmarkStar sensor={sensor} />
        </td>
        <td>{name}</td>
        <td>{site}</td>
        <td className="sensor-type">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid*/}
          <a onClick={onClick}>
            {isPrecipSensor(sensor) ? dict.precip : dict.stream}
            <Icon>
              <FontAwesomeIcon icon="external-link-alt"></FontAwesomeIcon>
            </Icon>
          </a>
        </td>
      </tr>
    );
  };

  if (results === undefined) {
    return <></>;
  }

  return (
    <Table isFullwidth className="find-table">
      <tbody>
        <tr>
          <th colSpan={2}>{dict.name}</th>
          <th colSpan={2}>ID</th>
        </tr>
        {results.map(resultRow)}
      </tbody>
    </Table>
  );
});

export default FindTable