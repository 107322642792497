import convert from "../convert";

import { unitFor, useLocaleContext } from "../contexts/LocaleContext";
import { normalizeUnit } from "../jefdaqApi";
import { chartUrl } from "../jefdaqApi/chart";
import { useRootStore } from "../models/Root";
import { ISelectedSensor, SensorDisplayPrecip } from "../models/Sensors";
import { RatedApiResponse } from "../jefdaqApi/rated";
import { useQuery } from "@tanstack/react-query";
import { toSeconds } from "../shared/duration";

export interface ChartApiResponse {
  dur: number;
  interval: number;
  source: {
    data: number[][];
    max: number;
    min: number;
    unit?: string;
    yunits?: string;
  };
}

type FetchParams = {
  queryKey: [
    string,
    {
      ratedApiResponse: RatedApiResponse | undefined;
      selected: ISelectedSensor;
    }
  ];
};
export const fetchChartApi = async ({
  queryKey,
}: FetchParams): Promise<ChartApiResponse> => {
  const { ratedApiResponse, selected } = queryKey[1];

  if (typeof ratedApiResponse === "undefined") {
    throw new Error("ratedApiResponse is undefined");
  }

  const url = chartUrl(ratedApiResponse, selected);
  if (url === undefined) {
    throw new Error("Could not create URL for chart API");
  }

  const headers = { "Content-Type": "application/json" };
  const res = await fetch(url.toString(), { headers });

  if (!res.ok) {
    throw new Error("Bad response from chart API");
  }

  return res.json();
};

export type ChartApiResponseLocalized = ChartApiResponse;

const useChartData = (selected: ISelectedSensor) => {
  const { units } = useLocaleContext();
  const { display } = selected;
  const toUnit = unitFor(units, display.measure);

  const {
    jefdaqApi: {
      rated: { response: ratedApiResponse },
    },
  } = useRootStore();

  const chartData = useQuery(
    ["chart", { selected, ratedApiResponse }],
    fetchChartApi,
    { enabled: ratedApiResponse !== undefined }
  );

  if (chartData.isSuccess) {
    const { data } = chartData;
    const { source } = data;
    const unit_ = source.unit || source.yunits;
    if (unit_ === undefined) {
      throw new Error("No unit in API response");
    }

    const isIntensity =
      SensorDisplayPrecip.is(display) && display.measure === "intensity";
    const multiplier = isIntensity
      ? toSeconds({ hours: 1 }) / toSeconds(display.interval)
      : 1;

    const fromUnit = isIntensity ? "in/h" : normalizeUnit(unit_);
    if (fromUnit === undefined) {
      throw new Error(`Unhandled unit ${fromUnit}`);
    }

    const data_ = source.data.map(([time, value]: number[]) => [
      time,
      convert(value * multiplier)
        .from(isIntensity ? "in/h" : fromUnit)
        .to(toUnit),
    ]);
    return {
      ...data,
      source: {
        data: data_,
        min: convert(source.min * multiplier)
          .from(fromUnit)
          .to(toUnit),
        max: convert(source.max * multiplier)
          .from(fromUnit)
          .to(toUnit),
        unit: toUnit
      },
    };
  }
};

export default useChartData;
