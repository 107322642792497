import { Sensor, SensorMeta, fetchSensorAPI, rootApiUrl } from "../jefdaqApi";

export interface RatedApiSensor extends Sensor {
  idx: number;
  site: string;
  lastReport: string;
  latitude: number;
  longitude: number;
  name: string;
  source: string;
  null: number;
  rateds: number[][][][];
}

export interface RatedApiMeta extends SensorMeta {
  periodsSeconds: number[];
  ratingTypes: string[];
  ratingUnitIdxs: number[][];
  ratingUnits: string[][];
  timezone: string;
}

export interface RatedApiResponse {
  meta: RatedApiMeta;
  data: RatedApiSensor[];
}

export const url = rootApiUrl + "/json/rated/"
const check = /.*/i;

export const fetchRatedAPI = () => fetchSensorAPI(url, check);

const ratingTypeIdx = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string
): number | undefined => {
  if (ratedApiResponse) {
    const {
      meta: { ratingTypes },
    } = ratedApiResponse;
    const idx = ratingTypes.indexOf(type);
    return idx >= 0 ? idx : undefined;
  } else {
    return undefined;
  }
};

export const ratingUnitIndex = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string,
  unit: string
): number | undefined => {
  const typeIdx = ratingTypeIdx(ratedApiResponse, type);
  if (typeIdx !== undefined) {
    if (ratedApiResponse) {
      const {
        meta: { ratingUnits },
      } = ratedApiResponse;
      const idx = ratingUnits[typeIdx].indexOf(unit);
      return idx >= 0 ? idx : undefined;
    } else {
      return undefined;
    }
  }
};

export const ratingUnitIdx = (
  ratedApiResponse: RatedApiResponse | undefined,
  type: string,
  ratingUnitIndex: number
): number | undefined => {
  const typeIdx = ratingTypeIdx(ratedApiResponse, type);
  if (typeIdx !== undefined) {
    if (ratedApiResponse) {
      const {
        meta: { ratingUnitIdxs },
      } = ratedApiResponse;
      const idx = ratingUnitIdxs[typeIdx][ratingUnitIndex];
      return idx >= 0 ? idx : undefined;
    } else {
      return undefined;
    }
  }
};
