import { Button, Table } from "trunx";
import {
  decimalPlaces,
  formatUnit,
  useLocaleContext,
} from "../../contexts/LocaleContext";

import { ISensorDisplayStream } from "../../models/Sensors";
import { StreamM } from "../../layers/StreamMarker";
import StreamMarker from "../../layers/StreamMarker";
import classNames from "classnames";
import config from "../../config";
import convert from "../../convert";
import { observer } from "mobx-react-lite";
import { scaleThreshold } from "d3-scale";
import { useRootStore } from "../../models/Root";

const StreamLegend = ({ display }: { display: ISensorDisplayStream }) => {
  const currentMeasure = display.measure;

  const {
    dict: {
      panel: {
        sensors: { legend: dict },
      },
    },
    units: {
      stream: { height: heightUnit, discharge: dischargeUnit },
    },
  } = useLocaleContext();

  const {
    sensors: { displayStreamWithMeasure },
  } = useRootStore();

  const { backgroundColors, heightBreakValues, dischargeBreakValues } =
    config.markers.stream;
  const breakValues =
    currentMeasure === "height" ? heightBreakValues : dischargeBreakValues;
  const colorScaleThreshold = scaleThreshold(breakValues, backgroundColors);

  const legendRows = backgroundColors.map((color) => {
    const [min, max] = colorScaleThreshold.invertExtent(color);
    if (max) {
      const min_ = min || 0;
      return {
        isLast: false,
        threshold: max,
        value: min_ + (max - min_) / 2.0,
      };
    } else if (min) {
      return { isLast: true, threshold: min, value: min };
    } else {
      throw new Error("invertExtent shouldn't do this");
    }
  });

  const unit = currentMeasure === "height" ? heightUnit : dischargeUnit;

  const MeasureButton = ({
    measure,
  }: {
    measure: ISensorDisplayStream["measure"];
  }) => {
    const isSelected = currentMeasure === measure;
    return (
      <Button
        onClick={() => displayStreamWithMeasure(measure)}
        className={classNames({
          "is-selected": isSelected,
          "is-primary": isSelected,
        })}
      >
        <span>{dict.streamMeasure[measure]}</span>
      </Button>
    );
  };

  const LegendRow = ({
    isLast,
    threshold,
    value,
  }: {
    isLast: boolean;
    threshold: number;
    value: number;
  }) => {
    return (
      <tr key={value}>
        <td key="marker" style={{ backgroundColor: "darkgrey" }}>
          <StreamMarker
            measure={currentMeasure}
            height={value}
            discharge={value}
            heightUnit={"ft"}
            dischargeUnit={"ft3/s"}
          ></StreamMarker>
        </td>
        <td key="threshold">
          {isLast ? "≥" : "<"}{" "}
          {convert(threshold)
            .from("ft")
            .to(heightUnit)
            .toFixed(decimalPlaces(heightUnit))}{" "}
          {formatUnit(unit)}
        </td>
      </tr>
    );
  };

  return (
    <>
      <div className="buttons has-addons" style={{ justifyContent: "center" }}>
        <MeasureButton measure={"height"}></MeasureButton>
        <MeasureButton measure={"discharge"}></MeasureButton>
      </div>

      <Table isFullwidth isNarrow style={{ marginTop: 0, textAlign: "center" }}>
        <tbody>
          <tr key="missing">
            <td style={{ backgroundColor: "darkgrey" }}>
              <StreamM />
            </td>
            <td>{dict.missing}</td>
          </tr>
          {legendRows.map((rowProps) => (
            <LegendRow key={rowProps.value} {...rowProps}></LegendRow>
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default observer(StreamLegend);
