import { AxisOptions, AxisOptionsBase, Chart as ReactChart } from "react-charts";
import { ISelectedSensor, SelectedPrecipSensor } from "../models/Sensors";
import { Icon, Progress, Subtitle, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelSection,
  LeafletSensorPanelHeading,
} from "../shared/LeafletPanel";
import { ReactNode, useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import useChartData from "../hooks/useChartData";
import { useLocaleContext } from "../contexts/LocaleContext";

export const Embed = ({ selected }: { selected: ISelectedSensor }) => {
  const { dict } = useLocaleContext();
  const chartData = useChartData(selected);
  const height = "200px";

  const elementType: AxisOptionsBase["elementType"] = SelectedPrecipSensor.is(
    selected
  )
    ? "bar"
    : "line";

  const primaryAxis = useMemo(
    (): AxisOptions<number[]> => ({
      getValue: (datum) => new Date(datum[0]),
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<number[]>[] => [
      {
        getValue: (datum) => datum[1],
        elementType,
      },
    ],
    [elementType]
  );

  if (!chartData) {
    return (
      <div style={{ height, width: "100%" }}>
        <Progress
          className={bulma("is-align-self-center")}
          max={100}
        ></Progress>
      </div>
    );
  } else if (chartData.source.data.length === 0) {
    return (
      <div style={{ padding: "2rem 0" }}>
        <span className="icon-text">
          <Icon>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Icon>
          <span>{dict.pane.chart.noData}&nbsp;</span>
        </span>
      </div>
    );
  } else {
    const {
      source: { data, unit },
    } = chartData;
    return (
      <div style={{ height, width: "100%" }}>
        <ReactChart
          options={{
            data: [{ label: unit, data }],
            primaryAxis,
            secondaryAxes,
          }}
        />
      </div>
    );
  }
};
const Chart = ({ selected }: { selected: ISelectedSensor }) => {
  const { sensor } = selected;
  const ChartPanel = ({ children }: { children: ReactNode }) => (
    <LeafletPanel>
      <LeafletSensorPanelHeading sensor={sensor} action="back" />

      <LeafletPanelBody>
        <LeafletPanelSection>
          <Subtitle className={bulma(["has-text-centered", "is-6"])}>
            {sensor.site}
          </Subtitle>
        </LeafletPanelSection>

        <LeafletPanelSection>{children}</LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );

  const chartData = useChartData(selected);
  const height = "400px";

  const elementType: AxisOptionsBase["elementType"] = SelectedPrecipSensor.is(
    selected
  )
    ? "bar"
    : "line";


  const primaryAxis = useMemo(
    (): AxisOptions<number[]> => ({
      getValue: (datum) => new Date(datum[0]),
    }),
    []
  );

  const secondaryAxes = useMemo(
    (): AxisOptions<number[]>[] => [
      {
        getValue: (datum) => datum[1],
        elementType
      },
    ],
    [elementType]
  );

  if (!chartData) {
    return (
      <ChartPanel>
        <Progress
          className={bulma("m-auto")}
          style={{ width: "50%" }}
          max={100}
        ></Progress>
      </ChartPanel>
    );
  }
  const {
    source: { data, unit },
  } = chartData;
  return (
    <ChartPanel>
      <div style={{ height, width: "100%" }}>
        <ReactChart
          options={{
            data: [{ label: unit, data }],
            primaryAxis,
            secondaryAxes,
          }}
        />
      </div>
    </ChartPanel>
  );
};

export default Chart;
