import convert, { LengthUnits, SpeedUnits } from "../convert";
import { decimalPlaces, useLocaleContext } from "../contexts/LocaleContext";
import {
  faCircle as faCircleSolid,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { BaseMap } from "../models/Layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISensorDisplayPrecip } from "../models/Sensors";
import { Icon } from "trunx";
import config from "../config";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { observer } from "mobx-react-lite";
import { scaleThreshold } from "d3-scale";
import { useRootStore } from "../models/Root";

const markerColor = (map: BaseMap) => 
  map.startsWith("Imagery") ? 'white' : 'black';

export const PrecipNone = observer(() => {
  const {
    layers: { baseMap },
  } = useRootStore();

  const color = markerColor(baseMap)
  return (
    <Icon style={{ fontSize: "1.2em" }} className="fa-layers none">
      
      <FontAwesomeIcon icon={faCircleSolid} color={color} opacity={0.1} />
      <FontAwesomeIcon
        icon={faCircle}
        color={color}
        opacity={0.8}
        className="border"
      />
    </Icon>
  );
});

export const PrecipM = observer(() => {
  const {
    layers: { baseMap },
  } = useRootStore();

  return (
    <Icon style={{ fontSize: "1.2em" }} className="fa-layers missing">
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={markerColor(baseMap)}
        opacity={0.8}
      />
    </Icon>
  );
});

const PrecipDot = ({ valueForColor }: { valueForColor: number }) => {
  const { breakValues, colors } = config.markers.precip.dot;

  const color = scaleThreshold(breakValues, colors);
  return (
    <Icon style={{ fontSize: "1.2em" }} className="fa-layers">
      <FontAwesomeIcon icon={faCircleSolid} color={color(valueForColor)} />
      <FontAwesomeIcon icon={faCircle} color={"black"} className="border" />
    </Icon>
  );
};
export const PrecipTag = ({
  value,
  valueForColor,
}: {
  value: string;
  valueForColor: number;
}) => {
  const { breakValues, backgroundColors, textColors } =
    config.markers.precip.tag;
  const backgroundColor = scaleThreshold(breakValues, backgroundColors);
  const textColor = scaleThreshold(breakValues, textColors);

  return (
    <span
      className="tag sensor-marker precip-tag sensor-tag"
      style={{
        backgroundColor: backgroundColor(valueForColor),
        color: textColor(valueForColor),
      }}
    >
      {value}
    </span>
  );
};

interface PrecipMarkerProps {
  measure: ISensorDisplayPrecip["measure"];
  depth: number | undefined;
  intensity: number | undefined;
  depthUnit: LengthUnits;
  intensityUnit: SpeedUnits;
}

const PrecipMarker = ({
  measure,
  depth,
  intensity,
  depthUnit: fromDepthUnit,
  intensityUnit: fromIntensityUnit,
}: PrecipMarkerProps) => {
  const {
    units: {
      precip: { depth: toDepthUnit, intensity: toIntensityUnit },
    },
  } = useLocaleContext();

  const {
    map: { zoom },
  } = useRootStore();

  const { dotDisplayZoomLevel } = config.markers.precip;

  const value = measure === "depth" ? depth : intensity;
  if (value === undefined) {
    return <PrecipM />;
  } else if (value === 0 && dotDisplayZoomLevel) {
    return <PrecipNone />;
  }

  const fromUnit = measure === "depth" ? fromDepthUnit : fromIntensityUnit;
  const valueForColorUnit = measure === "depth" ? "in" : "in/h";
  const valueForColor = convert(value).from(fromUnit).to(valueForColorUnit);

  if (zoom && dotDisplayZoomLevel && zoom <= dotDisplayZoomLevel) {
    return <PrecipDot valueForColor={valueForColor}></PrecipDot>;
  }

  const toUnit = measure === "depth" ? toDepthUnit : toIntensityUnit;
  const value_ = convert(value)
    .from(fromUnit)
    .to(toUnit)
    .toFixed(decimalPlaces(toUnit));

  return <PrecipTag valueForColor={valueForColor} value={value_}></PrecipTag>;
};

export default observer(PrecipMarker);
