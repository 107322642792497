import React, { useEffect, useState } from "react";

import L from "leaflet";
import ReactDOM from "react-dom";

interface Props {
  children: React.ReactNode;
  size: "normal" | "narrow" | "full";
  side: "left" | "right";
}

const LeafletSidebar = ({ children, size, side }: Props): JSX.Element => {
  const root = document.querySelector(".leaflet-control-container");
  const [container] = useState(() => {
    const el = document.createElement("div");
    el.classList.add("leaflet-sidebar");
    L.DomEvent.disableClickPropagation(el);
    L.DomEvent.disableScrollPropagation(el);
    el.onclick = e => { e.stopPropagation(); };
    return el;
  });

  useEffect(() => {
    const classList = container.classList;
    classList.remove("normal");
    classList.remove("narrow");
    classList.remove("full");
    classList.add(size);
  }, [size, container]);

  useEffect(() => {
    const classList = container.classList;
    classList.remove("left");
    classList.remove("right");
    classList.add(side);
  }, [side, container]);

  useEffect(() => {
    if (root) {
      if (side === "left") {
        root.prepend(container);
      } else {
        root.append(container);
      }

      return () => {
        root.removeChild(container);
      };
    }
  }, [container, root, side]);

  return ReactDOM.createPortal(children, container);
};

export default LeafletSidebar;
