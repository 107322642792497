import { Button, Level, LevelItem } from "trunx";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToggleOn } from "@fortawesome/free-solid-svg-icons";
import { useLocaleContext } from "../contexts/LocaleContext";

export const LangSelect = () => {
  const { setLang, lang } = useLocaleContext();

  const flip = lang === "en" ? "fa-rotate-180" : "";

  return (
    <Level>
      <LevelItem>
        <Button className="is-ghost" onClick={() => setLang("en")}>
          EN
        </Button>
      </LevelItem>
      <LevelItem>
        <FontAwesomeIcon className={flip} icon={faToggleOn} />
      </LevelItem>
      <LevelItem>
        <Button className="is-ghost" onClick={() => setLang("es")}>
          ES
        </Button>
      </LevelItem>
    </Level>
  );
};
