import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISensor } from "../models/Sensors";
import { Icon } from "trunx";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";

interface BookmarkStarProps {
  sensor: ISensor;
}
const BookmarkStar = ({ sensor }: BookmarkStarProps) => {
  const {
    sensors: {
      bookmarks: { isBookmarked, toggleBookmark },
    },
  } = useRootStore();
  const prefix = isBookmarked(sensor) ? "fas" : "far";

  return (
    <Icon onClick={() => toggleBookmark(sensor)}>
      <FontAwesomeIcon icon={[prefix, "star"]} />
    </Icon>
  );
};

export default observer(BookmarkStar);
