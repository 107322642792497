import { SnapshotIn, getRoot, types } from "mobx-state-tree";

import { IRoot } from "./Root";

const { enumeration, maybe } = types;

const Panel = enumeration("panel", [
  "layers",
  "sensors",
  "radar",
  "bookmarks",
  "measure",
  "find",
]);

export type IPanel = SnapshotIn<typeof Panel>;

export const Menus = types
  .model({
    nav: false,
    panel: maybe(Panel),
    find: "",
  })
  .actions((self) => ({
    toggleNav() {
      if (self.nav) {
        self.nav = false;
      } else {
        self.nav = true;
        self.panel = undefined;
        self.find = "";

        const {
          sensors: { selected, setSensor },
        } = getRoot<IRoot>(self);
        if (selected) {
          setSensor(selected.sensor, "summary");
        }
      }
    },
    closeNav() {
      self.nav = false;
    },
    openPanel(panel: IPanel) {
      self.nav = false;
      self.panel = panel;
    },
    closePanel() {
      if (self.panel === "find") {
        self.find = "";
      }
      self.panel = undefined;
    },
    togglePanel(panel: IPanel) {
      if (self.panel === panel) {
        self.panel = undefined;
      } else {
        self.nav = false;
        self.panel = panel;
        const {
          sensors: { selected, setSensor },
        } = getRoot<IRoot>(self);
        if (selected) {
          setSensor(selected.sensor, "summary");
        }
      }
    },
    setFind(find: string) {
      self.find = find;
      if (find !== "") {
        self.panel = "find";
      } else {
        if (self.panel === "find") {
          self.panel = undefined;
        }
      }
    },
  }));
