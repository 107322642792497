import * as Wildfire from "./layers/static/Wildfire";

import {
  camerasAZGF,
  camerasCoconinoMuseum,
  camerasGilaTelegraph,
  camerasNavajo,
  camerasPima,
  camerasPinal,
  camerasSantaCruz,
  pipelineFire
} from "./layers/misc/cameras";

import { Basemaps } from "esri-leaflet";
import { DynamicMapLayerOptions } from "esri-leaflet";
import FEMA_NFHL from "./layers/dynamic/FEMA_NFHL";
import { IPanel } from "./models/Menus";
import { IQueryFeaturesOptions } from "@esri/arcgis-rest-feature-service";
import { ISensorDisplayPrecip } from "./models/Sensors";
import { Lang } from "./contexts/LocaleContext";
import { LatLng } from "leaflet";
import { ReactNode } from "react";

export interface StaticMapLayer {
  name: string;
  source: string | IQueryFeaturesOptions;
  color?: string;
  visible?: boolean;
}

export interface StaticMapLayerFolder {
  title: string;
  layers: StaticMapLayer[];
}

export type StaticMapLayerOrFolder = StaticMapLayer | StaticMapLayerFolder;
export interface DynamicMapLayer extends DynamicMapLayerOptions {
  title: string;
  defaultLayers: number[];
}

export interface CameraLayerCamera {
  name: string;
  url: string;
  location: LatLng;
}

export interface MiscCameraLayer {
  name: string;
  cameras: CameraLayerCamera[];
}

export interface MiscLayerFolder {
  title: string;
  layers: MiscCameraLayer[];
}

export type MiscLayerOrFolder = MiscCameraLayer | MiscLayerFolder;

export interface Config {
  title: string;
  center: LatLng;
  zoom: number;
  basemap: Basemaps;
  disclaimer: { body: ReactNode; outLink: string } | false;
  agencyPassList?: string[];
  defaultDisplay: ISensorDisplayPrecip;
  defaultLocale: Lang;
  defaultMenuPanel?: IPanel;
  layers: {
    static: StaticMapLayerOrFolder[];
    dynamic: DynamicMapLayer[];
    misc: MiscLayerOrFolder[];
    lightning: boolean;
    cbrfc: boolean;
  };
  precipIntervals: {
    default: Duration[];
    more: Duration[];
  };
  markers: {
    precip: {
      dotDisplayZoomLevel: number | false;
      dot: {
        breakValues: number[];
        colors: string[];
      };
      tag: {
        breakValues: number[];
        textColors: string[];
        backgroundColors: string[];
      };
    };
    stream: {
      heightBreakValues: number[];
      dischargeBreakValues: number[];
      textColors: string[];
      backgroundColors: string[];
    };
  };
  hideRecentDataLink?: boolean;
  radar: boolean;
}

const config: Config = {
  title: "JE Fuller ALERT",
  center: new LatLng(32.8, -110.2),
  zoom: 8,
  basemap: "Topographic",
  disclaimer: false,
  defaultDisplay: { type: "precip", interval: { days: 1 }, measure: "depth" },
  defaultMenuPanel: "sensors",
  defaultLocale: "en",
  layers: {
    static: [
      Wildfire.last(4),
      {
        source: process.env.PUBLIC_URL + "/kml/Arizona_Counties.kml",
        name: "County Boundary",
      },
    ],
    dynamic: [FEMA_NFHL],
    misc: [
      {
        name: "Arizona Game and Fish Department Cameras",
        cameras: camerasAZGF,
      },
      { name: "Navajo County", cameras: camerasNavajo },
      { name: "Pima County Cameras", cameras: camerasPima },
      { name: "Pinal County Cameras", cameras: camerasPinal },
      { name: "Santa Cruz County Cameras", cameras: camerasSantaCruz },
      { name: "Telegraph Fire Cameras", cameras: camerasGilaTelegraph },
      { name: "Museum Fire Cameras", cameras: camerasCoconinoMuseum },
      { name: "Pipeline Fire Cameras", cameras: pipelineFire }
    ],
    lightning: false,
    cbrfc: false
  },
  precipIntervals: {
    default: [{ hours: 1 }, { hours: 12 }, { days: 1 }],
    more: [
      { minutes: 15 },
      { minutes: 30 },
      { hours: 3 },
      { hours: 6 },
      { days: 7 },
      { days: 30 },
    ],
  },
  markers: {
    precip: {
      dotDisplayZoomLevel: 9,
      dot: {
        breakValues: [0.01, 0.25, 0.5, 1, 2],
        colors: ["#6ECFCF", "#3BB9FF", "#52D017", "yellow", "orange", "red"],
      },
      tag: {
        breakValues: [0.01, 0.25, 0.5, 1, 2],
        backgroundColors: [
          "#6ECFCF",
          "#3BB9FF",
          "#52D017",
          "yellow",
          "orange",
          "red",
        ],
        textColors: [
          "black",
          "black",
          "black",
          "black",
          "black",
          "white",
          "black",
        ],
      },
    },
    stream: {
      heightBreakValues: [1, 5, 10, 20],
      dischargeBreakValues: [10, 20, 250, 1000],
      backgroundColors: ["#3BB9FF", "#52D017", "yellow", "orange", "red"],
      textColors: ["black", "black", "black", "black", "white"],
    },
  },
  radar: true,
};

export default config;
