import * as Wildfire from "../../layers/static/Wildfire";

import base, { Config, StaticMapLayer } from "../../config";

import FEMA_NFHL from "../../layers/dynamic/FEMA_NFHL";
import { LatLng } from "leaflet";
import { camerasGilaTelegraph } from "../../layers/misc/cameras";
import notEmpty from "../../shared/notEmpty";

const staticLayers: StaticMapLayer[] = [ { 
   source: process.env.PUBLIC_URL + "/kml/TelegraphWatersheds.kml", 
   name: "Telegraph Basin Watersheds", 
   visible: false 
 }, 
  Wildfire.current(false)
].filter(notEmpty);

const config: Config = {
  ...base,
  ...{
    title: "Gila County ALERT",
    center: new LatLng(33.77, -110.97),
    zoom: 9,
    agencyPassList: [
      "Arizona Department of Water Resources",
      "Gila County",
      "United States Geological Survey",
      "Arizona Game and Fish Department",
    ],
    basemap: "ImageryLabels",
    defaultDisplay: { type: "precip", interval: { minutes: 15 }, measure: "depth" },
    layers: {
      static: staticLayers,
      dynamic: [FEMA_NFHL],
      misc: [{ name: "Telegraph Fire Cameras", cameras: camerasGilaTelegraph }],
      lightning: false,
      cbrfc: false,
    },
  },
};

export default config;
