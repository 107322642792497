import "moment/locale/es";

import React, { ComponentProps, ReactNode } from "react";
import { Icon, bulma } from "trunx";
import { ISelectedSensor, SelectedPrecipSensor } from "../models/Sensors";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelSection,
  LeafletSensorPanelHeading,
} from "../shared/LeafletPanel";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { observer } from "mobx-react-lite";
import moment from "moment";
import Moment from "react-moment";
import config from "../config";
import { useLocaleContext } from "../contexts/LocaleContext";
import useChartData from "../hooks/useChartData";
import { tabularDataUrl } from "../jefdaqApi";
import { chartUrl } from "../jefdaqApi/chart";
import { useRootStore } from "../models/Root";
import PanelInfoTable from "../shared/PanelInfoTable";
import AdditionalInformationLink from "./SensorInfo/AdditionalInformationLink";
import MiniChart from "./SensorInfo/MiniChart";
import PrecipDataTable from "./SensorInfo/PrecipDataTable";
import StreamDataTable from "./SensorInfo/StreamDataTable";

interface SensorInfoPanelProps {
  selected: ISelectedSensor;
}

const SensorInfoPanel = ({ selected }: SensorInfoPanelProps) => {
  const {
    lang,
    dict: {
      panel: {
        sensors: { info: dict },
      },
    },
  } = useLocaleContext();

  const { sensor } = selected;

  moment.relativeTimeThreshold("s", 60);
  moment.relativeTimeThreshold("ss", 1);
  moment.locale(lang);
  const {
    sensors: { setSensor },
    jefdaqApi: {
      features,
      rated: { response: ratedApiResponse },
    },
  } = useRootStore();

  const rows: [string, ReactNode | string][] = [];
  rows.push([dict.operatedBy, sensor.source]);
  sensor.lastReport &&
    rows.push([
      dict.lastReported,
      <span
        title={moment(sensor.lastReport).format(
          "dddd, MMMM Do YYYY, h:mm:ss a"
        )}
      >
        {sensor.lastReport ? <Moment fromNow>{sensor.lastReport}</Moment> : "-"}
      </span>,
    ]);

  sensor.site && rows.push([dict.ID, sensor.site]);

  const TabularDataAnchor = (props: React.HTMLProps<HTMLAnchorElement>) => (
    <a {...props}>
      {dict.recentData}
      <Icon className={"is-right"}>
        <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
      </Icon>
    </a>
  );

  const TabularDataLink = () => {
    if (config.hideRecentDataLink) {
      return <></>;
    } else if (features.api) {
      return (
        <TabularDataAnchor
          onClick={() => setSensor(sensor, "tabular")}
        ></TabularDataAnchor>
      );
    } else if (sensor.site) {
      return (
        <TabularDataAnchor
          href={tabularDataUrl(sensor.site).toString()}
          rel="noopener noreferrer"
          target="_blank"
        ></TabularDataAnchor>
      );
    } else return <></>;
  };

  const ChartAnchor = (props: ComponentProps<"a">) => (
    <a {...props}>
      {dict.chart}
      <Icon className={"is-right"}>
        <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
      </Icon>
    </a>
  );

  const chartData = useChartData(selected);

  const ChartLink = () => {
    if (chartData !== undefined) {
      return (
        <ChartAnchor onClick={() => setSensor(sensor, "chart")}></ChartAnchor>
      );
    } else {
      const url = chartUrl(ratedApiResponse, selected);
      if (url !== undefined) {
        return (
          <ChartAnchor
            href={url.toString()}
            rel="noopener noreferrer"
            target="_blank"
          ></ChartAnchor>
        );
      }
    }
    return <></>;
  };

  const url = chartUrl(ratedApiResponse, selected);

  return (
    <LeafletPanel>
      <LeafletSensorPanelHeading sensor={sensor} />
      <LeafletPanelBody>
        <LeafletPanelSection>
          <PanelInfoTable rows={rows} />
        </LeafletPanelSection>

        <LeafletPanelSection>
          {SelectedPrecipSensor.is(selected) ? (
            <PrecipDataTable selected={selected} />
          ) : (
            <StreamDataTable selected={selected} />
          )}
        </LeafletPanelSection>
        <LeafletPanelSection>
          <TabularDataLink />
        </LeafletPanelSection>
        <LeafletPanelSection>
          <MiniChart api={features.api} selected={selected} url={url} />
        </LeafletPanelSection>

        <LeafletPanelSection className={bulma("is-hidden-touch")}>
          <ChartLink />
        </LeafletPanelSection>

        <div>
          {sensor.site && (
            <AdditionalInformationLink
              sensorID={sensor.site}
            ></AdditionalInformationLink>
          )}
        </div>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(SensorInfoPanel);
