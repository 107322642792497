import { Marker, MarkerProps, Popup } from "react-leaflet";
import React, { useEffect } from "react";

import { DivIcon } from "leaflet";
import L from "leaflet";
import { createPortal } from "react-dom";
import { observer } from "mobx-react-lite";

type ReactProps = { children: JSX.Element };

// so markers are stacked in the order they are created
let zIndexCounter = 0;

// https://github.com/DefinitelyTyped/DefinitelyTyped/blob/6e724310e0ed089cf4b8b261b8badf71206ad9e8/types/leaflet/index.d.ts#L75
type ContainerProps = {
  tagName: string;
  className?: string;
  container?: HTMLElement;
};

type DivIconMarkerProps = ReactProps & { marker: MarkerProps } & {
  container: ContainerProps;
  popup?: JSX.Element;
};
const DivIconMarker = ({ children, marker, container, popup }: DivIconMarkerProps) => {
  const { tagName, className } = container;
  const element = L.DomUtil.create(tagName, className);
  const divIcon = new DivIcon({ html: element, iconSize: [20, 20]}); // iconSize from .selectable-marker.border
  const portal = createPortal(children, element);

  useEffect(() => {
    return () => {
      L.DomUtil.remove(element);
    };
  });
  const { position, eventHandlers } = marker;
  const popup_ = popup ? <Popup>{popup}</Popup> : <></>;
  return (
    <>
      <Marker
        position={position}
        icon={divIcon}
        eventHandlers={eventHandlers}
        zIndexOffset={zIndexCounter++}
      >
      {portal}
      {popup_}
      </Marker>
    </>
  );
};
export default observer(DivIconMarker);
