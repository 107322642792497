import { Sensor, SensorMeta, fetchSensorAPI, rootApiUrl } from "../jefdaqApi";

export interface PrecipApiSensor extends Sensor {
  idx: number;
  site: string;
  lastReport: string | null;
  latitude: number;
  longitude: number;
  name: string;
  source: string;
  null: number;
  precipitationDepthsInches: number[];
  unitShort: string;
}

export interface PrecipApiMeta extends SensorMeta {
  precipitationIntervalSeconds: number[];
  timezone: string;
}

export interface PrecipApiResponse {
  meta: PrecipApiMeta;
  data: PrecipApiSensor[];
}

const url = rootApiUrl + "/json/rainfall/"
const check = /inch(es)?/i;

export const fetchPrecipAPI = () => fetchSensorAPI(url,check);
