import {
  CameraLayer as CameraLayerModel,
  DynamicLayer as DynamicLayerModel,
  ILayerFolder,
  IStaticLayer,
  isCameraLayer,
  isDynamicLayer,
  isFolder,
  isStaticLayer,
  isStaticLayerFolder,
} from "../models/Layers";
import { IAnyType, Instance } from "mobx-state-tree";
import { Icon, IconText, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

import DynamicLayerPanel from "./DynamicLayerPanel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StaticLayerFolderPanel from "./StaticLayerFolderPanel";
import config from "../config";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../contexts/LocaleContext";
import { useRootStore } from "../models/Root";

const LayersPanel = () => {
  const {
    dict: {
      panel: { layers: dict },
    },
  } = useLocaleContext();

  const {
    menus: { closePanel },
    layers: layerStore,
  } = useRootStore();

  const { setOpenLayerPanel, openLayerPanel, clearOpenLayerPanel } = layerStore;

  if (openLayerPanel !== undefined) {
    if (isStaticLayerFolder(openLayerPanel)) {
      return (
        <StaticLayerFolderPanel
          model={openLayerPanel}
          back={clearOpenLayerPanel}
        ></StaticLayerFolderPanel>
      );
    } else if (isDynamicLayer(openLayerPanel)) {
      return (
        <DynamicLayerPanel
          model={openLayerPanel}
          back={clearOpenLayerPanel}
        ></DynamicLayerPanel>
      );
    } else {
      return <></>;
    }
  } else {
    interface StaticLayerProps {
      model: IStaticLayer;
    }

    const StaticLayer = observer(
      ({ model: { name, visible, toggle } }: StaticLayerProps) => (
        <li key={name} onClick={toggle}>
          <IconText>
            <Icon className={bulma("is-left")}>
              <FontAwesomeIcon icon={visible ? faCheckSquare : faSquare} />
            </Icon>
            <span>{name}</span>
          </IconText>
        </li>
      )
    );
    type LayerFolderProps<IT extends IAnyType> = {
      model: Instance<ILayerFolder<IT>>;
    };

    const LayerFolder = observer(
      <IT extends IAnyType>({ model }: LayerFolderProps<IT>) => {
        const { title, toggle, anyVisible } = model;
        return (
          <li key={title}>
            <IconText>
              <Icon className={bulma("is-left")} onClick={toggle}>
                <FontAwesomeIcon icon={anyVisible ? faCheckSquare : faSquare} />
              </Icon>
              <span
                className="is-link"
                onClick={() => setOpenLayerPanel(model)}
              >
                {title}
              </span>
              <Icon
                className={"is-right"}
                onClick={() => setOpenLayerPanel(model)}
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </Icon>
            </IconText>
          </li>
        );
      }
    );

    interface DynamicLayerProps {
      model: Instance<typeof DynamicLayerModel>;
    }

    const DynamicLayer = observer(({ model }: DynamicLayerProps) => {
      const { title, toggleDefaultLayers, anyVisible } = model;
      return (
        <li key={title}>
          <IconText>
            <Icon className={bulma("is-left")} onClick={toggleDefaultLayers}>
              <FontAwesomeIcon icon={anyVisible ? faCheckSquare : faSquare} />
            </Icon>
            <span className="is-link" onClick={() => setOpenLayerPanel(model)}>
              {title}
            </span>
            <Icon
              className={"is-right"}
              onClick={() => setOpenLayerPanel(model)}
            >
              <FontAwesomeIcon icon={faCaretRight} />
            </Icon>
          </IconText>
        </li>
      );
    });

    interface CameraLayerProps {
      model: Instance<typeof CameraLayerModel>;
    }
    const CameraLayer = observer(
      ({ model: { name, visible, cameras, toggle } }: CameraLayerProps) =>
        cameras.length > 0 ? (
          <li key={name} onClick={toggle}>
            <Icon className={bulma("is-left")}>
              <FontAwesomeIcon icon={visible ? faCheckSquare : faSquare} />
            </Icon>
            {name}
          </li>
        ) : (
          <></>
        )
    );

    const { cbrfc, lightning} = config.layers;

    return (
      <LeafletPanel
        heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
      >
        <LeafletPanelBody>
          <LeafletPanelSection>
            <aside className={bulma("menu")}>
              <ul className={bulma("menu-list")}>
                {layerStore.static.length > 0 && (
                  <p className={bulma("menu-label")}>{dict.static}</p>
                )}
                {layerStore.static.map((model) => {
                  if (isStaticLayer(model)) {
                    return (
                      <StaticLayer key={model.name} model={model}></StaticLayer>
                    );
                  } else if (isFolder(model)) {
                    return (
                      <LayerFolder
                        key={model.title}
                        model={model}
                      ></LayerFolder>
                    );
                  } else {
                    return <></>;
                  }
                })}
                {layerStore.dynamic.length > 0 && (
                  <p className={bulma("menu-label")}>{dict.dynamic}</p>
                )}
                {layerStore.dynamic.map((model) => (
                  <DynamicLayer key={model.title} model={model}></DynamicLayer>
                ))}
                {(layerStore.misc.length > 0 || lightning) && (
                  <p className={bulma("menu-label")}>{dict.misc}</p>
                )}

                {layerStore.misc.map((model) => {
                  if (isCameraLayer(model)) {
                    return (
                      <CameraLayer key={model.name} model={model}></CameraLayer>
                    );
                  } else if (isFolder(model)) {
                    return (
                      <LayerFolder
                        key={model.title}
                        model={model}
                      ></LayerFolder>
                    );
                  } else {
                    return <></>;
                  }
                })}
                {lightning && (
                  <li key="lightning" onClick={layerStore.toggleLightning}>
                    <IconText>
                      <Icon className={bulma("is-left")}>
                        <FontAwesomeIcon
                          icon={layerStore.lightning ? faCheckSquare : faSquare}
                        />
                      </Icon>
                      <span>{dict.lightning}</span>
                    </IconText>
                  </li>
                )}{cbrfc && (
                  <li key="cbrfc" onClick={layerStore.toggleCbrfc}>
                    <IconText>
                      <Icon className={bulma("is-left")}>
                        <FontAwesomeIcon
                          icon={layerStore.cbrfc ? faCheckSquare : faSquare}
                        />
                      </Icon>
                      <span>Colorado Basin River Forecast</span>
                    </IconText>
                  </li>
                )}
              </ul>
            </aside>
          </LeafletPanelSection>
        </LeafletPanelBody>
      </LeafletPanel>
    );
  }
};

export default observer(LayersPanel);
