import { ConaguaResponse, ConaguaStation } from "./useConagua";

import { PrecipSensor_ } from "./models/Sensors";
import { precipIntervals } from "./shared/precipIntervals";

export const conaguaToPrecipSensor = (
  response: ConaguaResponse
): PrecipSensor_[] => {
  const stations: ConaguaStation[] = Object.values(response);

  const sensors: PrecipSensor_[] = stations.map((station: ConaguaStation) => {
    const { name, lat, lng, timezone, precip } = station;
    const depths = new Array(precipIntervals.length);
    depths.fill(undefined)
    if (precip) {
      Object.entries(precip).forEach(([duration, depth]) => {
        const idx = precipIntervals.indexOf(duration);
        if (idx > -1) {
          depths[idx] = depth;
        }
      });
    }

    const sensor: PrecipSensor_ = {
      type: "precip",
      id: "conagua_" + name,
      timezone,
      latitude: lat,
      longitude: lng,
      name,
      source: "Conagua",
      depths,
      depthUnit: "mm",
      intensityUnit: "mm/h",
    };
    return sensor;
  });
  return sensors;
};
