import { Button, Field, Icon, Progress, Subtitle, Table, bulma } from "trunx";
import { ISensor, isPrecipSensor } from "../models/Sensors";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelSection,
  LeafletSensorPanelHeading,
} from "../shared/LeafletPanel";
import { ReactNode, useState } from "react";
import convert, { isVolumeFlowRateUnit } from "../convert";
import {
  decimalPlaces,
  formatUnit,
  useLocaleContext,
} from "../contexts/LocaleContext";
import useTabularData, {
  TabularData,
  forCsvDownloader,
} from "../hooks/useTabularData";

import CsvDownloader from "react-csv-downloader";
import DateRangePicker from "../DateRangePicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment-timezone";
import { subDays } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

interface TabularDataPaneProps {
  sensor: ISensor;
}
const TabularDataPane = ({ sensor }: TabularDataPaneProps) => {
  const {
    lang,
    units,
    dict: {
      pane: { tabular: dict },
    },
  } = useLocaleContext();

  const today = new Date();
  const [startDate, setStartDate] = useState(subDays(today, 7));
  const [endDate, setEndDate] = useState(today);

  const subtitle = `${dict.sensor} ${sensor.site}`;
  const pickerProps = { startDate, setStartDate, endDate, setEndDate };

  const data = useTabularData(sensor, startDate, endDate);

  const isPrecip = isPrecipSensor(sensor);
  const valueHead = isPrecip ? dict.precipitation : dict.stage;
  const toUnit = isPrecip ? units.precip.depth : units.stream.height;
  const toUnitRated = units.stream.discharge;

  const gotData = !(data === undefined || data === "loading");

  const CsvButtonWrapper = ({ children }: { children: ReactNode }) =>
    gotData ? (
      <CsvDownloader
        filename={`${sensor.name.trim()}.csv`}
        datas={forCsvDownloader(data, sensor.timezone, toUnit, toUnitRated)}
        columns={[
          { id: "date", displayName: dict.date },
          { id: "time", displayName: dict.time + ` (${sensor.timezone})` },
          { id: "value", displayName: valueHead + ` (${formatUnit(toUnit)})` },
        ].concat(
          data.type === "TwoColumns"
            ? [
                {
                  id: "rated",
                  displayName: dict.discharge + ` (${formatUnit(toUnitRated)})`,
                },
              ]
            : []
        )}
      >
        {children}
      </CsvDownloader>
    ) : (
      <>{children}</>
    );

  const TabularPanel = ({ children }: { children: ReactNode }) => (
    <LeafletPanel>
      <LeafletSensorPanelHeading sensor={sensor} action="back" />

      <LeafletPanelBody isFullHeight>
        <LeafletPanelSection>
          <Subtitle className={bulma(["has-text-centered", "is-6"])}>
            {subtitle}
          </Subtitle>
        </LeafletPanelSection>
        <LeafletPanelSection>
          <DateRangePicker {...pickerProps} />
          <CsvButtonWrapper>
            <Field isGrouped className="is-grouped-centered">
              <Button className={bulma("is-link")} disabled={!gotData}>
                {dict.csv}
              </Button>
            </Field>
          </CsvButtonWrapper>
        </LeafletPanelSection>
        {children}
      </LeafletPanelBody>
    </LeafletPanel>
  );

  if (!gotData) {
    return (
      <TabularPanel>
        <LeafletPanelSection>
          <Progress className={bulma("m-auto")} style={{ width: "50%" }} max={100}></Progress>
        </LeafletPanelSection>
      </TabularPanel>
    );
  } else {
    const sensorZone = moment.tz.zone(sensor.timezone);
    const browserZone = moment.tz.zone(moment.tz.guess());
    const sameZone = sensorZone?.offsets === browserZone?.offsets;

    const zoneWarn = (
      <LeafletPanelSection className={bulma('has-text-centered')}>
        <span className={bulma("has-text-warning")}>
          <Icon>
            <FontAwesomeIcon icon={faExclamationTriangle} />
          </Icon>
          <span>{dict.zoneWarning(sensor.timezone)}</span>
        </span>
      </LeafletPanelSection>
    );

    const TableHead = ({ data }: { data: TabularData }) => {
      const hasDischarge = data.type === "TwoColumns";
      return (
        <thead>
          <tr>
            <th>{dict.date}</th>
            <th>{dict.time}</th>
            <th>{valueHead}</th>
            {hasDischarge && <th>{dict.discharge}</th>}
          </tr>
        </thead>
      );
    };

    const TableBody = ({ data }: { data: TabularData }) => {
      if (data.type === "OneColumn") {
        const fromUnit = data.unit;

        const rows = Array.from(data.rows.entries())
          .sort(([left], [right]) => (left < right ? 1 : -1))
          .map(([date, value]) => ({
            date: utcToZonedTime(date, sensor.timezone),
            value,
          }));
        return (
          <tbody>
            {rows.map(({ date, value }) => (
              <tr key={date.toISOString()}>
                <td>{date.toLocaleDateString(lang)}</td>
                <td>{date.toLocaleTimeString(lang)}</td>
                <td>
                  {convert(value)
                    .from(fromUnit)
                    .to(toUnit)
                    .toFixed(decimalPlaces(toUnit))}{" "}
                  {formatUnit(toUnit)}
                </td>
              </tr>
            ))}
          </tbody>
        );
      } else {
        const rows = Array.from(data.rows.entries())
          .sort(([left], [right]) => (left < right ? 1 : -1))
          .map(([date, values]) => ({
            date: utcToZonedTime(date, sensor.timezone),
            value: values[0],
            rated: values[1],
          }));
        const fromUnit = data.units[0];

        const fromUnitRated = data.units[1];
        if (!isVolumeFlowRateUnit(fromUnitRated)) {
          throw new Error(`Can only handle discharge but got ${fromUnitRated}`);
        }

        return (
          <tbody>
            {rows.map(({ date, value, rated }) => (
              <tr key={date.toISOString()}>
                <td>{date.toLocaleDateString(lang)}</td>
                <td>{date.toLocaleTimeString(lang)}</td>
                <td>
                  {convert(value)
                    .from(fromUnit)
                    .to(toUnit)
                    .toFixed(decimalPlaces(toUnit))}{" "}
                  {formatUnit(toUnit)}
                </td>
                <td>
                  {convert(rated)
                    .from(fromUnitRated)
                    .to(toUnitRated)
                    .toFixed(decimalPlaces(toUnitRated))}{" "}
                  {formatUnit(toUnitRated)}
                </td>
              </tr>
            ))}
          </tbody>
        );
      }
    };

    return (
      <TabularPanel>
        {sameZone || zoneWarn}
        <LeafletPanelSection>
          <Table className="mx-auto is-scrollable">
            <TableHead data={data}></TableHead>
            <TableBody data={data}></TableBody>
          </Table>
        </LeafletPanelSection>
      </TabularPanel>
    );
  }
};

export default TabularDataPane;
