import { Feature, GeometryObject } from "geojson";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import PanelInfoTable, { PanelInfoTableProps } from "../shared/PanelInfoTable";

import { Panel } from "trunx";
import geojson from "geojson";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../contexts/LocaleContext";
import { useRootStore } from "../models/Root";

const hasDescription = (
  feature: any
): feature is geojson.Feature<geojson.MultiPoint, { description: string }> => {
  return feature?.properties?.description !== undefined;
};

export interface LayerInfoPanelProps {
  feature: Feature<GeometryObject>;
}

const LayerInfoPanel = ({ feature }: LayerInfoPanelProps) => {
  const {
    lang,
    dict: {
      panel: { layers: dict },
    },
  } = useLocaleContext();

  const {
    layers: { clearSelectedFeature },
  } = useRootStore();

  if (hasDescription(feature)) {
    const { properties: description } = feature;
    return (
      <Panel>
        <p className="panel-heading">
          {dict.title}
          <span style={{ float: "right" }} onClick={clearSelectedFeature}>
            &times;
          </span>
        </p>
        <iframe
          style={{ backgroundColor: "white" }}
          title="description"
          srcDoc={description.description}
        ></iframe>
      </Panel>
    );
  } else {
    // No explicit description, just show all properties in a table
    const properties = (feature as any)?.properties || {
      incidentname: "Unknown",
    };
    const incidentName =
      properties.irwin_IncidentName ||
      properties.IncidentName ||
      properties.incidentname ||
      properties.poly_IncidentName ||
      properties.name;

    const castDates = (row: [string, any]): [string, string] => {
      const [k, v] = row;
      const maybeDate = new Date(v);
      const isDate = !isNaN(maybeDate as any) && maybeDate.getFullYear() > 2000;
      const v_ = isDate ? maybeDate.toLocaleString(lang) : v && v.toString();
      return [k, v_];
    };

    const rows: PanelInfoTableProps["rows"] =
      Object.entries(properties).map(castDates);

    return (
      <LeafletPanel
        heading={
          <LeafletPanelHeading
            title={incidentName}
            close={clearSelectedFeature}
          />
        }
      >
        <LeafletPanelBody>
          <LeafletPanelSection>
            <PanelInfoTable rows={rows} />
          </LeafletPanelSection>
        </LeafletPanelBody>
      </LeafletPanel>
    );
  }
};

export default observer(LayerInfoPanel);
