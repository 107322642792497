import { useEffect, useState } from "react";

export interface ConaguaStation {
  foo: string;
  lat: number;
  lng: number;
  name: string;
  precip: { [duration: string]: number } | undefined;
  timezone: string;
  created: string;
}

export type ConaguaResponse = Map<string, ConaguaStation>;

const fetchConagua = async (path: string): Promise<ConaguaResponse> => {
  try {
    const fetchResponse = await fetch(path);
    if (!fetchResponse.ok) {
      throw new Error(`${fetchResponse.status} ${fetchResponse.statusText}`);
    }
    const json: any = await fetchResponse.json();
    return json;
  } catch (e) {
    //console.log(e);
    throw e;
  }
};

export const useConagua = () => {
  const path = "/data/conagua/stations.json";

  const [response, setResponse] = useState<ConaguaResponse | undefined>(
    undefined
  );
  useEffect(() => {
    const fetchAndSet = fetchConagua(path).then(setResponse);
    fetchAndSet
      .then(() =>
        setInterval(() => {
          fetchAndSet.catch((e) => {
            //console.error(`API not available: ${path}`);
          });
        }, 30000)
      )
      .catch((e) => {
        //console.error(`API not available: ${path}`);
      });
  }, [path]);

  return response;
};
