import { SensorDisplayPrecip, SensorDisplayStream } from "./models/Sensors";
import { useContext, useEffect, useState } from "react";
import { useMap, useMapEvents } from "react-leaflet";

import { BaseMap } from "./models/Layers";
import { Basemaps } from "esri-leaflet";
import CameraLayer from "./layers/CameraLayer";
import CbrfcLayer from "./layers/CbrfcLayer";
import LightningLayer from "./layers/LightningLayer";
import { Point } from "leaflet";
import PrecipSensorsLayer from "./layers/PrecipSensorsLayer";
import RadarContext from "./contexts/RadarContext";
import RadarLayer from "./layers/RadarLayer";
import StaticLayer from "./layers/StaticLayer";
import StreamSensorsLayer from "./layers/StreamSensorsLayer";
import { VectorBasemapLayer } from "esri-leaflet-vector";
import { autorun } from "mobx";
import { basemapLayer } from "esri-leaflet";
import { observer } from "mobx-react-lite";
import { onPatch } from "mobx-state-tree";
import { useRootStore } from "./models/Root";

const baseMapsToShow = (layer: BaseMap): Basemaps[] => {
  switch (layer) {
    case "ImageryLabels":
      return [layer, "Imagery"];
    case "TerrainLabels":
      return [layer, "Terrain"];
    default:
      return [layer];
  }
};

const Map = () => {
  const radarContext = useContext(RadarContext);
  const radarReady = radarContext?.apiResponse;
  const radarVisible = radarContext?.visible;
  const opacity = radarContext?.opacity || 1;

  const {
    layers: layerStore,
    sensors: { loading, display, selected: selectedSensor },
    map: { setZoom },
    menus,
    isMobile,
  } = useRootStore();

  const mapEvents = useMapEvents({
    zoomend: () => setZoom(mapEvents.getZoom()),
  });

  const map = useMap();
  const { style, classList } = map.getContainer();
  style.cursor = "crosshair";
  classList[loading ? "add" : "remove"]("sensors-loading");

  const [currentLayers, setLayers] = useState<typeof VectorBasemapLayer[]>([]);

  useEffect(
    () =>
      autorun(() => {
        if (map) {
          currentLayers.forEach((l) => map.removeLayer(l));
          const baseMap = layerStore.baseMap;
          const layers = baseMapsToShow(baseMap).map((m) => basemapLayer(m));
          layers.map((l) => l.addTo(map));
          setLayers(layers);
        }
      }),
    [] // eslint-disable-line react-hooks/exhaustive-deps
  );

  autorun(() => {
    if (map && selectedSensor !== undefined) {
      const {
        sensor: { latitude, longitude },
      } = selectedSensor;
      const center = map.project({ lat: latitude, lng: longitude });
      const center_ = isMobile ? new Point(center.x, center.y + 200) : center;
      const target = map.unproject(center_);

      const bounds = map.getBounds().pad(-0.2);
      const inBounds = bounds.contains(target);
      if (!inBounds) {
        map.flyTo(target);
      }
    }
  });

  onPatch(layerStore, ({ path, value }) => {
    if (path === "/lightning" && value !== undefined) {
      // We have just turned on lightning layer
      if (isMobile) {
        menus.closePanel();
      }
    }
  });

  return (
    <>
      {SensorDisplayPrecip.is(display) && (
        <PrecipSensorsLayer display={display}></PrecipSensorsLayer>
      )}
      {SensorDisplayStream.is(display) && (
        <StreamSensorsLayer display={display}></StreamSensorsLayer>
      )}
      {radarReady && radarVisible && (
        <RadarLayer opacity={opacity}></RadarLayer>
      )}
      {layerStore.flattenStatic.map((model, idx) => (
        <StaticLayer model={model} key={idx}></StaticLayer>
      ))}
      {layerStore.flattenMisc.map((model, idx) => (
        <CameraLayer key={idx} model={model}></CameraLayer>
      ))}
      {layerStore.lightning && (
        <LightningLayer lightning={layerStore.lightning} />
      )}
      {layerStore.cbrfc && <CbrfcLayer cbrfc={layerStore.cbrfc} />}
    </>
  );
};

export default observer(Map);
