import "react-datepicker/dist/react-datepicker.css";

import { Control, Field, bulma } from "trunx";
import React, {
  ComponentProps,
  Dispatch,
  SetStateAction,
  forwardRef,
} from "react";

import DatePicker from "react-datepicker";
import { useLocaleContext } from "./contexts/LocaleContext";

const ReactDatePickerInput = forwardRef<
  HTMLInputElement,
  ComponentProps<"input">
>((props, ref) => {
  const style = { maxWidth: "max-content" };
  return (
    <input className={bulma("is-small")} ref={ref} {...props} style={style} />
  );
});

interface DateRangePickerProps {
  startDate: Date;
  endDate: Date;
  setStartDate: Dispatch<SetStateAction<Date>>;
  setEndDate: Dispatch<SetStateAction<Date>>;
}

const DateRangePicker = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}: DateRangePickerProps) => {
  const { dateLocale } = useLocaleContext();

  return (
    <Field isGrouped className="is-grouped-centered">
      <Control>
        <DatePicker
          selected={startDate}
          onChange={(date) => date && setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          customInput={<ReactDatePickerInput />}
          popperPlacement="bottom"
          locale={dateLocale}
          dateFormat="P"
        />
      </Control>
      <Control>
        <DatePicker
          selected={endDate}
          onChange={(date) => date && setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          customInput={<ReactDatePickerInput />}
          popperPlacement="bottom"
          locale={dateLocale}
          dateFormat="P"
        />
      </Control>
    </Field>
  );
};

export default DateRangePicker;
