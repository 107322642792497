import { IStreamValues_, StreamSensor_ } from "../models/Sensors";
import { StageApiResponse, StageApiSensor } from "../jefdaqApi/stage";

import { isLengthUnit } from "../convert";
import notEmpty from "../shared/notEmpty";
import { streamPeriodSeconds } from "../shared/streamPeriods";

export const stageApiToSensors = (
  stageApiResponse: StageApiResponse
): StreamSensor_[] => {
  const apiIntervals = stageApiResponse.meta.periodsSeconds;
  if (JSON.stringify(apiIntervals) !== JSON.stringify(streamPeriodSeconds)) {
    throw new Error("Stage periods do not match");
  }

  const apiSensorToStreamSensor = (
    apiSensor: StageApiSensor
  ): StreamSensor_ | undefined => {
    const type = "stream";
    const id = "jefdaq_" + apiSensor.idx.toString();
    const lastReport = apiSensor.lastReport || undefined;

    const heightUnit = apiSensor.unitShort.toLowerCase().trim();
    if (!isLengthUnit(heightUnit)) {
      console.error("No compatible unit for stage sensor " + id);
      return undefined;
    }
    const heightToStageValues = (s: number | null): IStreamValues_ => {
      const actuallyNull = s === null;
      const isNullValue = s === apiSensor.null;
      const value = actuallyNull || isNullValue ? undefined : (s as number);
      return { height: value, discharge: undefined };
    };

    const latest = heightToStageValues(apiSensor.stage);
    const stages = apiSensor.stages.map((v) => heightToStageValues(v));

    const sensor: StreamSensor_ = {
      ...apiSensor,
      ...stageApiResponse.meta,
      ...{ type, id, lastReport, latest, values: stages, heightUnit },
    };

    return sensor;
  };

  return stageApiResponse.data.map(apiSensorToStreamSensor).filter(notEmpty);
};
