import { IPrecipSensor, ISensorDisplayPrecip } from "../models/Sensors";

import DivIconMarker from "./DivIconMarker";
import { LeafletMouseEvent } from "leaflet";
import PrecipMarker from "./PrecipMarker";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";

interface PrecipSensorViewProps {
  sensor: IPrecipSensor;
  display: ISensorDisplayPrecip;
}
const PrecipSensorView = observer(
  ({ sensor, display }: PrecipSensorViewProps) => {
    const { interval, measure } = display;

    const {
      menus: { panel },
      sensors: { setSensor },
    } = useRootStore();

    const { position, intensityUnit, depthUnit } = sensor;
    const depth = sensor.depthFor(interval);
    const intensity = sensor.intensityFor(interval);

    const container = {
      tagName: "div",
      className: classNames("selectable-marker", {
        "is-selected": sensor.selected,
      }),
    };

    const eventHandlers =
      panel === "measure"
        ? {}
        : {
            click: (e: LeafletMouseEvent) => {
              setSensor(sensor);
            },
          };
    const marker = { position, eventHandlers };

    return (
      <DivIconMarker key={sensor.id} marker={marker} container={container}>
        <PrecipMarker
          measure={measure}
          depth={depth}
          intensity={intensity}
          depthUnit={depthUnit}
          intensityUnit={intensityUnit}
        ></PrecipMarker>
      </DivIconMarker>
    );
  }
);

const PrecipSensorsLayer = ({ display }: { display: ISensorDisplayPrecip }) => {
  const {
    sensors: { precipSensors },
  } = useRootStore();

  const interval = display.interval;
  const sorter = (a: IPrecipSensor, b: IPrecipSensor) =>
    (a.depthFor(interval) || 0) - (b.depthFor(interval) || 0);

  return (
    <>
      {precipSensors.sort(sorter).map((sensor) => (
        <PrecipSensorView
          key={sensor.id}
          sensor={sensor}
          display={display}
        ></PrecipSensorView>
      ))}
    </>
  );
};

export default observer(PrecipSensorsLayer);
