import { ICameraLayer, ICameraLayerCamera } from "../models/Layers";

import DivIconMarker from "./DivIconMarker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Icon } from "trunx";
import { LeafletMouseEvent } from "leaflet";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";

const CameraMarker = observer(({ camera }: { camera: ICameraLayerCamera }) => {
  const {
    layers: { baseMap, setCamera, selectedCamera },
  } = useRootStore();

  const position = camera.location;
  const eventHandlers = {
    click: (e: LeafletMouseEvent) => {
      setCamera(camera);
    },
  };

  const marker = { position, eventHandlers };
  const color = baseMap.startsWith("Imagery") ? "white" : "black";
  const selected = selectedCamera?.url === camera.url ? 'visible' : 'hidden';
  return (
    <DivIconMarker
      key={camera.url}
      marker={marker}
      container={{ tagName: "div" }}
    >
      <Icon className="fa-layers">
        <FontAwesomeIcon icon={faCamera} color={color} size="lg" />
        <FontAwesomeIcon icon={faCircle} color={'red'} size="2x" transform="down-0.5 left-0.5" visibility={selected}/>
      </Icon>
    </DivIconMarker>
  );
});

export interface CameraLayerProps {
  model: ICameraLayer;
}

const CameraLayer = ({ model: { visible, cameras } }: CameraLayerProps) => {
  if (!visible) {
    return <></>;
  }

  return (
    <>
      {cameras.map((camera) => (
        <CameraMarker key={camera.url} camera={camera} />
      ))}
    </>
  );
};

export default observer(CameraLayer);
