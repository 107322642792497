import { format, parse } from "date-fns";
import React, {
  ReactNode,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from "react";

interface RadarApiResponse {
  nullTile: string;
  zooms: { string: number[][] };
  baseURL: string;
  times: Date[];
}
interface RadarContextState {
  apiResponse?: RadarApiResponse;
  frame: number;
  playing: boolean;
  setPlaying: (active: boolean) => void;
  visible: boolean;
  setVisible: (active: boolean) => void;
  speed: number;
  setSpeed: (speed: number) => void;
  opacity: number;
  setOpacity: (opacity: number) => void;
}

const RadarContext = createContext<RadarContextState | undefined>(undefined);

export const RadarContextProvider = ({ children }: { children: ReactNode }) => {
  const [frame, setFrame] = useState<number>(0);
  const [speed, setSpeed] = useState<number>(500);

  const [playing, setPlaying] = useState<boolean>(false);
  const [visible, setVisible] = useState<boolean>(false);

  const [opacity, setOpacity] = useState<number>(0.5);

  const [apiResponse, setApiResponse] = useState<RadarApiResponse>();

  const [apiUrl, setApiUrl] = useState<string>(
    process.env.REACT_APP_API_URL || "https://jefdaq.jefulleralert.com"
  );

  useEffect(() => {
    const update = () =>
      fetchRadarAPI(apiUrl)
        .then(setApiResponse)
        .catch((e: any) => {
          if (!apiUrl.includes("jefulleralert")) {
            setApiUrl("https://jefdaq.jefulleralert.com");
          } else {
            console.error(e);
          }
        });
    update();
    setInterval(update, 10 * 60 * 1000);
  }, [apiUrl]);

  useLoopFrames(playing ? speed : 0, frame, !visible, setFrame);

  const state: RadarContextState = {
    apiResponse,
    frame,
    playing,
    setPlaying,
    visible,
    setVisible,
    speed,
    setSpeed,
    opacity,
    setOpacity,
  };
  return (
    <RadarContext.Provider value={state}>{children}</RadarContext.Provider>
  );
};

const apiTimeFormat = "yyyyMMdd'_'HHmmss";
const fromApiTimeFormat = (time: string) =>
  parse(time, apiTimeFormat, new Date());
export const toApiTimeFormat = (time: Date) => format(time, apiTimeFormat);

const fetchRadarAPI = (apiUrl: string): Promise<RadarApiResponse> => {
  return fetch(apiUrl + "/json/radar/")
    .then((res) => res.json())
    .then((result) => {
      const d0q = result["D0Q"];
      const times = d0q["times"].map(fromApiTimeFormat);
      return { ...d0q, ...{ times } };
    });
};

const useLoopFrames = (
  speed: number,
  frame: number,
  reset: boolean,
  setFrame: (number: SetStateAction<number>) => void
) => {
  useEffect(() => {
    const updateFrame = () =>
      setFrame((frame) => (frame === 0 ? 9 : frame - 1));

    let interval: NodeJS.Timeout | null = null;
    if (reset) {
      setFrame(0);
    } else if (speed > 0) {
      const delta = (1000 - speed) * (frame === 0 ? 4 : 1);
      interval = setInterval(updateFrame, delta);
    } else {
      interval && clearInterval(interval);
    }
    return () => {
      interval && clearInterval(interval);
    };
  }, [frame, reset, setFrame, speed]);
};

export default RadarContext;
