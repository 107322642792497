import { Dictionary } from "./dictionary";

const en: Dictionary = {
  nav: {
    search: "Search",
    poweredBy: "Powered by",
    staleWarn: "Some data are stale by",
    findme: "Find me",
    more: "more",
  },
  panel: {
    display: "Display",
    tools: "Tools",
    layers: {
      title: "Layers",
      baseMap: "Base map",
      baseMapOptions: {
        Streets: "Streets",
        Topographic: "Topographic",
        NationalGeographic: "National Geographic",
        Oceans: "Oceans",
        Gray: "Gray",
        DarkGray: "Dark Gray",
        Imagery: "Satellite",
        ShadedRelief: "Shaded Relief",
        Terrain: "Terrain",
        USATopo: "USA Topographic",
        OceansLabels: "Oceans with labels",
        GrayLabels: "Gray with labels",
        DarkGrayLabels: "Dark gray with labels",
        ImageryLabels: "Satellite with labels",
        ImageryClarity: "Satellite clarity",
        ImageryTransportation: "Satellite with transportation",
        ShadedReliefLabels: "Shaded relief with labels",
        TerrainLabels: "Terrain with labels",
      },
      static: "Static",
      dynamic: "Dynamic",
      misc: "Misc",
      camera: "Cameras",
      back: "Back to layers",
      lightning: "Lightning",
    },
    sensors: {
      title: "Sensors",
      data: {
        title: "Data",
        precip: "precip",
        stream: "stage/discharge",
        other: "other",
      },
      legend: {
        missing: "Missed last report",
        title: "Legend",
        hour: "/hr",
        precipMeasure: {
          depth: "Depth",
          intensity: "Intensity",
        },
        streamMeasure: {
          height: "Height",
          discharge: "Discharge",
        },
      },
      info: {
        back: "Back to filters",
        operatedBy: "Operated by:",
        lastReported: "Last report:",
        statsLastComputed: "Stats last computed:",
        ID: "Station:Sensor ID:",
        precip: "Precipitation",
        intensity: "Intensity",
        stage: "Stage",
        discharge: "Discharge",
        recentData: "Recent tabular data",
        chart: "Full size chart",
        additionalInformation: "Additional information",
      },
    },
    radar: { title: "Radar", opacity: "Opacity", speed: "Speed" },
    find: {
      title: "Find",
      name: "Name",
      id: "ID",
      precip: "precip",
      stream: "stage",
    },
    bookmarks: {
      title: "Bookmarks",
      name: "Name",
      precip: "precip",
      stream: "stage/disch",
    },
    measure: {
      title: "Measure",
    },
    lightning: {
      title: "Lightning",
      legend: "Legend",
      within: (mins) => `Within last ${mins} mins`,
      clickPrompt: "Click a strike icon to view information",
      selected: "Selected",
      detected: "Detected",
      current: "Current",
      positive: "positive",
      negative: "negative",
      type: "Type",
      location: "Location",
    },
    cbrfc: {
      open: "Open on",
      details: "View details",
    }

  },
  pane: {
    tabular: {
      title: "Tabular Data",
      station: "Station",
      sensor: "Sensor",
      precipitation: "Precipitation",
      stage: "Stage",
      discharge: "Discharge",
      date: "Date",
      time: "Time",
      csv: "Download CSV",
      zoneWarning: (zone: string) => `Sensor in in ${zone} time zone.`,
    },
    chart: {
      monthNames: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      noData: "No data available",
    },
  },
  intervals: {
    durationLabel: {
      // from https://github.com/JEFuller/JEFMAP/blob/2633e4943ab82a90106e5996d0c2cdd4b4a33f01/viewer/js/gis/dijit/JefdaqStation.js#L726
      PT15M: "15m",
      PT30M: "30m",
      PT1H: "1h",
      PT3H: "3h",
      PT6H: "6h",
      PT12H: "12h",
      P1D: "1d",
      P7D: "7d",
      P14D: "14d",
      P30D: "30d",
    },

    lastLabel: "latest",
    max: "max",
  },
};

export default en;
