import { Column, Columns, Dropdown, DropdownMenu, Icon, bulma, } from "trunx";
import { SensorDisplayPrecip, SensorDisplayStream } from "../../models/Sensors";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";
import { formatISODuration, isEqual } from "../../shared/duration";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../config";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../../contexts/LocaleContext";
import { useRootStore } from "../../models/Root";

const SelectSensorFilter = () => {
  const {
    dict: {
      intervals: { durationLabel, lastLabel, max },
      panel: {
        sensors: { data: dict },
      },
    },
  } = useLocaleContext();

  const {
    sensors: {
      display,
      clearDisplay,
      displayPrecipWithInterval,
      displayStreamWithPeriod,
    },
  } = useRootStore();

  interface PrecipCheckboxProps {
    interval: Duration;
  }
  const PrecipCheckbox = observer(({ interval }: PrecipCheckboxProps) => {
    const isSelected =
      SensorDisplayPrecip.is(display) && isEqual(display.interval, interval);

    return (
      <li
        onClick={() =>
          isSelected ? clearDisplay() : displayPrecipWithInterval(interval)
        }
      >
        <span style={{ padding: 0 }}>
          <Icon className={bulma("is-left")}>
            <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
          </Icon>
          {durationLabel[formatISODuration(interval)]}
        </span>
      </li>
    );
  });

  interface StreamCheckboxProps {
    period: "last" | Duration;
  }
  const StreamCheckbox = observer(({ period }: StreamCheckboxProps) => {
    const isSelected =
      SensorDisplayStream.is(display) &&
      (display.period === "last"
        ? period === "last"
        : period !== "last" && isEqual(display.period, period));
    return (
      <li
        onClick={() =>
          isSelected ? clearDisplay() : displayStreamWithPeriod(period)
        }
      >
        <span style={{ padding: 0 }}>
          <Icon className={bulma("is-left")}>
            <FontAwesomeIcon icon={isSelected ? faCheckSquare : faSquare} />
          </Icon>
          {period === "last"
            ? lastLabel
            : `${durationLabel[formatISODuration(period)]} ${max}`}
        </span>
      </li>
    );
  });


  return (
    <Columns>
      <Column id="precip-col" className={bulma("is-half")} >
        <aside>
          <p className={bulma("menu-label")}>{dict.precip}</p>
          <ul className={bulma("menu-list")}>
            {config.precipIntervals.default.map((interval) => (
              <PrecipCheckbox
                key={formatISODuration(interval)}
                interval={interval}
              />
            ))}
            <li>
              <Dropdown isHoverable>
                <div className="dropdown-trigger">
                  <Icon className={bulma("is-left")}>
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Icon>
                  {dict.other}
                </div>
                <DropdownMenu>
                  <ul>
                    {config.precipIntervals.more.map((interval) => (
                      <PrecipCheckbox
                        key={formatISODuration(interval)}
                        interval={interval}
                      />
                    ))}
                  </ul>
                </DropdownMenu>
              </Dropdown>
            </li>
          </ul>
        </aside>
      </Column>
      <Column className={bulma("is-half")}>
        <aside className={bulma("menu")}>
          <p className={bulma("menu-label")}>{dict.stream}</p>
          <ul className={bulma("menu-list")}>
            <StreamCheckbox period={"last"} />
            <StreamCheckbox period={{ hours: 6 }} />
            <StreamCheckbox period={{ hours: 12 }} />
            <StreamCheckbox period={{ days: 1 }} />
          </ul>
        </aside>
      </Column>
    </Columns>
  );
};

export default observer(SelectSensorFilter);
