import Color from "color";
import { StaticMapLayer } from "../../config";
import moment from "moment";
import notEmpty from "../../shared/notEmpty";
import { unfold } from "ramda";

const currentYear = moment().year();

export const forYear = (year: number): StaticMapLayer => (
  { name: `${year} wildfires`, source:  `${process.env.PUBLIC_URL}/geojson/wildfire${year}.geojson`})

export const current = (visible? :boolean ): StaticMapLayer | undefined => {
  try {
    const url = "https://services3.arcgis.com/T4QMspbfLg3qTGWY/arcgis/rest/services/WFIGS_Interagency_Perimeters_YearToDate/FeatureServer/0"
    const where = "attr_POOState IN ('US-AZ', 'US-NM')"

    return {
      name: "Current year wildfires",
      color: "red",
      source: {url, where, f: 'geojson'},
      visible
    };
  } catch {
    return undefined;
  }
};

export const previous = (max: number): StaticMapLayer[] =>
  unfold((n) => (n <= max ? [n, n + 1] : false), 1).map((n) => {
    return {
      ...forYear(currentYear - n),
      color: Color("red")
        .rotate(45 * (n / max))
        .hex(),
    };
  });

export const last = (n: number) => ({
  title: "Wildfires",
  layers: [current()].concat(previous(n-1)).filter(notEmpty),
});
