import { ISensor } from "../../models/Sensors";
import { TabularData } from "../useTabularData";

const get = async (
  sensor: ISensor,
  startDate: Date,
  endDate: Date
): Promise<TabularData> => {
  const [conagua, id] = sensor.id.split("_");
  if (!conagua) {
    return Promise.reject(new Error("Expecting conagua sensor"));
  }

  if (!id) {
    return Promise.reject(new Error("Expecting conagua ID"));
  }

  type ApiData = Map<string, number>;
  const url = `/data/conagua/${id}.precip.json`;
  const apiData: ApiData = await fetch(url).then((res) => res.json());

  const rows = new Map();
  Array.from(Object.entries(apiData)).forEach(([utc, val]) => {
    const date = new Date(utc);
    if (startDate <= date && date <= endDate) {
      rows.set(date, val);
    }
  });

  const data: TabularData = {
    type: "OneColumn",
    unit: "mm",
    rows,
  };

  return Promise.resolve(data);
};

export default get;
