import convert, { LengthUnits, VolumeFlowRateUnits } from "../convert";
import { decimalPlaces, useLocaleContext } from "../contexts/LocaleContext";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISensorDisplayStream } from "../models/Sensors";
import { Icon } from "trunx";
import config from "../config";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { scaleThreshold } from "d3-scale";
import { useRootStore } from "../models/Root";

interface StreamMarkerProps {
  measure: ISensorDisplayStream["measure"];
  height: number | undefined;
  discharge: number | undefined;
  heightUnit: LengthUnits | undefined;
  dischargeUnit: VolumeFlowRateUnits | undefined;
}

export const StreamM = observer(() => {
  const {
    layers: { baseMap },
  } = useRootStore();
  const color = baseMap.startsWith("Imagery") ? "white" : "black";

  return (
    <Icon style={{ fontSize: "1.2em" }} className="fa-layers missing">
      <FontAwesomeIcon
        icon={faExclamationTriangle}
        color={color}
        opacity={0.8}
      />
    </Icon>
  );
});

const StreamMarker = ({
  measure,
  height,
  discharge,
  heightUnit: fromHeightUnit,
  dischargeUnit: fromDischargeUnit,
}: StreamMarkerProps) => {
  const {
    units: {
      stream: { height: toHeightUnit, discharge: toDischargeUnit },
    },
  } = useLocaleContext();

  const fromUnit = measure === "height" ? fromHeightUnit : fromDischargeUnit;

  // Need height unit to compute background color
  if (fromHeightUnit === undefined || fromUnit === undefined) {
    return <StreamM />;
  }

  const value = measure === "height" ? height : discharge;

  const valueForColorUnit = measure === "height" ? "ft" : "ft3/s";
  const valueForColor = convert(value).from(fromUnit).to(valueForColorUnit);

  const toUnit = measure === "height" ? toHeightUnit : toDischargeUnit;
  const value_ = convert(value)
    .from(fromUnit)
    .to(toUnit)
    .toFixed(decimalPlaces(toUnit));

  const {
    heightBreakValues,
    dischargeBreakValues,
    textColors,
    backgroundColors,
  } = config.markers.stream;
  const breakValues =
    measure === "height" ? heightBreakValues : dischargeBreakValues;
  const streamTextColor = scaleThreshold(breakValues, textColors);
  const streamFill = scaleThreshold(breakValues, backgroundColors);

  const backgroundColor = streamFill(valueForColor);

  const color = streamTextColor(valueForColor);
  return (
    <span className="tag sensor-tag" style={{ color, backgroundColor }}>
      {value_}
    </span>
  );
};

export default StreamMarker;
