import {
  Dropdown,
  DropdownItemAnchor,
  DropdownMenu,
  DropdownTrigger,
  Icon,
  bulma,
} from "trunx";
import React, { useState } from "react";

import { BaseMap } from "./models/Layers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactDOM from "react-dom";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "./contexts/LocaleContext";
import { useRootStore } from "./models/Root";

const MapStyleDropdown = (): JSX.Element => {
  const container = React.useMemo(() => document.createElement("div"), []);

  const className = "map-style-dropdown";

  React.useEffect(() => {
    const previous = document.querySelector(
      ".leaflet-control-container " + className
    );
    if (previous) {
      previous.remove();
    }

    const root = document.querySelector(".leaflet-top.leaflet-right");
    if (root) {
      container.className = className;
      root.prepend(container);
    }
  }, [container]);

  const [selectBase, setSelectBase] = useState<boolean>(false);

  const {
    layers: { baseMap, setBaseMap },
  } = useRootStore();

  const {
    dict: {
      panel: { layers: dict },
    },
  } = useLocaleContext();

  const baseMaps = Object.values(BaseMap);
  const baseLayerItems = baseMaps.map((baseMap_) => (
    <DropdownItemAnchor
      key={baseMap_}
      isActive={baseMap === baseMap_}
      onClick={() => {
        setBaseMap(baseMap_);
      }}
    >
      {dict.baseMapOptions[baseMap_]}
    </DropdownItemAnchor>
  ));

  const node = (
    <Dropdown
      isActive={selectBase}
      className={bulma(["is-hidden-touch", "is-right"]) + " leaflet-control"}
    >
      <DropdownTrigger onClick={() => setSelectBase(!selectBase)}>
        <span>{dict.baseMapOptions[baseMap]}</span>
        <Icon className={bulma("is-small")}>
          <FontAwesomeIcon icon={faAngleDown} />
        </Icon>
      </DropdownTrigger>
      <DropdownMenu>{baseLayerItems}</DropdownMenu>
    </Dropdown>
  );

  return ReactDOM.createPortal(node, container);
};

export default observer(MapStyleDropdown);
