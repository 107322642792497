import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
} from "../shared/LeafletPanel";

import { ICameraLayerCamera } from "../models/Layers";
import { useRootStore } from "../models/Root";

const CameraInfoPanel = ({ camera }: { camera: ICameraLayerCamera }) => {
  const {
    layers: { clearCamera: close },
  } = useRootStore();
  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={camera.name} close={close} />}
    >
      <LeafletPanelBody>
        <img src={camera.url} alt={camera.name} style={{ width: "100%", padding: "1rem" }} />
      </LeafletPanelBody>
    </LeafletPanel>
  );
};


export default CameraInfoPanel;