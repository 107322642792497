import { faPause, faPlay, faStop } from "@fortawesome/free-solid-svg-icons";
import {
  Field,
  Icon,
  Label,
  Panel as LeafletPanel,
  Level,
  LevelItem,
  Progress,
  bulma,
} from "trunx";
import {
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useLocaleContext } from "../contexts/LocaleContext";
import RadarContext from "../contexts/RadarContext";
import { useRootStore } from "../models/Root";

const RadarPanel = () => {
  const {
    lang,
    dict: {
      panel: { radar: dict },
    },
  } = useLocaleContext();

  const radar = useContext(RadarContext);

  const {
    menus: { closePanel },
  } = useRootStore();

  if (!radar?.apiResponse) {
    return (
      <LeafletPanel
        heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
      >
        <div className="radar is-loading">&nbsp;</div>
      </LeafletPanel>
    );
  }

  const {
    frame,
    setVisible,
    playing,
    setPlaying,
    speed,
    setSpeed,
    opacity,
    setOpacity,
  } = radar;
  const { times } = radar.apiResponse;
  const frameTime = times[frame];

  const stop = () => {
    setPlaying(false);
    setVisible(false);
  };

  const playPause = () => {
    setPlaying(!playing);
    setVisible(true);
  };

  const left = (
    <LevelItem className={bulma("mr-2")}>
      <div className={bulma("level-item")}>
        <span onClick={() => playPause()}>
          <Icon className={bulma("is-left")}>
            <FontAwesomeIcon icon={playing ? faPause : faPlay} />
          </Icon>
        </span>
        <span onClick={() => stop()}>
          <Icon className={bulma("is-left")}>
            <FontAwesomeIcon icon={faStop} />
          </Icon>
        </span>
      </div>
    </LevelItem>
  );

  const right = (
    <LevelItem className={bulma("ml-2")}>
      {frameTime?.toLocaleTimeString(lang)}
    </LevelItem>
  );

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
    >
      <LeafletPanelBody>
        <LeafletPanelSection>
          <Level left={left} right={right}>
            <LevelItem>
              <Progress value={10 - frame} max={10} />
            </LevelItem>
          </Level>

          <Field className={bulma("is-horizontal")}>
            <div className={bulma("field-label")}>
              <Label>{dict.opacity}</Label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    style={{ width: "80%" }}
                    step="1"
                    min="1"
                    max="100"
                    type="range"
                    value={opacity * 100}
                    onChange={({ target }) =>
                      setOpacity(parseInt(target.value) / 100)
                    }
                  ></input>
                </div>
              </div>
            </div>
          </Field>

          <Field className={bulma("is-horizontal")}>
            <div className={bulma("field-label")}>
              <Label>{dict.speed}</Label>
            </div>
            <div className="field-body">
              <div className="field">
                <div className="control">
                  <input
                    style={{ width: "80%" }}
                    step="100"
                    min="0"
                    max="1000"
                    type="range"
                    value={speed}
                    onChange={({ target }) => setSpeed(parseInt(target.value))}
                  ></input>
                </div>
              </div>
            </div>
          </Field>
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};
export default RadarPanel;
