import { ISensorDisplayStream, IStreamSensor } from "../models/Sensors";

import DivIconMarker from "./DivIconMarker";
import { LeafletMouseEvent } from "leaflet";
import StreamMarker from "./StreamMarker";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../models/Root";

interface StreamSensorViewProps {
  sensor: IStreamSensor;
  display: ISensorDisplayStream;
}

const StreamSensorView = observer(
  ({ sensor, display }: StreamSensorViewProps) => {
    const { period, measure } = display;
    const {
      menus: { panel },
      sensors: { setSensor },
    } = useRootStore();

    const { id: idx, position, heightUnit, dischargeUnit } = sensor;
    const height = sensor.heightFor(period);
    const discharge = sensor.dischargeFor(period);

    const container = {
      tagName: "div",
      className: classNames("selectable-marker", {
        "is-selected": sensor.selected,
      }),
    };

    const eventHandlers =
      panel === "measure"
        ? {}
        : {
            click: (e: LeafletMouseEvent) => {
              setSensor(sensor);
            },
          };
    const marker = { position, eventHandlers };

    return (
      <DivIconMarker key={idx} marker={marker} container={container}>
        <StreamMarker
          height={height}
          discharge={discharge}
          heightUnit={heightUnit}
          dischargeUnit={dischargeUnit}
          measure={measure}
        ></StreamMarker>
      </DivIconMarker>
    );
  }
);

const StreamSensorsLayer = ({ display }: { display: ISensorDisplayStream }) => {
  const {
    sensors: { streamSensors },
  } = useRootStore();

  const interval = display.period;
  const sorter = (a: IStreamSensor, b: IStreamSensor) =>
    (a.heightFor(interval) || 0) - (b.heightFor(interval) || 0);
  return (
    <>
      {streamSensors.sort(sorter).map((sensor) => (
        <StreamSensorView
          key={sensor.id}
          sensor={sensor}
          display={display}
        ></StreamSensorView>
      ))}
    </>
  );
};

export default observer(StreamSensorsLayer);
