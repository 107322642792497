import { Icon, Subtitle, Table } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { ReactNode, useEffect, useState } from "react";

import { ILightning } from "../models/Layers";
import { IStrike } from "../models/VaisalaAPI";
import PanelInfoTable from "../shared/PanelInfoTable";
import { formatDuration } from "date-fns";
import { intervalToDuration } from "date-fns/esm";
import { lightningIcon } from "../shared/lightning";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "../contexts/LocaleContext";

const legendDurations = [
  { minutes: 15 },
  { minutes: 30 },
  { minutes: 45 },
  { minutes: 60 },
];

const Legend = () => {
  const {
    dict: {
      panel: {
        lightning: { legend, within },
      },
    },
  } = useLocaleContext();
  return (
    <LeafletPanelSection>
      <Subtitle>{legend}</Subtitle>
      <Table isFullwidth isNarrow style={{ marginTop: 0, textAlign: "center" }}>
        <tbody>
          {legendDurations.map((age) => (
            <tr key={age.minutes}>
              <td style={{ textAlign: "right" }}>
                <Icon>{lightningIcon(age)}</Icon>
              </td>
              <td>{within(age.minutes)}</td>
            </tr>
          ))}

          <tr style={{ backgroundColor: "#1f2424" }}>
            <td colSpan={2}>&nbsp;</td>
          </tr>
        </tbody>
      </Table>
    </LeafletPanelSection>
  );
};

const Selected = ({ strike }: { strike: IStrike }) => {
  const {
    dict: {
      panel: {
        lightning: { detected, current, location, positive, negative },
      },
    },
  } = useLocaleContext();
  const [date, setDate] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => setDate(new Date()), 60000);
    return () => clearInterval(interval);
  }, []);

  const ago = "ago at"; // TODO: translate
  const rows: [string, ReactNode][] = [];
  const age = intervalToDuration({ start: strike.local, end: date });
  rows.push([
    detected,
    <span>
      {formatDuration(age, { format: ["minutes"] })} {ago}{" "}
      <i>{strike.local.toLocaleTimeString()}</i>
    </span>,
  ]);
  rows.push([
    current,
    <span>
      {Math.abs(strike.current).toString()} kA (
      <i>{strike.current > 0 ? positive : negative}</i>)
    </span>,
  ]);
  rows.push([location, [strike.lat, strike.lng].join(", ")]);

  return <PanelInfoTable rows={rows} />;
};

interface LightningInfoPanelProps {
  lightning: ILightning;
  legend: boolean;
}
const LightningInfoPanel = ({ lightning, legend }: LightningInfoPanelProps) => {
  const { selected, clear } = lightning;
  const {
    dict: {
      panel: { lightning: dict },
    },
  } = useLocaleContext();

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={dict.title} close={clear} />}
    >
      <LeafletPanelBody>
        {legend && <Legend />}

        <LeafletPanelSection>
          {legend && <Subtitle>{dict.selected}</Subtitle>}
          {selected && <Selected strike={selected} />}
          {!selected && (
            <div>
              <i>{dict.clickPrompt}</i>
            </div>
          )}
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(LightningInfoPanel);
