// https://www.cbrfc.noaa.gov/dbdata/station/ratdata/ratdb_lib.js

function GetFlow (stage,SHTBL,RTBL) {
    //var WRN=-1;


    //apply adjustment
    stage = stage + SHTBL['dadj'];
            
    //apply shift
    let shift=0;  let v0=SHTBL['val0'];  let s0=SHTBL['sh0'];
    for ( var i=0; i<SHTBL['nval']; i++) {
        let v1 = SHTBL['val'+i];  
        let s1 = SHTBL['sh'+i];
        if (stage < v1) {
            if (i === 0) {   //below all shift values
                shift = s1;
            } else {    //on or between shift values
                shift = s0 + ((s1-s0)/(v1-v0))*(stage-v0);
            }
            break;
        } else if (i === SHTBL['nval']-1) {  //above all shift values
                shift = s1;
        }
        //store last values
        v0 = v1;
        s0 = s1;
    }
    stage = stage + shift;

    //convert to flow
    //WRN=3;
    let flow=0;
    if (stage < RTBL['h0']) {   //below table
        if (RTBL['q0'] === 0) {
            flow=0;
        } else {
            let stg0=RTBL['h0'];
            let q0=RTBL['q0'];
            let stg1=RTBL['h1'];
            let q1=RTBL['q1'];
            let slope = (q1-q0) / (stg1-stg0);
            let b = q0 - slope*stg0;
            flow = slope*stage + b;
        }
        //WRN=1
    } else if (stage > RTBL['h'+(RTBL['npair']-1)]) {   //above table

        let stg0=RTBL['h'+(RTBL['npair']-2)];
        let q0=RTBL['q'+(RTBL['npair']-2)];
        let stg1=RTBL['h'+(RTBL['npair']-1)];
        let q1=RTBL['q'+(RTBL['npair']-1)];
        let slope = (Math.log10(q1)-Math.log10(q0)) / (Math.log10(stg1)-Math.log10(stg0));
        let b = Math.log10(q1) - slope*Math.log10(stg1);
        flow = Math.pow(10, slope*Math.log10(stage) + b);
        //let WRN=2
     } else {   //within table
        let stg0=RTBL['h0'];
        let q0=RTBL['q0'];
        for (i =0; i < RTBL['npair']; i++) {
            let stg1=RTBL['h'+i];  let q1=RTBL['q'+i];
            if (stage === stg1) {
                flow=q1;
                //let WRN=0;
                break;
            } else if (stage < stg1) {
                //if any values <= 0 use linear interpolation
                if (stage <= 0 || stg0 <= 0 || stg1 <= 0 || q0 <= 0 || q1 <= 0) {
                    let slope = (q1-q0) / (stg1-stg0);
                    let b = q0 - slope*stg0;
                    flow = slope*stage + b;
                } else {
                    let slope = (Math.log10(q1)-Math.log10(q0)) / (Math.log10(stg1)-Math.log10(stg0));
                    let b = Math.log10(q1) - slope*Math.log10(stg1);
                    flow = Math.pow(10, slope*Math.log10(stage) + b);
                }
                //let WRN=0;
                break;
            }
            //store last values
            stg0=stg1;
            q0=q1;
        }
    }
    //no negatives or huge values
    if (flow < 0) {
        flow=0;
    }
    if (flow > 1000000000) {
        //let WRN=3;
        return -1;
    }
  
    //return rounded cfs value
    flow=parseInt(Math.round(flow));
    return flow;
}

export {GetFlow}