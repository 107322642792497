import React, { useEffect, useState } from "react";

import L from "leaflet";
import ReactDOM from "react-dom";
import { observer } from "mobx-react-lite";

interface Props {
  children: React.ReactNode;
}

const LeafletMobileCard = ({ children}: Props): JSX.Element => {
  const root = document.querySelector(".leaflet-control-container");
  const [container] = useState(() => {
    const el = document.createElement("div");
    el.classList.add("mobile-card");
    L.DomEvent.disableClickPropagation(el);
    L.DomEvent.disableScrollPropagation(el);
    el.onclick = e => { e.stopPropagation(); };
    return el;
  });

  useEffect(() => {
    if (root) {
      root.append(container);

      return () => {
        root.removeChild(container);
      };
    }
  }, [container, root]);

  return ReactDOM.createPortal(children, container);
};

export default observer(LeafletMobileCard);
