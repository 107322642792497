import { GetFlow } from "./cbrfc/ratdb_lib";

import { Icon, Progress, Subtitle, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";

import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { useLocaleContext } from "../contexts/LocaleContext";
import { ICbrfc } from "../models/Layers";

import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import highchartsMore from "highcharts/highcharts-more";
import { IRiver } from "../models/CbrfcAPI";
highchartsMore(Highcharts);

interface CbrfcInfoLoadingProps {
  selected: IRiver;
  clear: () => void;
}
const CbrfcInfoLoading = ({ selected, clear }: CbrfcInfoLoadingProps) => {
  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={selected.id} close={clear} />}
    >
      <LeafletPanelBody isFullHeight>
        <LeafletPanelSection>
          <Subtitle className={bulma("has-text-centered")}>
            {selected.name}
          </Subtitle>
        </LeafletPanelSection>
        <LeafletPanelSection>
          <Progress
            className={bulma("m-auto")}
            style={{ width: "50%" }}
            max={100}
          ></Progress>
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

interface CbrfcInfoChartProps {
  selected: IRiver;
  clear: () => void;
  data: { [k: string]: any };
}

const CbrfcInfoChart = ({ data, selected, clear }: CbrfcInfoChartProps) => {
  const {
    dict: {
      panel: { cbrfc: dict },
    },
  } = useLocaleContext();

  let { options, israt, SHTBL, RTBL } = data;

  // Update styles to suit dark background
  options.chart = {
    ...options.chart,
    width: null,
    backgroundColor: "transparent",
    style: { color: "white" },
  };
  options.title = { ...options.title, style: { color: "lightgrey" } };
  options.subtitle = { ...options.subtitle, style: { color: "grey" } };
  options.legend = { ...options.legend, itemStyle: { color: "lightgrey" } };

  const xTitle = { ...options.xAxis.title, style: { color: "grey" } };
  options.xAxis = {
    ...options.xAxis,
    title: xTitle,
    labels: { style: { color: "grey" } },
  };

  options.xAxis.plotBands = options.xAxis.plotBands.map(
    (axis: any, n: number) => {
      const darker = "rgba(255, 255, 255, 0.1)";
      const lighter = "rgba(255, 255, 255, 0.2)";
      const color = n % 2 === 0 ? darker : lighter;
      return { ...axis, color };
    }
  );

  options.yAxis = options.yAxis.map((axis: any) => {
    const yTitle = { ...axis.title, style: { color: "grey" } };
    return { ...axis, title: yTitle, labels: { style: { color: "grey" } } };
  });

  const chartComponent = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (!chartComponent.current) {
      return;
    }
    const chart = chartComponent.current.chart;

    // https://www.cbrfc.noaa.gov/dbdata/station/flowgraph/flowgraph_hc.js:493
    if (israt !== 0) {
      const opt: { [k: string]: any } = {};
      opt["labels"] = {};

      // linear_flow is a checkbox on https://www.cbrfc.noaa.gov/lmap/lmap.php
      // const linear_flow = 0;
      // if (linear_flow) {
      //   opt.labels.formatter = function () {
      //     return GetStage(this.value, SHTBL, RTBL);
      //   };
      // } else {
      opt.labels.formatter = function () {
        return GetFlow(this.value, SHTBL, RTBL);
      };
      //}
      const axis = chart.get("yaxis2");
      if (axis !== undefined) {
        // @ts-ignore
        axis.update(opt);
      }
    }
  });

  const link = `https://www.cbrfc.noaa.gov/dbdata/station/flowgraph/flowgraph_hc.html?id=${selected.id}&ptype=0&linear_flow=0`;

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={selected.id} close={clear} />}
    >
      <LeafletPanelBody isFullHeight>
        <LeafletPanelSection>
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartComponent}
          />
        </LeafletPanelSection>
        <LeafletPanelSection>
          <div style={{ textAlign: "center" }}>
            <a href={link} rel="noopener noreferrer" target="_blank">
              {dict.open}&nbsp;cbrfc.noaa.gov
              <Icon className={"is-right"}>
                <FontAwesomeIcon icon={faExternalLinkAlt}></FontAwesomeIcon>
              </Icon>
            </a>
          </div>
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

const CbrfcInfoPanel = ({ cbrfc }: { cbrfc: ICbrfc }) => {
  const { selected, clear } = cbrfc;

  const [data, setData] = useState<{ [k: string]: any }>();

  useEffect(() => {
    if (!selected) {
      return;
    }
    const dataUrl = `https://eoopkw8n4wygnls.m.pipedream.net/?id=${selected.id}`;
    // https://www.cbrfc.noaa.gov/dbdata/station/flowgraph/flowgraph_data_hc.py?id=${selected.id}&ctype=hc&forecast_date=2023-10-25&begin_date=2023-10-15&end_date=2023-11-04&ptype=3&linear_flow=0&zero_flow=0&scale_critical=0&scale_historical=0&show_stats=0&show_ens=0&show_daily=0&show_simulated=1&pyears=&ts=1698193400844
    fetch(dataUrl)
      .then((res) => res.json())
      .then((data) => setData(data));
  }, [selected]);

  if (!selected) {
    return <></>;
  }

  if (!data) {
    return <CbrfcInfoLoading selected={selected} clear={clear} />;
  }

  return <CbrfcInfoChart selected={selected} clear={clear} data={data} />;
};

export default observer(CbrfcInfoPanel);
