import { Button, Level, LevelItem, Panel, bulma } from "trunx";
import { ComponentProps, ReactNode } from "react";

import BookmarkStar from "./BookmarkStar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ISensor } from "../models/Sensors";
import { useRootStore } from "../models/Root";

export const LeafletPanel = Panel;

interface ILeafletPanelHeadingProps {
  title: string;
  close: () => void;
}

export const LeafletPanelHeading = ({
  title,
  close,
}: ILeafletPanelHeadingProps) => {
  const left = <LevelItem>{title}</LevelItem>;
  const right = (
    <Button
      className={bulma(["is-pulled-right", "has-text-white", "is-ghost"])}
      onClick={close}
    >
      <FontAwesomeIcon icon="times" />
    </Button>
  );

  return <Level left={left} right={right} />;
};

interface ILeafletSensorPanelHeadingProps {
  sensor: ISensor;
  action?: "close" | "back";
}

export const LeafletSensorPanelHeading = ({
  sensor,
  action = "close",
}: ILeafletSensorPanelHeadingProps) => {
  const {
    sensors: { clearSensor, setSensor },
  } = useRootStore();

  const onClick =
    action === "close" ? clearSensor : () => setSensor(sensor, "summary");

  const left = (
    <>
      <LevelItem>
        <BookmarkStar sensor={sensor} />
      </LevelItem>
      <LevelItem style={{ flexShrink: 1, justifyContent: "flex-start" }}>
        {sensor.name}
      </LevelItem>
    </>
  );

  const right = (
    <>
      <LevelItem style={{ paddingLeft: "1ch" }}>
        <Button
          className={bulma(["has-text-white", "is-ghost"])}
          onClick={onClick}
        >
          <FontAwesomeIcon icon="times" />
        </Button>
      </LevelItem>
    </>
  );

  return (
    <div className="panel-heading">
      <Level left={left} right={right} />
    </div>
  );
};

interface LeafletPanelBodyProps {
  children: ReactNode;
  isFullHeight?: boolean;
  minHeight?: string;
}

export const LeafletPanelBody = ({
  children,
  isFullHeight,
  minHeight,
}: LeafletPanelBodyProps) => {
  let style: React.HTMLAttributes<HTMLDivElement>["style"] = {};
  if (isFullHeight) {
    style['height'] = "80vh" ;
  } else if (minHeight) {
    style['minHeight'] = minHeight;
  }
  return (
    <div style={style} className="panel-body">
      {children}
    </div>
  );
};

export const LeafletPanelSection = (props: ComponentProps<"div">) => {
  const props_ = {
    ...props,
    className: (props.className || "") + " panel-section",
  };
  return <div {...props_}>{props.children}</div>;
};
