import { isoToSeconds, parseISODuration, toSeconds } from "./duration";

import { zipWith } from "ramda";

export const streamPeriods = ["PT6H", "PT12H", "P1D"];
export const streamPeriodDurations = streamPeriods.map(parseISODuration);
export const streamPeriodSeconds = streamPeriods.map(isoToSeconds);

export const streamPeriodIdx = (period: Duration): number =>
  streamPeriodSeconds.indexOf(toSeconds(period));

export const withStreamPeriodDurations = zipWith(
  (period, value: number | undefined) => ({ period, value }),
  streamPeriodDurations
);
