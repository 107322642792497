import { Button, Modal, ModalBackground, ModalCard, bulma } from "trunx";

import { ReactNode } from "react";
import { useCookie } from "react-use";

interface DisclaimerProps {
  children: ReactNode;
  outLink: string;
}
const Disclaimer = ({ children, outLink }: DisclaimerProps) => {
  const [accepted, setAccepted] = useCookie("disclaimer");

  const footer = (
    <>
      <Button
        className={bulma("is-primary")}
        onClick={() => setAccepted("accepted")}
        style={{ padding: "0 2rem" }}
      >
        Accept
      </Button>
      <Button onClick={() => (window.location.href = outLink)}>
        Home page
      </Button>
    </>
  );
  return (
    <Modal isActive={!accepted}>
      <ModalBackground></ModalBackground>
      <ModalCard header="Disclaimer" footer={footer}>
        {children}
      </ModalCard>
    </Modal>
  );
};

export default Disclaimer;
