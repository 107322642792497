import { Column, Columns, Subtitle, Tag, Tags, bulma } from "trunx";
import {
  decimalPlaces,
  formatUnit,
  useLocaleContext,
} from "../../contexts/LocaleContext";
import { formatISODuration, toSeconds } from "../../shared/duration";

import { ISelectedStreamSensor } from "../../models/Sensors";
import convert from "../../convert";
import { observer } from "mobx-react-lite";
import { splitEvery } from "ramda";
import { withStreamPeriodDurations } from "../../shared/streamPeriods";

const StreamDataTable = ({ selected }: { selected: ISelectedStreamSensor }) => {
  const { sensor, display } = selected;
  const {
    dict: { intervals: dict },
  } = useLocaleContext();

  const {
    latest,
    values,
    heightUnit: fromHeightUnit,
    dischargeUnit: fromDischargeUnit,
  } = sensor;

  const {
    dict: {
      panel: {
        sensors: {
          info: { stage: stageTitle, discharge: dischargeTitle },
        },
      },
    },

    units: {
      stream: { height: toHeightUnit, discharge: toDischargeUnit },
    },
  } = useLocaleContext();

  const title = display.measure === "height" ? stageTitle : dischargeTitle;
  const fromUnit =
    display.measure === "height" ? fromHeightUnit : fromDischargeUnit;
  const toUnit = display.measure === "height" ? toHeightUnit : toDischargeUnit;

  interface DataTagProps {
    period: "last" | Duration;
    value: number | undefined;
  }

  const last: DataTagProps = {
    period: "last",
    value: display.measure === "height" ? latest.height : latest.discharge,
  };

  const rest: DataTagProps[] = withStreamPeriodDurations(
    values.map((s) => (display.measure === "height" ? s.height : s.discharge))
  );
  const data = [last].concat(rest);

  const DataTag = observer(({ period, value }: DataTagProps) => {
    const isDisplaying =
      period === "last"
        ? display.period === "last"
        : display.period !== "last" &&
          toSeconds(period) === toSeconds(display.period);

    const key = period === "last" ? "last" : formatISODuration(period);
    const label =
      period === "last"
        ? dict.lastLabel
        : dict.durationLabel[formatISODuration(period)];

    return (
      <Tags className="is-stage is-justify-content-center" key={key} hasAddons>
        <Tag key="label" className={isDisplaying ? bulma("is-info") : ""}>
          {label}
        </Tag>
        <Tag key="value" className={value !== 0 ? bulma("is-primary") : ""}>
          {value !== undefined && fromUnit
            ? convert(value)
                .from(fromUnit)
                .to(toUnit)
                .toFixed(decimalPlaces(toUnit))
            : ""}
        </Tag>
      </Tags>
    );
  });

  interface DataColumnProps {
    data: DataTagProps[];
    idx: number;
  }
  const DataColumn = ({ data, idx }: DataColumnProps) => (
    <Column key={idx.toString()}>
      {data.map((d, idx_) => (
        <DataTag key={idx_} {...d} />
      ))}
    </Column>
  );

  return (
    <div id="sensor-data-table">
      <Subtitle>
        {title}&nbsp;
        <small className={bulma("is-size-6")}>({formatUnit(toUnit)})</small>
      </Subtitle>

      <Columns>
        {splitEvery(2, data).map((column, idx) => (
          <DataColumn key={idx} idx={idx} data={column} />
        ))}
      </Columns>
    </div>
  );
};

export default observer(StreamDataTable);
