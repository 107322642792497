import { Instance, cast, onSnapshot, types } from "mobx-state-tree";

import Cookies from "js-cookie";

const { identifier, map, model } = types;

const Bookmark = model({ id: identifier }).named("Bookmark");

export const cookieName = "bookmarks";

// set would be more appropriate, but:
// https://github.com/mobxjs/mobx-state-tree/issues/381
export const BookmarkStore = model({ ids: map(Bookmark) })
  .named("Bookmarks")
  .actions((self) => ({
    toggleBookmark(o: string | { id: string}) {
      const id = (typeof(o) === 'string') ? o : o.id

      if (id.includes(",")) {
        // This would break the cookie:
        throw new Error("Bookmarks ids cannot contain commas");
      }

      if (self.ids.has(id)) {
        self.ids.delete(id);
      } else {
        self.ids.put({ id });
      }
    },
    afterCreate() {
      const cookie = Cookies.get(cookieName);
      if (cookie) {
        const ids = cookie.split(",").map((id) => [id, { id }]);
        self.ids = cast(Object.fromEntries(ids));
      }

      onSnapshot(self, (newSnapshot) => {
        const keys = Object.keys(newSnapshot.ids);
        if (keys.length > 0) {
          Cookies.set(cookieName, keys.join(","));
        } else {
          Cookies.remove(cookieName);
        }
      });
    },
  }))
  .views((self) => ({
    isBookmarked({ id }: { id: string }) {
      return self.ids.has(id);
    },
  }));

export type IBookmarkStore = Instance<typeof BookmarkStore>;
