import { Icon, IconText, bulma } from "trunx";
import {
  IconDefinition,
  faBookmark,
  faCloudRain,
  faLayerGroup,
  faLocationArrow,
  faTape,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPanel } from "./models/Menus";
import { LangSelect } from "./shared/LangSelect";
import config from "./config";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocaleContext } from "./contexts/LocaleContext";
import { useMap } from "react-leaflet";
import { useRootStore } from "./models/Root";

interface NavMenuPanelItemProps {
  panel: IPanel;
  icon: IconDefinition;
}
const NavMenuPanelItem = ({ panel, icon }: NavMenuPanelItemProps) => {
  const {
    dict: { panel: panelDict },
  } = useLocaleContext();
  const { title } = panelDict[panel];

  const {
    menus: { openPanel },
  } = useRootStore();

  return (
    <li>
      <IconText onClick={() => openPanel(panel)}>
        <Icon>
          <FontAwesomeIcon icon={icon} />
        </Icon>
        <span>{title}</span>
      </IconText>
    </li>
  );
};

const NavMenuFindMe = () => {
  const {
    dict: { nav },
  } = useLocaleContext();


  const {
    menus: { closeNav },
  } = useRootStore();

  const map = useMap();

  const action = () => {
    map.locate({ setView: true, maxZoom: 15});
    closeNav();
  };
  return (
    <li>
      <IconText onClick={action}>
        <Icon>
          <FontAwesomeIcon icon={faLocationArrow} />
        </Icon>
        <span>{nav.findme}</span>
      </IconText>
    </li>
  );
};

const NavMenu = () => {
  const {
    dict: {
      panel: { display, tools },
    },
  } = useLocaleContext();

  const { layers } = useRootStore();
  const { radar } = config;

  return (
    <aside className={bulma("menu")}>
      <p className={bulma("menu-label")}>{display}</p>
      <ul className={bulma("menu-list")}>
        <NavMenuPanelItem panel="sensors" icon={faCircle} />
        {layers.any && <NavMenuPanelItem panel="layers" icon={faLayerGroup} />}
        {radar && <NavMenuPanelItem panel="radar" icon={faCloudRain} />}
      </ul>
      <p className={bulma("menu-label")}>{tools}</p>
      <ul className={bulma("menu-list")}>
        <NavMenuPanelItem panel="bookmarks" icon={faBookmark} />
        <NavMenuPanelItem panel="measure" icon={faTape} />
        <NavMenuFindMe />
      </ul>
      <p className={bulma("menu-label")}>&nbsp;</p>
      <ul className={bulma("menu-list")}>
        <LangSelect />
      </ul>
    </aside>
  );
};
export default NavMenu;
