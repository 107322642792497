import { useEffect, useState } from "react";

import { ISensor } from "../models/Sensors";
import { go } from "fuzzysort";
import { useRootStore } from "../models/Root";

const useFindSensors = (find: string) => {
  interface FindSensorsResult extends Fuzzysort.KeyResults<ISensor> {}
  const [results, setResults] = useState<FindSensorsResult>();

  const {
    sensors: { sensors },
  } = useRootStore();

  useEffect(() => {
    const opts = { key: "name", limit: 3 };
    const results_ = go(find, Array.from(sensors.values()), opts);
    setResults(results_);
  }, [find, sensors]);

  return results;
};

export default useFindSensors;
