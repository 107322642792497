import { DivIcon, LatLng, LeafletMouseEvent, Point } from "leaflet";
import { Icon, Section, Table, bulma } from "trunx";
import { Marker as LeafletMarker, Polyline, useMap } from "react-leaflet";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import convert from "../convert";
import { useLocaleContext } from "../contexts/LocaleContext";
import { useRootStore } from "../models/Root";

interface Marker {
  position: LatLng;
  number: number;
}

interface RowProps {
  left: Marker;
  right: Marker;
  distance: number;
}
const Row = ({ left, right, distance }: RowProps) => {
  const { unitSystem } = useLocaleContext();
  const distanceLocal = convert(distance)
    .from("m")
    .toBest({ system: unitSystem, exclude: ["nMi", "fathom", "ft-us"] });
  return (
    <>
      <td>
        {String.fromCharCode(65 + left.number)}
        <Icon>
          <FontAwesomeIcon icon="arrow-right" />
        </Icon>
        {String.fromCharCode(65 + right.number)}
      </td>
      <td>
        {distanceLocal && distanceLocal.val.toFixed(2)}&nbsp;
        {distanceLocal && distanceLocal.unit}
      </td>
    </>
  );
};

const MeasurePanel = () => {
  const {
    dict: {
      panel: { measure: dict },
    },
  } = useLocaleContext();

  const {
    menus: { closePanel },
    isMobile,
  } = useRootStore();

  const [markers, setMarkers] = useState<LatLng[]>([]);

  const map = useMap();
  useEffect(() => {
    map.off("click");

    map.on("click", (event: LeafletMouseEvent) => {
      const point = map.project(event.latlng).add([0, isMobile ? 0 : 60]);
      const target = map.unproject(point);
      setMarkers(() => [...markers, target]);
    });

    return () => {
      map.off("click");
    };
  }, [isMobile, map, markers]);

  const first = markers[0];
  const last = markers[markers.length - 1];
  const { rows, totalDistance } = markers.reduce(
    (
      { rows, totalDistance }: { rows: JSX.Element[]; totalDistance: number },
      _,
      index,
      array
    ) => {
      if (index > 0) {
        const left = array[index - 1];
        const right = array[index];
        const distance = left.distanceTo(right);
        totalDistance += distance;
        rows.push(
          <tr key={index}>
            <Row
              key={index - 1}
              left={{ position: left, number: index - 1 }}
              right={{ position: right, number: index }}
              distance={distance}
            />
          </tr>
        );
      }
      return { rows, totalDistance };
    },
    { rows: [], totalDistance: 0 }
  );

  const total = (
    <tfoot>
      <tr>
        <Row
          left={{ position: first, number: 0 }}
          right={{ position: last, number: markers.length - 1 }}
          distance={totalDistance}
        />
      </tr>
    </tfoot>
  );
  const MarkerIcon = (letter: string) =>
    new DivIcon({
      className: "measure-marker-icon",
      iconSize: new Point(20, 20),
      html: letter,
    });

  return (
    <LeafletPanel
      heading={<LeafletPanelHeading title={dict.title} close={closePanel} />}
    >
      <LeafletPanelBody>
        <LeafletPanelSection>
          {markers.length < 2 && (
            <Section className={bulma("has-text-centered")}>
              <i>Click the map to add measurement markers.</i>
            </Section>
          )}
          {markers.length >= 2 && (
            <Table isFullwidth>
              <tbody>{rows}</tbody>
              {rows.length > 1 && total}
            </Table>
          )}

          {markers.map((marker, i) => (
            <LeafletMarker
              key={i}
              position={marker}
              icon={MarkerIcon(String.fromCharCode(65 + i))}
            ></LeafletMarker>
          ))}

          <Polyline positions={markers}></Polyline>
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default MeasurePanel;
