import { formatISO } from "date-fns";
import { partition } from "ramda";
import config from "./config";
import { isUnit, Units } from "./convert";

export interface SensorMeta {
  created: Date;
}

export interface Sensor {
  source: string;
  unitLong?: string;
}

export interface SensorApiResponse<M extends SensorMeta, S extends Sensor> {
  meta: M;
  data: S[];
}

const filterAgencyPassList = <D extends Sensor>(data: D[]) => {
  const { agencyPassList } = config;
  return agencyPassList === undefined
    ? data // Assume all data if no list is specified
    : data.filter(({ source }) => agencyPassList.includes(source));
};

const filterBadUnits = <D extends Sensor>(data: D[], unit: RegExp) => {
  const [good, bad] = partition(({ unitLong }) => {
    return unitLong === undefined || unit.test(unitLong as string);
  }, data);
  if (bad.length > 0) {
    console.error(`can't handle unitLong which doesn't match ${unit}`);
  }
  return good;
};

export const fetchSensorAPI = async <M extends SensorMeta, S extends Sensor>(
  url: string,
  check: RegExp
): Promise<SensorApiResponse<M, S>> => {
  try {
    const fetchResponse = await fetch(url);
    if (!fetchResponse.ok) {
      throw new Error(`${fetchResponse.status} ${fetchResponse.statusText}`);
    }
    const json: any = await fetchResponse.json();
    const meta = { ...json.meta, created: new Date(json.meta.created) };
    const data = filterBadUnits<S>(filterAgencyPassList<S>(json.data), check);
    return { meta, data };
  } catch (e) {
    console.log(e);
    throw e;
  }
};

export const rootApiUrl = new URL(
  process.env.REACT_APP_API_URL || "https://jefdaq.jefulleralert.com"
);

export interface TabularData {
  data: {
    local: string;
    utc: string;
    val: number;
  }[];
  meta: {
    units: string[];
  };
}

export interface TabularDataRated {
  data: {
    local: string;
    utc: string;
    val: number;
  }[];
  meta: {
    units: string[];
  };
}

export const tabularDataUrl = (
  site: string,
  range?: { startDate: Date; endDate: Date },
  rated?: boolean
) => {
  const url = rootApiUrl + (rated ? "/tabular_dataRated/" : "/tabular_data/");

  const params = new URLSearchParams({ sensorPair: site });
  if (range) {
    params.append(
      "startDate",
      formatISO(range.startDate, { representation: "date" })
    );
    params.append(
      "endDate",
      formatISO(range.endDate, { representation: "date" })
    );
  }
  return url + "?" + params;
};

export const normalizeUnit = (unit: string): Units | undefined => {
  const unit_ = unit.trim().toLowerCase();
  switch (unit_) {
    case "cfs":
      return "ft3/s";
    case "gpm":
      return "gal/min";
    default:
      return isUnit(unit_) ? unit_ : undefined;
  }
};
