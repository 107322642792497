import { DynamicLayer, DynamicLayerMetadata } from "../models/Layers";
import { Icon, IconText, bulma } from "trunx";
import {
  LeafletPanel,
  LeafletPanelBody,
  LeafletPanelHeading,
  LeafletPanelSection,
} from "../shared/LeafletPanel";
import { faCheckSquare, faSquare } from "@fortawesome/free-regular-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Instance } from "mobx-state-tree";
import { observer } from "mobx-react-lite";

interface DynamicLayerPanelProps {
  model: Instance<typeof DynamicLayer>;
  back: () => void;
}
const DynamicLayerPanel = ({
  model: { title, metadata, isVisible, toggleLayer },
  back,
}: DynamicLayerPanelProps) => {
  const LayerMenu = observer(
    ({ metadata }: { metadata: Instance<typeof DynamicLayerMetadata> }) => {
      return (
        <aside className={bulma("menu")}>
          <ul className={bulma("menu-list")}>
            {metadata.map(({ name, id }) => {
              return (
                <li key={`key-${id}`} onClick={() => toggleLayer(id)}>
                  <IconText>
                    <Icon className={bulma("is-left")}>
                      <FontAwesomeIcon
                        icon={isVisible(id) ? faCheckSquare : faSquare}
                      />
                    </Icon>
                    <span>{name}</span>
                  </IconText>
                </li>
              );
            })}
          </ul>
        </aside>
      );
    }
  );

  return (
    <LeafletPanel heading={<LeafletPanelHeading title={title} close={back} />}>
      <LeafletPanelBody>
        <LeafletPanelSection>
          {metadata ? <LayerMenu metadata={metadata}></LayerMenu> : undefined}
        </LeafletPanelSection>
      </LeafletPanelBody>
    </LeafletPanel>
  );
};

export default observer(DynamicLayerPanel);
