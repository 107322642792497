import {
  addMinutes,
  differenceInMinutes,
  max,
  parse,
  subHours,
} from "date-fns";
import { LatLng, latLngBounds } from "leaflet";
import { Instance, flow, types } from "mobx-state-tree";

import { formatInTimeZone } from "date-fns-tz";
import { times } from "ramda";
import { utcToZonedTime } from "../date-fns-tz";

const { map, model, identifier, number, refinement } = types;

const float = refinement(number, (n) => !isNaN(n));

export const Strike = model({
  id: identifier,
  lat: float,
  lng: float,
  current: number,
})
  .named("Strike")
  .views((self) => ({
    get local() {
      const utc = parse(self.id, "MM/dd/yyyy HH:mm:ss.SSS", new Date());
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
      return utcToZonedTime(utc, timeZone);
    },
    get position() {
      return new LatLng(self.lat, self.lng);
    },
  }));

export interface IStrike extends Instance<typeof Strike> { }

const rootApiUrl = new URL(
  "https://" + (process.env.REACT_APP_DOMAIN || "jefulleralert.com")
);

const url = (date: Date) =>
  new URL(
    formatInTimeZone(date, "UTC", "'lightning'/yyyy.MM.dd.HH.mm.'txt'"),
    rootApiUrl
  );

export const VaisalaAPI = model({
  strikeMap: map(Strike),
})
  .volatile((self) => ({
    fetching: false,
    latest: new Date(0),
    strikes: [] as IStrike[],
  }))
  .views((self) => ({
    get bounds() {
      const positions = Array.from(self.strikeMap.values()).map(
        (s) => s.position
      );
      return latLngBounds(positions);
    },
  }))
  .actions((self) => ({
    putFile(csv: string) {
      csv.split(/\r?\n/).forEach((line) => {
        const [id, lat_, lng_, current_, cloud_] = line.split(",");
        const lat = parseFloat(lat_);
        const lng = parseFloat(lng_);
        const cloud = cloud_ === "1";
        const current = parseFloat(current_);
        if (!cloud && !isNaN(lat) && !isNaN(lng) && !isNaN(current)) {
          if (!self.strikeMap.has(id)) {
            self.strikeMap.put({ id, lat, lng, current });
          }
        }
      });
    },
  }))
  .actions((self) => ({
    fetch: flow(function* f() {
      if (!self.fetching) {
        self.fetching = true;
        while (self.fetching) {
          const now = new Date();
          const maxAgeHours = 1;
          const earliest = max([self.latest, subHours(now, maxAgeHours)])

          const numFiles = differenceInMinutes(now, earliest);
          const timestamps = times((n) => addMinutes(earliest, n), numFiles)

          for (const time of timestamps) {
            const url_ = url(time)
            try {
              const response = yield fetch(url_.toString());
              if (response.ok) {
                const csv = yield response.text();
                self.putFile(csv);
                if (time > self.latest) {
                  self.latest = time;
                }
              }
            } catch (e) {
              if (e instanceof Error && e.message === "Failed to fetch") {
                // Assume hasn't been uploaded yet
              } else {
                throw e;
              }
            }
          }

          const oldest = subHours(now, maxAgeHours);
          for (const strike of self.strikeMap.values()) {
            if (strike.local < oldest) {
              self.strikeMap.delete(strike.id);
            }
          }

          self.strikes = Array.from(self.strikeMap.values());

          yield new Promise((resolve) => setTimeout(resolve, 60000));
        }
      }
    }),
  }));
