import { Dictionary } from "./dictionary";

const es: Dictionary = {
  nav: {
    search: "Buscar",
    poweredBy: "Desarrollada por",
    staleWarn: "Algunos datos están obsoletos por",
    findme: "Encuéntrame",
    more: "más",
  },
  panel: {
    display: "Mostrar",
    tools: "Herramientas",
    layers: {
      title: "Capas",
      baseMap: "Mapa base",
      baseMapOptions: {
        Streets: "Calles",
        Topographic: "Topográfico",
        NationalGeographic: "National Geographic",
        Oceans: "Océanos",
        Gray: "Gris",
        DarkGray: "Gris oscuro",
        Imagery: "Imagen Satélital",
        ShadedRelief: "Relieve sombreado",
        Terrain: "Terreno",
        USATopo: "Topográfico de EE. UU.",
        OceansLabels: "Océanos con etiquetas",
        GrayLabels: "Gris con etiquetas",
        DarkGrayLabels: "Gris oscuro con etiquetas",
        ImageryLabels: "Imagen Satélital con etiquetas",
        ImageryClarity: "Claridad de las imágenes",
        ImageryTransportation: "Imágenes con transporte",
        ShadedReliefLabels: "Relieve sombreado con etiquetas",
        TerrainLabels: "Terreno con etiquetas",
      },
      static: "Estática",
      dynamic: "Dinámica",
      misc: "Misc",
      camera: "Cámaras",
      back: "Volver a capas",
      lightning: "Relámpagos",
    },
    sensors: {
      title: "Sensores",
      data: {
        title: "Datos",
        precip: "precip",
        stream: "corr/desc",
        other: "más",
      },
      legend: {
        missing: "Último informe perdido",
        title: "Leyenda",
        hour: "/hora",
        precipMeasure: {
          depth: "Profundidad",
          intensity: "Intensidad",
        },
        streamMeasure: {
          height: "Altura",
          discharge: "Descarga",
        },
      },
      info: {
        back: "Volver a filtros",
        operatedBy: "Operado por:",
        lastReported: "Ultimo informe:",
        statsLastComputed: "Estadísticas calculadas por última vez:",
        ID: "Estación:Sensor ID:",
        precip: "Precipitación",
        intensity: "Intensidad",
        stage: "Corriente",
        discharge: "Descarga",
        recentData: "Tabla de datos recientes",
        chart: "Gráfica en tamaño completo",
        additionalInformation: "Información adicional",
      },
    },
    radar: {
      title: "Radar",
      opacity: "Opacidad",
      speed: "Velocidad",
    },
    find: {
      title: "Buscar",
      name: "Nombre",
      id: "ID",
      precip: "precip",
      stream: "corr/desc",
    },
    bookmarks: {
      title: "Marcadores",
      name: "Nombre",
      precip: "precip",
      stream: "corr/desc",
    },
    measure: {
      title: "Distancia",
    },
    lightning: {
      title: "Relámpagos",
      legend: "Leyenda",
      within: (mins) => `Dentro de ${mins} minutos`,
      clickPrompt: "Haga clic en un icono de huelga para ver la información",
      selected: "Seleccionado",
      detected: "Detectado",
      current: "La energía",
      positive: "positivo",
      negative: "negativo",
      type: "Tipo",
      location: "Ubicación",
    },
    cbrfc: {
      open: "Abrir en",
      details: "Ver detalles",
    }
  },
  pane: {
    tabular: {
      title: "Datos Tabulares",
      station: "Estación",
      sensor: "Sensor",
      precipitation: "Precipitación",
      stage: "Corriente",
      discharge: "Descarga",
      date: "Fecha",
      time: "Hora",
      csv: "Descargar CSV",
      zoneWarning: (zone: string) => `Sensor en zona horaria ${zone}.`,
    },
    chart: {
      monthNames: [
        "En",
        "Feb",
        "Mar",
        "Abr",
        "Mayo",
        "Jun",
        "Jul",
        "Agt",
        "Sept",
        "Oct",
        "Nov",
        "Dic",
      ],
      noData: "Datos no disponibles.",
    },
  },
  intervals: {
    durationLabel: {
      // from https://github.com/JEFuller/JEFMAP/blob/2633e4943ab82a90106e5996d0c2cdd4b4a33f01/viewer/js/gis/dijit/JefdaqStation.js#L726
      PT15M: "15m",
      PT30M: "30m",
      PT1H: "1h",
      PT3H: "3h",
      PT6H: "6h",
      PT12H: "12h",
      P1D: "1d",
      P7D: "7d",
      P14D: "14d",
      P30D: "30d",
    },
    lastLabel: "ultimo",
    max: "máximo",
  },
};

export default es;
