import {
  Control,
  Icon,
  IconText,
  Input,
  Level,
  LevelItem,
  Navbar,
  NavbarEnd,
  NavbarItem,
  NavbarItemAnchor,
  Subtitle,
  Title,
  bulma,
} from "trunx";
import {
  IconDefinition,
  faBars,
  faBookmark,
  faCloudRain,
  faExclamationTriangle,
  faLayerGroup,
  faSearch,
  faTape,
} from "@fortawesome/free-solid-svg-icons";
import { formatDistanceToNow, min, subHours } from "date-fns";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IPanel } from "./models/Menus";
import { LangSelect } from "./shared/LangSelect";
import config from "./config";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { observer } from "mobx-react-lite";
import { useLocaleContext } from "./contexts/LocaleContext";
import { useRootStore } from "./models/Root";

const StaleWarn = observer(() => {
  const {
    jefdaqApi: { precip, stage },
  } = useRootStore();
  const {
    dateLocale,
    dict: { nav: dict },
  } = useLocaleContext();
  if (precip.response && stage.response) {
    const oldest = min([
      precip.response.meta.created,
      stage.response.meta.created,
    ]);
    const hourAgo = subHours(new Date(), 1);
    if (oldest < hourAgo) {
      return (
        <NavbarItem>
          <span
            className={bulma(["has-text-warning", "is-large", "icon-text"])}
          >
            <Icon>
              <FontAwesomeIcon icon={faExclamationTriangle} />
            </Icon>
            <span>
              {dict.staleWarn}&nbsp;
              {formatDistanceToNow(oldest, { locale: dateLocale })}
            </span>
          </span>
        </NavbarItem>
      );
    }
  }
  return <> </>;
});

const Navbar_ = () => {
  const {
    dict: { nav: dict, panel: panelDict },
  } = useLocaleContext();

  const {
    menus: { toggleNav, setFind, find, togglePanel },
    layers,
    isMobile,
  } = useRootStore();

  const PanelLink = ({
    panel,
    icon,
  }: {
    panel: IPanel;
    icon: IconDefinition;
  }) => (
    <NavbarItemAnchor onClick={() => togglePanel(panel)}>
      <IconText>
        <Icon>
          <FontAwesomeIcon icon={icon} />
        </Icon>
        <span>{panelDict[panel].title}</span>
      </IconText>
    </NavbarItemAnchor>
  );

  return (
    <Navbar style={{ zIndex: 1000 }}>
      <NavbarItemAnchor onClick={toggleNav}>
        <Icon>
          <FontAwesomeIcon icon={faBars} />
        </Icon>
      </NavbarItemAnchor>

      {isMobile && (
        <>
          <NavbarItem>
            <Control hasIconsRight>
              <Input
                id="find"
                className={bulma("has-background-dark")}
                placeholder={dict.search}
                onChange={(e) => setFind(e.target.value)}
                value={find}
                type="search"
                autoComplete="off"
              ></Input>

              <Icon className={"is-right"}>
                <FontAwesomeIcon icon={faSearch} />
              </Icon>
            </Control>
          </NavbarItem>
          <NavbarItem className="mobile-title">
            <Subtitle>{config.title}</Subtitle>
          </NavbarItem>
          <NavbarEnd className={bulma("is-hidden-mobile")}>
            <NavbarItemAnchor>
              <StaleWarn></StaleWarn>
            </NavbarItemAnchor>
          </NavbarEnd>
        </>
      )}

      {!isMobile && (
        <>
          <NavbarItem hasDropdown>
            <Level
              left={
                <>
                  <LevelItem>
                    <Title>{config.title}</Title>
                  </LevelItem>
                  <LevelItem>
                    <Control hasIconsRight>
                      <Input
                        className={bulma("has-background-dark")}
                        placeholder={dict.search}
                        onChange={(e) => setFind(e.target.value)}
                        value={find}
                      ></Input>
                      <Icon className={"is-right"}>
                        <FontAwesomeIcon icon={faSearch} />
                      </Icon>
                    </Control>
                  </LevelItem>
                </>
              }
            ></Level>
          </NavbarItem>
          <PanelLink panel="sensors" icon={faCircle} />
          {layers.any && <PanelLink panel="layers" icon={faLayerGroup} />}
          {config.radar && <PanelLink panel="radar" icon={faCloudRain} />}
          <PanelLink panel="bookmarks" icon={faBookmark} />
          <PanelLink panel="measure" icon={faTape} />
          <NavbarEnd>
            <NavbarItemAnchor>
              <StaleWarn></StaleWarn>
              <LangSelect></LangSelect>
            </NavbarItemAnchor>
          </NavbarEnd>
        </>
      )}
    </Navbar>
  );
};

export default observer(Navbar_);
